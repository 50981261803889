import "./SellerAccount.css";
import React, { useState } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import brandImage from "../../Icons/shopLogoDemo.png";
import greaterThanIcon from "../../Icons/greater-than.png";
import sellersGuideIcon from "../../Icons/open-book.png";
import helpCenterIcon from "../../Icons/seller/help-center.png";
import shopIcon from "../../Icons/seller/shop.png";
import changePassIcon from "../../Icons/seller/change-password.png";
import deliveryOptionsIcon from "../../Icons/seller/delivery.png";
import analyticsIcon from "../../Icons/seller/analytics.png";
import pendingOrdersIcon from "../../Icons/seller/pending-orders.png";
import processedOrdersIcon from "../../Icons/seller/processed-orders.png";
import allProductsIcon from "../../Icons/seller/all-products.png";
import addProductIcon from "../../Icons/seller/add-product.png";
import archivedProductsIcon from "../../Icons/seller/archived-products.png";
import availableRegionIcon from "../../Icons/seller/globe.png";
import signoutIcon from "../../Icons/seller/signout.png";
import couponsIcon from "../../Icons/seller/coupons.png";
import { useNavigate } from "react-router-dom";
import SellerQRCodeDrawer from "../../Components/SellerQRCodeDrawer/SellerQRCodeDrawer";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import swoopkartQR from "../../Icons/swoopkartQR.png";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const SellerAccount = (props) => {
  const navigate = useNavigate();
  const analyticsNavigationHandler = () => {
    navigate("/seller/analytics");
  };
  const helpNavigationHandler = () => {
    navigate("/support");
  };
  const shopDetailsNavigationHandler = () => {
    navigate("/seller/shop/edit-shop-details");
  };
  const deliveryOptionsNavigationHandler = () => {
    navigate("/seller/delivery-options");
  };
  const changePasswordNavigationHandler = () => {
    navigate("/seller/change-password");
  };
  const pendingOrdersNavigationHandler = () => {
    navigate("/seller/orders/pending-orders");
  };
  const processedOrdersNavigationHandler = () => {
    navigate("/seller/orders/processed-orders");
  };
  const allProductsNavigationHandler = () => {
    navigate("/seller/products/all-products");
  };
  const addProductNavigationHandler = () => {
    navigate("/seller/products/add-new-product");
  };
  const archivedProductsNavigationHandler = () => {
    navigate("/seller/products/archived-products");
  };
  const availableRegionNavigationHandler = () => {
    navigate("/seller/selling-region");
  };
  const signoutHandler = () => {
    dispatch({ type: "SELLER_LOGGING_OUT" });
    navigate("/");
  };
  const couponsNavigationHandler = () => {
    navigate("/seller/coupons");
  };
  const sellersGuideNavigationHandler = () => {
    navigate("/guide");
  };

  const [toggle] = useBodyScrollLock();
  const [isSellerQRDrawerOpen, setIsSellerQRDrawerOpen] = useState(false);
  const sellerQRDrawerToggleClickHandler = () => {
    setIsSellerQRDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const backdropClickHandler = () => {
    setIsSellerQRDrawerOpen(false);
    toggle();
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);
  useEffect(() => {
    document.title = "My Account • Sell at Swoopkart";
  }, []);
  return (
    <div>
      <SellerQRCodeDrawer
        show={isSellerQRDrawerOpen}
        close={sellerQRDrawerToggleClickHandler}
      />
      {isSellerQRDrawerOpen && <Backdrop click={backdropClickHandler} />}
      <SmallNavBar navBarText="My Account" />
      <div className="container-fluid mt-4 user_dp_and_name">
        <div className=" d-flex align-items-start justify-content-center">
          <div
            className="position-relative"
            onClick={sellerQRDrawerToggleClickHandler}
          >
            <img
              src={sellerDPFromStore}
              alt="dp"
              className="user_dp"
              style={{ height: "80px", width: "80px" }}
            />
            <span
              className="position-absolute top-100 start-100 translate-middle d-flex justify-content-center"
              style={{ color: "white" }}
            >
              <img
                src={swoopkartQR}
                alt=""
                style={{
                  borderRadius: "50%",
                  height: "33px",
                  border: "1.5px solid rgb(250,103,103)",
                  marginBottom: "27px",
                  marginRight: "23px",
                }}
              />
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <span
            className="fw-semibold"
            style={{ fontSize: "23.5px", color: "rgb(250,103,103)" }}
          >
            {sellerNameFromStore}
          </span>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="user_ControlsAndNavigation_box row">
              <div
                className="user_control_container_box col-6 d-flex justify-content-center"
                style={{ borderRight: "1px solid rgb(255, 237, 237)" }}
                onClick={analyticsNavigationHandler}
              >
                <div className="">
                  <img
                    src={analyticsIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">
                    Analytics
                  </span>
                </div>
              </div>
              <div
                className="user_control_container_box col-6 d-flex justify-content-center"
                onClick={signoutHandler}
                style={{ borderLeft: "1px solid rgb(255, 237, 237)" }}
              >
                <div className="">
                  <img
                    src={signoutIcon}
                    alt=""
                    className="user_control_container_box_img"
                  />
                  <span className="user_control_container_box_text">
                    Sign out
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user_MoreControls">
        <div className="mt-0">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>My Account</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={shopDetailsNavigationHandler}
              >
                <div>
                  <img
                    src={shopIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Edit Shop Details
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={deliveryOptionsNavigationHandler}
              >
                <div>
                  <img
                    src={deliveryOptionsIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Delivery Options
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={changePasswordNavigationHandler}
              >
                <div>
                  <img
                    src={changePassIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Change Password
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={availableRegionNavigationHandler}
              >
                <div>
                  <img
                    src={availableRegionIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Selling Region
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>Orders</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={pendingOrdersNavigationHandler}
              >
                <div>
                  <img
                    src={pendingOrdersIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Pending Orders
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={processedOrdersNavigationHandler}
              >
                <div>
                  <img
                    src={processedOrdersIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Processed Orders
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>Product</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={allProductsNavigationHandler}
              >
                <div>
                  <img
                    src={allProductsIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    All Products
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={archivedProductsNavigationHandler}
              >
                <div>
                  <img
                    src={archivedProductsIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Archived Products
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={addProductNavigationHandler}
              >
                <div>
                  <img
                    src={addProductIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Add Product
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="user_MoreControls_box_category">
            <span>
              <strong>More</strong>
            </span>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={couponsNavigationHandler}
              >
                <div>
                  <img
                    src={couponsIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">Coupons</span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={helpNavigationHandler}
              >
                <div>
                  <img
                    src={helpCenterIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">Support</span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
              <div
                className="user_MoreControls_box d-flex justify-content-between  align-items-center"
                onClick={sellersGuideNavigationHandler}
              >
                <div>
                  <img
                    src={sellersGuideIcon}
                    alt=""
                    className="user_MoreControls_box_img"
                  />
                  <span className="user_MoreControls_box_text">
                    Seller's Guide
                  </span>
                </div>
                <img
                  src={greaterThanIcon}
                  alt=""
                  className="greaterThanSideDrawerIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SmallFooter /> */}
      <div className="p-3"></div>
    </div>
  );
};
export default React.memo(SellerAccount);
