import "./AllProductsItem.css";
import archiveImage from "../../../Icons/product/archived.png";
import deleteImage from "../../../Icons/product/delete.png";
import updateImage from "../../../Icons/product/update.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

const AllProductsItem = (props) => {
  const calculatedRating = Math.round(
    props.rating.totalSum / props.rating.noOfPeople
  );
  const navigate = useNavigate();

  return (
    <div className="all_product_itemProduct">
      <div className="container-fluid">
        <div
          className="searchResultProduct d-flex justify-content-start row"
          style={{ border: "none", paddingTop: "0" }}
          onClick={() => {
            navigate("/seller/products/all-products/" + props._id);
          }}
        >
          <div className="col-5 d-flex justify-content-center">
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.image
              }
              alt="product"
              className="productImage"
            />
          </div>
          <div className="col-7 productDetails d-flex flex-column justify-content-center align-items-start">
            {/* 30 Words */}
            <span className="productTitle ">{props.productName}</span>
            <span
              className="productPrice fw-bold"
              style={{ fontSize: "17.5px" }}
            >
              ₹{props.price}
            </span>
            <div className="fs-6 searchResultProduct_discount">
              <strike>₹{props.mrp}</strike>
              <span>
                {"  "}({props.discount}% off)
              </span>
            </div>
            {/* 20 Words */}
            <span className="productBrand">{props.brandName}</span>
            <div className="productRating">
              <span
                className={`fa fa-star ${calculatedRating >= 1 && "checked"}`}
              ></span>{" "}
              <span
                className={`fa fa-star ${calculatedRating >= 2 && "checked"}`}
              ></span>{" "}
              <span
                className={`fa fa-star ${calculatedRating >= 3 && "checked"}`}
              ></span>{" "}
              <span
                className={`fa fa-star ${calculatedRating >= 4 && "checked"}`}
              ></span>{" "}
              <span
                className={`fa fa-star ${calculatedRating >= 5 && "checked"}`}
              ></span>
              {"  "}
              <span className="noOfRatings">{`(${props.rating.noOfPeople})`}</span>
            </div>
          </div>
        </div>
        <div className="all_product_item_controls  d-flex justify-content-center">
          <button
            className="all_product_item_Controls_button_n smallButtonControlBorder d-flex align-items-center"
            onClick={() => props.ArchiveHandler(props._id)}
          >
            <img
              src={archiveImage}
              alt=""
              style={{ height: "13px", marginRight: "5.5px" }}
            />
            Archive
          </button>
          <button
            className="all_product_item_Controls_button_n smallButtonControlBorder d-flex align-items-center"
            onClick={() => props.UpdateHandler(props._id)}
          >
            <img
              src={updateImage}
              alt=""
              style={{ height: "13px", marginRight: "5px" }}
            />{" "}
            Edit
          </button>
          <button
            className="all_product_item_Controls_button_n smallButtonControlBorder d-flex align-items-center"
            onClick={() => props.DeleteHandler(props._id)}
          >
            <img
              src={deleteImage}
              alt=""
              style={{ height: "13px", marginRight: "5px" }}
            />{" "}
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
export default React.memo(AllProductsItem);
