import "./PendingOrders.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import PendingOrderItem from "./PendingOrderItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import emptyIcon from "../../Icons/empty.png";

const PendingOrders = () => {
  const [whatToShow, setWhatToShow] = useState("one");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Pending Orders • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [orders, setOrders] = useState([]);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const fetchOnLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/pending-orders/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "PENDING_ORDERS_FETCHED") {
        setWhatToShow("one");
        setOrders(response.orders);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOnLoadHandler();
  }, []);

  const acceptOrderHandler = async (OrderId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/pending-orders/accept-order/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: OrderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDER_ACCEPTED") {
        setWhatToShow("one");
        setOrders(response.orders);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const rejectOrderHandler = async (OrderId) => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/pending-orders/reject-order/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: OrderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDER_REJECTED") {
        setWhatToShow("one");
        setOrders(response.orders);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  return (
    <div className="PendingOrders">
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Pending Orders" />
      <div className="orderHistoryItemsContainer">
        {whatToShow === "one" && (
          <div>
            <div className="orderHistoryItemsContainer">
              <React.Fragment>
                {orders.map((order) => {
                  return (
                    <PendingOrderItem
                      key={order._id}
                      _id={order._id}
                      grandTotal={formatNumber(order.grandTotal)}
                      orderedOn={order.orderedOn}
                      soldTo={order.userAddress.fullName}
                      remarks={order.remarks}
                      totalItems={order.totalItems}
                      cartProducts={order.cartProducts}
                      acceptOrder={acceptOrderHandler}
                      rejectOrder={rejectOrderHandler}
                    />
                  );
                })}
                {orders.length <= 0 && (
                  <div className="container-fluid mt-3 mb-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "75vh" }}
                    >
                      <div
                        style={{
                          color: "#494949",
                          fontSize: "20px",
                        }}
                        className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                      >
                        <img
                          src={emptyIcon}
                          alt=""
                          style={{ height: "160px" }}
                        />{" "}
                        <div>You have no pending orders.</div>
                        <div
                          className="fw-semibold"
                          style={{
                            fontSize: "15px",
                            color: "rgb(250,103,103)",
                          }}
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Back to Dashboard.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            </div>
            <div className="p-3"></div>
          </div>
        )}
        {whatToShow === "unableToReachServer" && (
          <div>
            <CoudntFetch />
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(PendingOrders);
