import "./ForgotPassword.css";
import React from "react";
import LogoNavBar from "../../UI/LogoNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import { useEffect } from "react";

const ForgotPassword = () => {
  const [whatToShow, setWhatToShow] = useState("one");
  const [maskedEmail, setMaskedEmail] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailValid, setEnteredEmailValid] = useState(true);

  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  function emailToName(emailAddress) {
    return emailAddress.split("@")[0];
  }
  function emailToDomain(emailAddress) {
    return emailAddress.split("@")[1];
  }
  const requestForLinkHandler = async () => {
    if (enteredEmail.trim() === "") {
      setEnteredEmailValid(false);
      return;
    }
    if (isValidEmail(enteredEmail)) {
      setEnteredEmailValid(false);
      return;
    }
    setEnteredEmailValid(true);
    if (enteredEmailValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL +
            "/forgot-password/request-link/",
          {
            method: "POST",
            body: JSON.stringify({
              enteredEmail: enteredEmail,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const response = await requestSent.json();
        if (response.status === "MAIL_NOT_SENT") {
          setError("We coudn't send the link on mail.");
        }
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "MAIL_NOT_FOUND") {
          setError("We coudn't find any shop found with this email.");
        }
        if (response.status === "INVALID_EMAIL") {
          setError("Enter a valid email.");
        }
        if (response.status === "MAIL_SENT") {
          setWhatToShow("two");
          const extractedNameFromEmail = emailToName(enteredEmail);
          const extractedDomainFromEmail = emailToDomain(enteredEmail);
          var firstChar = extractedNameFromEmail[0];
          var lastChar = extractedNameFromEmail.substr(
            extractedNameFromEmail.length - 1
          );
          let joinedMaskedEmail =
            firstChar + "*******" + lastChar + "@" + extractedDomainFromEmail;
          setMaskedEmail(joinedMaskedEmail);
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    document.title = "Forgot Password • Sell at Swoopkart";
  }, []);
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <LogoNavBar />
      <div className="container-fluid">
        {whatToShow === "one" && (
          <div className="container-fluid">
            <div>
              <div className="d-flex justify-content-center mb-2">
                <h3 className="">Forgot Password</h3>
              </div>
              <div className="formGeneralSeller mt-2 mb-3">
                <span>
                  No problem! Just enter the email linked with your Shop and
                  we'll send a link to get back into your account.
                </span>
              </div>
              {(error !== "" || !enteredEmailValid) && (
                <div className="mb-3 formErrorSeller">
                  {!enteredEmailValid && <span>Enter a valid email.</span>}
                  {error !== "" && <span>{error}</span>}
                </div>
              )}
              <div className="form-floating mb-3 ">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput_enterEmailForLinkCP"
                  placeholder="name@example.com"
                  value={enteredEmail}
                  onChange={(event) => {
                    setEnteredEmail(event.target.value);
                  }}
                />
                <label htmlFor="floatingInput_enterEmailForLinkCP">
                  Email address
                </label>
              </div>
              <div className="nextButton">
                <button
                  className="nextButtonCustom"
                  onClick={requestForLinkHandler}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {whatToShow === "two" && (
          <div className="container-fluid">
            <div className="d-flex mb-2 justify-content-center">
              <h3>Email Sent</h3>
            </div>
            <div className="formSuccessSeller mt-2 mb-1">
              <div>
                We sent an email to <strong>{`${maskedEmail}`}</strong> with a
                link to get back into your account. You can just create a new
                password and use it normally.
              </div>
            </div>
            <div className="nextButton" style={{ marginTop: "30px" }}>
              <button
                className="nextButtonCustom"
                onClick={() => {
                  navigate("/");
                }}
              >
                Done
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="fixed-bottom" style={{ zIndex: "1" }}>
        <SmallFooter />
      </div>
    </div>
  );
};
export default React.memo(ForgotPassword);
