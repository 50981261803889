import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import uploadImage from "../../Icons/upload.png";
import "./ChangeDP.css";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const ChangeDP = (props) => {
  const dispatch = useDispatch();
  const inputRefImgCrop = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  let sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow";
  if (props.show === true) {
    sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow open";
  } else {
    sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow";
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const sendImageToServerHandler = async () => {
    const imageData = new File(
      [selectedImage],
      Math.floor(Math.random() * 100000000000000 + 1).toString() + ".png",
      { type: "image/png" }
    );
    console.log(imageData);

    const formData = new FormData();
    formData.append("dp", imageData);

    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/edit-shop/change-display-picture",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      if (response.status === "DP_UPDATED_SUCESSFULLY") {
        props.close();
        setError("");
        setImageUrl("");
        setSelectedImage("");
        localStorage.setItem(
          "sellerDP",
          process.env.REACT_APP_SELLER_BACKEND_IMAGE_DP_LOCATION +
            "/" +
            response.newSellerDP
        );
        dispatch({ type: "SELLER_LOAD" });
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      setIsLoading(false);
    } catch (err) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  };

  return (
    <div className={sellerUploadDPDrawerClasses}>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <div className="mainChangeShopDP_container">
        <div className="container-fluid fw-semibold">
          <div
            className="text-center fs-4 fw-semibold"
            style={{ color: "rgb(250,103,103)" }}
          >
            Display Picture
          </div>
          <div className="container-fluid text-center">
            {sellerNameFromStore}, Make sure that you upload an image that has
            1:1 aspect ratio.
          </div>
        </div>
        <div className="container-fluid">
          <div className="container-fluid">
            {error !== "" && (
              <div className="mt-3 formErrorSeller">
                {error !== "" && <span>{error}</span>}
              </div>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="container-fluid mt-3">
            {!imageUrl && !selectedImage && (
              <div className="changeCropper_ChangeShopDP">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRefImgCrop}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      console.log(e.target.files[0]);
                    }}
                  />
                  <div>
                    <img
                      src={uploadImage}
                      alt=""
                      onClick={() => {
                        inputRefImgCrop.current.click();
                      }}
                    />
                  </div>
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "15px", color: "grey" }}
                  >
                    Click to upload an image
                  </div>
                </div>
              </div>
            )}
            {imageUrl && selectedImage && (
              <div className="changeCropper_ChangeShopDP">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <img
                    src={imageUrl}
                    alt={selectedImage.name}
                    style={{
                      height: "140px",
                      width: "140px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <button
                    className="seller_updateProduct_cancelbutton"
                    onClick={() => {
                      setImageUrl(null);
                      setSelectedImage(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="seller_addProduct_button"
                    onClick={sendImageToServerHandler}
                  >
                    Change Picture
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangeDP;
