import "./MyStoreRoot.css";
import Product from "./Product";
import React, { useState, useEffect } from "react";
import BestSellings from "./BestSellings";
import SellerQRCodeDrawer from "../../Components/SellerQRCodeDrawer/SellerQRCodeDrawer";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import swoopkartQR from "../../Icons/swoopkartQR.png";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import { useNavigate } from "react-router-dom";
import LoadingRootProduct from "../../UI/LoadingProduct/LoadingRootProduct";
import LoadingSearchResultProduct from "../../UI/LoadingProduct/LoadingSearchResultProduct";

const MyStoreRoot = (props) => {
  const [toggle] = useBodyScrollLock();
  const navigate = useNavigate();
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);
  const [allProductsRecieved, setAllProductsRecieved] = useState([]);
  const [bestSellingsRecieved, setBestSellingsRecieved] = useState([]);
  const [shopDescription, setShopDescription] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSellerQRDrawerOpen, setIsSellerQRDrawerOpen] = useState(false);
  const sellerQRDrawerToggleClickHandler = () => {
    setIsSellerQRDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const backdropClickHandler = () => {
    setIsSellerQRDrawerOpen(false);
    toggle();
  };
  const { onLoad } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    onLoad();
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);
  useEffect(() => {
    document.title = sellerNameFromStore + " • Seller at Swoopkart";
  }, []);

  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/shop/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError("There was a server error.");
          setWasCouldntFetch(true);
        }
        if (response.status === "SHOP_FOUND") {
          setError("");
          setAllProductsRecieved(response.allProducts);
          setBestSellingsRecieved(response.bestSellingProducts);
          setShopDescription(response.shopDescription);
          setShopAddress(response.address);
          setWasCouldntFetch(false);
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setError("There was a server error.");
          setWasCouldntFetch(true);
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      <SellerQRCodeDrawer
        show={isSellerQRDrawerOpen}
        close={sellerQRDrawerToggleClickHandler}
      />
      {isSellerQRDrawerOpen && <Backdrop click={backdropClickHandler} />}
      {!wasCouldntFetch && (
        <div className="shop_page_container">
          <div className="container-fluid">
            <div className="row mt-3 pt-2 mb-4">
              <div className="shop_page_sellerbanner_logo col-3 d-flex align-items-start justify-content-center">
                <div
                  className="position-relative"
                  onClick={sellerQRDrawerToggleClickHandler}
                >
                  <img
                    src={sellerDPFromStore}
                    alt=""
                    style={{ height: "70px", width: "70px" }}
                  />
                  <span
                    className="position-absolute top-100 start-100 translate-middle d-flex justify-content-center"
                    style={{ color: "white" }}
                  >
                    <img
                      src={swoopkartQR}
                      alt=""
                      style={{
                        borderRadius: "50%",
                        height: "33px",
                        border: "1.5px solid rgb(250,103,103)",
                        marginBottom: "17px",
                        marginRight: "14px",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="col-9" style={{ padding: "0 7px 0 22px" }}>
                <div className="d-flex justify-content-start">
                  <span className="fs-5 shop_page_name">
                    <strong>{sellerNameFromStore}</strong>
                  </span>
                </div>
                <div className="" style={{ paddingRight: "3%" }}>
                  <span className="fs-6">
                    <strong>{sellerNameFromStore}</strong> - {shopDescription}
                    {isLoading && (
                      <React.Fragment>
                        Edit your shop's description in your account section.
                      </React.Fragment>
                    )}
                  </span>
                </div>
                <div className="" style={{ paddingRight: "3%" }}>
                  <span className="fs-6">
                    <strong>Address</strong> - {shopAddress}
                    {isLoading && (
                      <React.Fragment>Not Available.</React.Fragment>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="container-fluid shop_page_heading mb-2">
              <span className="fw-semibold" style={{ fontSize: "17px" }}>
                Our Best Selling Products
              </span>
            </div>
            <div
              className={`${
                bestSellingsRecieved.length <= 0 && !isLoading
                  ? ""
                  : "sellerBestSellingContainer"
              } `}
            >
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                  <LoadingRootProduct />
                </React.Fragment>
              )}
              {error === "" && !isLoading && (
                <React.Fragment>
                  {bestSellingsRecieved.map((bestSelling) => {
                    return (
                      <BestSellings
                        key={bestSelling._id}
                        _id={bestSelling._id}
                        image={bestSelling.productImage}
                        productName={bestSelling.productName}
                        price={formatNumber(bestSelling.price)}
                        discount={bestSelling.discount}
                      />
                    );
                  })}
                  {bestSellingsRecieved.length <= 0 && (
                    <div className="container-fluid mt-3">
                      <div className="formGeneralSeller p-5">
                        <span
                          style={{
                            color: "#494949",
                            fontSize: "16.5px",
                          }}
                        >
                          {" "}
                          Your shop currently dosen't have any best selling
                          products.
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="container-fluid shop_page_heading mb-2">
              <span className="fw-semibold" style={{ fontSize: "17px" }}>
                All Products
              </span>
            </div>
            <div style={{ marginBottom: "15%" }}>
              {(error !== "" || isLoading) && (
                <React.Fragment>
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                  <LoadingSearchResultProduct />
                </React.Fragment>
              )}
              {error === "" && !isLoading && (
                <div className="container-fluid">
                  {allProductsRecieved.map((product) => {
                    return (
                      <Product
                        key={product._id}
                        _id={product._id}
                        image={product.productImage}
                        productName={product.productName}
                        price={formatNumber(product.price)}
                        discount={product.discount}
                        mrp={formatNumber(product.MRP)}
                        brand={product.brandName}
                        rating={product.rating}
                      />
                    );
                  })}
                  {allProductsRecieved.length <= 0 && (
                    <div className="formGeneralSeller p-5 mt-3">
                      <span
                        style={{
                          color: "#494949",
                          fontSize: "16.5px",
                        }}
                      >
                        Your shop currently dosen't have any products.
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {wasCouldntFetch && <CoudntFetch />}
    </div>
  );
};
export default React.memo(MyStoreRoot);
