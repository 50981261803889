import "./ChangePassword.css";
import React from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const ChangePassword = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const showPasswordHandleChange = () => {
    setIsPasswordShown((current) => !current);
  };

  const [whatToShow, setWhatToShow] = useState("one");

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [enteredOldPassword, setEnteredOldPassword] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredRepetePassword, setEnteredRepetePassword] = useState("");

  const [enteredOldPasswordValid, setEnteredOldPasswordValid] = useState(true);
  const [enteredPasswordValid, setEnteredPasswordValid] = useState(true);
  const [enteredRepetePasswordValid, setEnteredRepetePasswordValid] =
    useState(true);
  const [doesBothPasswordsMatch, setDoesBothPasswordsMatch] = useState(true);

  const formValidationHandler = () => {
    const validation =
      enteredPasswordValid &&
      enteredRepetePasswordValid &&
      doesBothPasswordsMatch;
    setIsFormValid(validation);
  };

  const submitButtonClickHandler = async () => {
    if (enteredOldPassword === "") {
      setEnteredOldPasswordValid(false);
      return;
    }
    setEnteredOldPasswordValid(true);
    if (enteredPassword === "") {
      setEnteredPasswordValid(false);
      return;
    }
    if (enteredPassword.length < 9) {
      setEnteredPasswordValid(false);
      return;
    }
    setEnteredPasswordValid(true);
    if (enteredRepetePassword === "") {
      setEnteredRepetePasswordValid(false);
      return;
    }
    if (enteredRepetePassword.length < 9) {
      setEnteredRepetePasswordValid(false);
      return;
    }
    if (enteredRepetePassword !== enteredPassword) {
      setDoesBothPasswordsMatch(false);
      setError("Both passwords are not matching. Try again.");
      return;
    }
    setDoesBothPasswordsMatch(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/change-password/",
          {
            method: "POST",
            body: JSON.stringify({
              enteredOldPassword: enteredOldPassword,
              enteredNewPassword: enteredPassword,
              enteredRepeteNewPassword: enteredRepetePassword,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "PASSWORD_ERROR") {
          setError(
            "Enter a valid password. It should be greater than 9 characters."
          );
        }
        if (response.status === "INVALID_OLD_PASSWORD") {
          setError(
            "You have entered an invalid old password. Please try again."
          );
        }
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (response.status === "NOT_FOUND") {
          setError("No seller found with your id. Try re-signing in.");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        if (response.status === "PASSWORD_CHANGED_SUCESSFULLY") {
          setWhatToShow("two");
        }
        setIsLoading(false);
      } catch (err) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  const submitTwoButtonClickHandler = () => {
    setWhatToShow("one");
    navigate(-1);
  };
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Change Password" />
      {whatToShow === "one" && (
        <div className="container-fluid">
          <div className="changePassword_Password_container">
            <div className="container-fluid">
              <div className="formGeneralSeller mb-3">
                <span>
                  <strong>{`${sellerNameFromStore}`}</strong>, Just enter your
                  old password so that we can verify its you and then enter your
                  new password.
                </span>
              </div>
              {(!enteredOldPasswordValid ||
                !enteredPasswordValid ||
                error !== "") && (
                <div className="formErrorSeller mb-3">
                  {!enteredOldPasswordValid && (
                    <span>Enter a valid old password.</span>
                  )}
                  {!enteredPasswordValid && (
                    <span>
                      Enter a valid password greater than 9 characters.
                    </span>
                  )}
                  {!enteredRepetePasswordValid && (
                    <span>
                      Re-Enter a valid password greater than 9 characters.
                    </span>
                  )}
                  {error !== "" && <span>{error}</span>}
                </div>
              )}
              <div>
                <div className="form-floating mb-3 changePassword_Password_container_box">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    className="form-control"
                    id="sellerfloatingInput_changepass_old"
                    placeholder="Old Password"
                    value={enteredOldPassword}
                    onChange={(event) =>
                      setEnteredOldPassword(event.target.value)
                    }
                  />
                  <label htmlFor="sellerfloatingInput_changepass_old">
                    Old Password
                  </label>
                </div>
              </div>
              <div>
                <div className="form-floating mb-3 changePassword_Password_container_box">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    className="form-control"
                    id="sellerfloatingInput_changepass"
                    placeholder="New Password"
                    value={enteredPassword}
                    onChange={(event) => setEnteredPassword(event.target.value)}
                  />
                  <label htmlFor="sellerfloatingInput_changepass">
                    New Password
                  </label>
                </div>
                <div className="form-floating mb-3 changePassword_Password_container_box">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    className="form-control"
                    id="sellerfloatingInput_changepassrpt"
                    placeholder="Repeat New Password"
                    value={enteredRepetePassword}
                    onChange={(event) =>
                      setEnteredRepetePassword(event.target.value)
                    }
                  />
                  <label htmlFor="sellerfloatingInput_changepassrpt">
                    Repeat New Password
                  </label>
                </div>
                <div className="showPasswordOrNotCheckBoxContainer">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isPasswordShown}
                      onChange={showPasswordHandleChange}
                      id="flexCheckChecked_passshow"
                      defaultChecked={false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked_passshow"
                    >
                      Show Password
                    </label>
                  </div>
                </div>
              </div>
              <div className="nextButton d-flex justify-content-end">
                <button
                  className="nextButtonCustom"
                  onClick={submitButtonClickHandler}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "two" && (
        <div className="container-fluid">
          <div className="changePassword_Password_container">
            <div className="container-fluid">
              <div className="formSuccessSeller mb-3">
                <span>
                  <strong>{`${sellerNameFromStore}`}</strong>, Your password has
                  been changed successfully. Now you can use your new password
                  to sign in across devices.
                </span>
              </div>
              <div className="nextButton d-flex justify-content-end">
                <button
                  className="nextButtonCustom"
                  onClick={submitTwoButtonClickHandler}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-3"></div>
    </div>
  );
};
export default React.memo(ChangePassword);
