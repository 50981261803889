import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LogoNavBar from "../../UI/LogoNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
const PageNotFound = (props) => {
  const navigate = useNavigate();
  const { onLoad } = props;

  useEffect(() => {
    document.title = "Page not found • Sell at Swoopkart";
    onLoad();
  }, []);

  return (
    <div>
      <LogoNavBar />
      <div className="container-fluid">
        <div className="d-flex justify-content-center">
          <h3>Sorry, this page isn't available.</h3>
        </div>
        <div className="container-fluid mt-3 text-center fs-6">
          The link you followed may be broken, or the page may have been
          removed.{" "}
          <span
            className="fw-semibold"
            style={{ color: "rgb(250,103,103)" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Go back to Dashboard.
          </span>
        </div>
      </div>
      <div className="fixed-bottom footerSignIn">
        <SmallFooter />
      </div>
    </div>
  );
};
export default PageNotFound;
