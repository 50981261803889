import { useEffect } from "react";
import "./SellersGuide.css";
import React from "react";
import sellersGuideIcon from "../../Icons/open-book.png";
import upArrow from "../../Icons/up.png";
import downArrow from "../../Icons/down.png";
import { useState } from "react";

const SellersGuide = (props) => {
  const { onLoad } = props;

  const [isSEOOpened, setIsSEOOpened] = useState(false);
  const [isSellingRegionOpened, setIsSellingRegionOpened] = useState(false);
  const [isDomesticSellingOpened, setIsDomesticSellingOpened] = useState(false);
  const [isDeliveryOptionsOpened, setIsDeliveryOptionsOpened] = useState(false);
  const [isCouponsOpened, setIsCouponsOpened] = useState(false);
  const [isAnalyticsOpened, setIsAnalyticsOpened] = useState(false);
  const [isArchivedProductsOpened, setIsArchivedProductsOpened] =
    useState(false);
  const [isPendingOrdersOpened, setIsPendingOrdersOpened] = useState(false);
  const [isProcessedOrdersOpened, setIsProcessedOrdersOpened] = useState(false);
  const [isTandCOpened, setIsTandCOpened] = useState(false);

  const SEOClickHandler = () => {
    setIsSEOOpened((prev) => !prev);
  };

  const sellingRegionClickHandler = () => {
    setIsSellingRegionOpened((prev) => !prev);
  };

  const domesticSellingClickHandler = () => {
    setIsDomesticSellingOpened((prev) => !prev);
  };

  const deliveryOptionsClickHandler = () => {
    setIsDeliveryOptionsOpened((prev) => !prev);
  };

  const couponsClickHandler = () => {
    setIsCouponsOpened((prev) => !prev);
  };

  const analyticsClickHandler = () => {
    setIsAnalyticsOpened((prev) => !prev);
  };

  const archivedProductsClickHandler = () => {
    setIsArchivedProductsOpened((prev) => !prev);
  };

  const pendingOrdersClickHandler = () => {
    setIsPendingOrdersOpened((prev) => !prev);
  };

  const processedOrdersClickHandler = () => {
    setIsProcessedOrdersOpened((prev) => !prev);
  };

  const tandCClickHandler = () => {
    setIsTandCOpened((prev) => !prev);
  };

  useEffect(() => {
    document.title = "Seller's Guide • Sell at Swoopkart";
    onLoad();
  }, []);

  return (
    <div>
      <div className="aboutUsContainer_aboutUs sellersGuideBanner d-flex justify-content-center align-items-center">
        <img
          src={sellersGuideIcon}
          alt=""
          style={{ height: "28px", paddingRight: "13px" }}
        />
        <span
          style={{
            color: "rgb(250,103,103)",
            fontWeight: "500",
            fontSize: "25px",
          }}
        >
          Seller's Guide
        </span>
      </div>
      <div className="sellersGuideBanner1 container-fluid">
        <div
          className="text-center fw-semibold container-fluid"
          style={{ fontSize: "17.5px" }}
        >
          Hello Seller !!!
        </div>
        <div className="text-center container-fluid">
          Find all mantras to sell at swoopkart and grow your business.
        </div>
        <div
          className="text-center container-fluid"
          style={{ fontSize: "14px" }}
        >
          We are so excited to work with you.
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "8%" }}>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={SEOClickHandler}
          >
            <span style={{ marginRight: "5%" }}>How does Search work ?</span>
            {!isSEOOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isSEOOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isSEOOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    When a customer tries to search any product, the words
                    mentioned by him are used to search the products in our
                    database.
                  </div>
                  <div>
                    So our searching algorithm searches for the products having
                    same words mentioned by the user.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={sellingRegionClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What is a Selling Region ?
            </span>
            {!isSellingRegionOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isSellingRegionOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isSellingRegionOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Selling region decides the regions and areas in which the
                    seller can sell.
                  </div>
                  <div>
                    This is useful if the seller wants to sell in a specific
                    city or so with diffrent delivery options, he can just list
                    the pincodes and rest all our algorithms will take care.
                  </div>
                  <div>
                    If domestic selling is enabled then selling regions dosen't
                    matter, in this case we recommend not to list custom
                    deliveries, use the standard domestic delivery option for
                    all regions.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={deliveryOptionsClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What are Delivery Options ?
            </span>
            {!isDeliveryOptionsOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isDeliveryOptionsOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isDeliveryOptionsOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Delivery Options are choices of diffrent delivery mechanisms
                    with diffrent wait time and price provided by the seller to
                    the customer.
                  </div>
                  <div>
                    A Delivery Option is applicable on all products of the same
                    store.
                  </div>
                  <div>
                    If delivery option provided by seller cannot be fulfilled at
                    any moment, then we suggest to convert the delivery option
                    to domestic delivery.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={domesticSellingClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What is Domestic Selling ?
            </span>
            {!isDomesticSellingOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isDomesticSellingOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isDomesticSellingOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Domestic selling means that the seller is available in all
                    regions of India.
                  </div>
                  <div>
                    If Domestic selling is enabled, the seller can charge
                    domestic delivery charges and deliver in his choice of wait
                    time.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={couponsClickHandler}
          >
            <span style={{ marginRight: "5%" }}>What are Coupons ?</span>
            {!isCouponsOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isCouponsOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isCouponsOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Coupons are discounts which can be applied on the final bill
                    amount (excluding delivery charges) by the user while
                    checking out.
                  </div>
                  <div>
                    These coupons are only valid if listed by the seller in his
                    shop.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={analyticsClickHandler}
          >
            <span style={{ marginRight: "5%" }}>What are Analytics ?</span>
            {!isAnalyticsOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isAnalyticsOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isAnalyticsOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Analytics helps Swoopkart Sellers to understand their
                    business in a better manner in a data driven aproach.
                  </div>
                  <div>
                    Business Reach, Product Analysis and many more are shown in
                    Analytics.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={archivedProductsClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What are Archived Products ?
            </span>
            {!isArchivedProductsOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isArchivedProductsOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isArchivedProductsOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    There can be many seasonal products or products that are out
                    of stock for the moment. These products can be archived to
                    hide from the searching algorithms and from your store as
                    well.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={pendingOrdersClickHandler}
          >
            <span style={{ marginRight: "5%" }}>What are Pending Orders ?</span>
            {!isPendingOrdersOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isPendingOrdersOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isPendingOrdersOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Orders that are under processing or seller's action is
                    pending are pending orders.
                  </div>
                  <div>
                    Further actions must be taken on these orders accordingly.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={processedOrdersClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What are Processed Orders ?
            </span>
            {!isProcessedOrdersOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isProcessedOrdersOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isProcessedOrdersOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    Orders that have been rejected by the seller or Canceled by
                    the customer or Delivered successfully are processed orders.
                  </div>
                  <div>
                    Further no more actions can be taken on these orders.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="AddressForOrder_AddressItem"
          style={{ padding: "2% 4%", border: "2px solid  rgb(255, 232, 232)" }}
        >
          <div
            className="fw-semibold  d-flex justify-content-between align-items-center"
            style={{
              fontSize: "16.5px",
              color: "rgb(250,103,103)",
            }}
            onClick={tandCClickHandler}
          >
            <span style={{ marginRight: "5%" }}>
              What are our Terms and Conditions ?
            </span>
            {!isTandCOpened && (
              <img src={downArrow} alt="" style={{ height: "16px" }} />
            )}
            {isTandCOpened && (
              <img src={upArrow} alt="" style={{ height: "16px" }} />
            )}
          </div>
          {isTandCOpened && (
            <div className="" style={{ marginTop: "5px" }}>
              <div className="rbBlock d-flex justify-content-start align-items-center">
                <div style={{ fontSize: "15px" }}>
                  <div>
                    To protect the integrity of our sellers, all orders will be
                    taken only after the customer agrees for no return and no
                    refund policy. That means the customer will only have a
                    chance to cancel an order before its processing starts.
                  </div>
                  <div>
                    We do not encourage or promote selling of illegal products
                    or products that effect the mankind in any way.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellersGuide;
