import "./Region.css";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import React, { useState, useEffect } from "react";
import plusIcon from "../../Icons/plus.png";
import ExistingRegions from "./ExistingRegions/ExistingRegions";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const Region = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [regionsRecieved, setRegionsRecieved] = useState([]);

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Selling Region • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [formError, setFormError] = useState("");
  const [error, setError] = useState("");
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);

  const [enteredPincode, setEnteredPincode] = useState("");
  const [enteredBlock, setEnteredBlock] = useState("");
  const [enteredRegion, setEnteredRegion] = useState("");
  const [enteredDivision, setEnteredDivision] = useState("");
  const [enteredDistrict, setEnteredDistrict] = useState("");
  const [enteredSandC, setEnteredSandC] = useState("");

  const [isDomesticRegionChecked, setIsDomesticRegionChecked] = useState(false);
  const [isAddRegionClicked, setIsAddRegionClicked] = useState(false);
  const addRegionToggleHandler = () => {
    setIsAddRegionClicked(true);
  };
  const cancelAddRegionToggleHandler = () => {
    setEnteredPincode("");
    setEnteredBlock("");
    setEnteredRegion("");
    setEnteredDivision("");
    setEnteredDistrict("");
    setEnteredSandC("");
    setError("");
    setIsAddRegionClicked(false);
    setWasFetchFromAPISuccessful(false);
  };

  const [wasFetchFromAPISuccessful, setWasFetchFromAPISuccessful] =
    useState(false);

  // fetch from address api
  const checkValidityClickHandler = async (pincode) => {
    if (enteredPincode !== "") {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          "https://api.postalpincode.in/pincode/" + pincode,
          {
            method: "GET",
          }
        );
        const response = await requestSent.json();
        console.log(response[0]);
        if (response[0].Status === "Success") {
          setEnteredBlock(response[0].PostOffice[0].Block + "");
          setEnteredRegion(response[0].PostOffice[0].Region + "");
          setEnteredDivision(response[0].PostOffice[0].Division + "");
          setEnteredDistrict(response[0].PostOffice[0].District + "");
          setEnteredSandC(
            response[0].PostOffice[0].State +
              ", " +
              response[0].PostOffice[0].Country
          );
          setWasFetchFromAPISuccessful(true);
          setFormError("");
        }
        if (response[0].Status === "Error") {
          setFormError("Pincode not found. Enter a valid pincode.");
        }
        if (!response[0].Status) {
          setFormError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setFormError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    } else {
      setFormError("Enter a valid pincode.");
    }
  };

  // add region to database
  const addRegionClickHandler = async () => {
    if (enteredPincode !== "") {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL +
            "/selling-region/add-new-region",
          {
            method: "POST",
            body: JSON.stringify({
              enteredPincode: enteredPincode,
              enteredBlock: enteredBlock,
              enteredRegion: enteredRegion,
              enteredDivision: enteredDivision,
              enteredDistrict: enteredDistrict,
              enteredSandC: enteredSandC,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setFormError("There was a server error.");
          setWasCouldntFetch(false);
        }
        if (response.status === "REGION_ADDED") {
          setFormError("");
          setEnteredPincode("");
          setEnteredBlock("");
          setEnteredRegion("");
          setEnteredDivision("");
          setEnteredDistrict("");
          setEnteredSandC("");
          setIsAddRegionClicked(false);
          setWasFetchFromAPISuccessful(false);
          setRegionsRecieved(response.regions);
          setIsDomesticRegionChecked(response.domesticDelivery);
          setWasCouldntFetch(false);
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setFormError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  const domesticSellingChangeHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/selling-region/domestic-selling",
        {
          method: "POST",
          body: JSON.stringify({
            domesticSelling: !isDomesticRegionChecked,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      if (response.status === "CHANGED") {
        setWasCouldntFetch(false);
        setIsDomesticRegionChecked(response.domesticSelling);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  // On Load
  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/selling-region/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        if (response.status === "REGIONS_RETRIEVED") {
          setWasCouldntFetch(false);
          setRegionsRecieved(response.regions);
          setIsDomesticRegionChecked(response.domesticDelivery);
          setError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  // delete Region
  const deleteRegionHandler = async (RegionId) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/selling-region/delete-selling-region",
        {
          method: "POST",
          body: JSON.stringify({
            recievedRegionId: RegionId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      if (response.status === "REGION_DELETED") {
        setWasCouldntFetch(false);
        setRegionsRecieved(response.regions);
        setIsDomesticRegionChecked(response.domesticDelivery);
        setError("");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}

      <div>
        <SmallNavBar navBarText="Selling Region" />
        {!wasCouldntFetch && (
          <div>
            <div className="globe-Image-region-container ">
              <div>
                {!isAddRegionClicked && (
                  <div
                    className="MyAccount_addNewAddresses d-flex flex-column align-items-center "
                    onClick={addRegionToggleHandler}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div
                        className="col-2 d-flex justify-content-end"
                        style={{ paddingRight: "0px" }}
                      >
                        <div className="MyAccount_addNewAddresses_img">
                          <img src={plusIcon} alt="" />
                        </div>
                      </div>
                      <div
                        className="col-10"
                        style={{ paddingLeft: "6px", marginTop: "-1px" }}
                      >
                        <div
                          className="MyAccount_addNewAddresses_text"
                          style={{ color: "rgb(250,103,103)" }}
                        >
                          <b>New Selling Region</b>
                        </div>
                        <div className="MyAccount_addNewAddresses_text_exp">
                          You will only be selling in these particular regions.
                          If Domestic Selling is enabled even then these are
                          applicable.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isAddRegionClicked && (
                  <div className="addNewAddresses_form mb-5">
                    <div>
                      <div className="addNewAddresses_form_heading">
                        <strong className="fs-5">New Selling Region</strong>
                      </div>
                      {formError !== "" && (
                        <div className="mb-3 formErrorSeller">
                          {formError !== "" && <div>{formError}</div>}
                        </div>
                      )}
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control"
                          id="addNewRegion_pincode"
                          placeholder="Pincode"
                          value={enteredPincode}
                          onChange={(event) => {
                            setEnteredPincode(event.target.value);
                          }}
                          readOnly={wasFetchFromAPISuccessful}
                        />
                        <label htmlFor="addNewRegion_pincode">Pincode</label>
                      </div>
                      {!wasFetchFromAPISuccessful && (
                        <div className="mt-4 pt-2 d-flex justify-content-center">
                          <button
                            className="seller_updateProduct_cancelbutton"
                            onClick={cancelAddRegionToggleHandler}
                          >
                            Cancel
                          </button>
                          <button
                            className="seller_addProduct_button"
                            onClick={() =>
                              checkValidityClickHandler(enteredPincode)
                            }
                          >
                            Check Validity
                          </button>
                        </div>
                      )}
                      {wasFetchFromAPISuccessful && (
                        <div>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control newDisabledFormControl"
                              disabled={true}
                              id="addNewRegion_block"
                              placeholder="Block"
                              value={enteredBlock}
                            />
                            <label htmlFor="addNewRegion_block">Block</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control newDisabledFormControl"
                              disabled={true}
                              id="addNewRegion_region"
                              placeholder="Region"
                              value={enteredRegion}
                            />
                            <label htmlFor="addNewRegion_region">Region</label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control newDisabledFormControl"
                              disabled={true}
                              id="addNewRegion_division"
                              placeholder="Division"
                              value={enteredDivision}
                            />
                            <label htmlFor="addNewRegion_division">
                              Division
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control newDisabledFormControl"
                              id="addNewRegion_district"
                              placeholder="District"
                              value={enteredDistrict}
                              disabled={true}
                            />
                            <label htmlFor="addNewRegion_district">
                              District
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control newDisabledFormControl"
                              disabled={true}
                              id="addNewRegion_statencountry"
                              placeholder="State & Country"
                              value={enteredSandC}
                            />
                            <label htmlFor="addNewRegion_statencountry">
                              State & Country
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {wasFetchFromAPISuccessful && (
                      <div className="mt-4 pt-2 d-flex justify-content-center">
                        <button
                          className="seller_updateProduct_cancelbutton"
                          onClick={cancelAddRegionToggleHandler}
                        >
                          Cancel
                        </button>
                        <button
                          className="seller_addProduct_button"
                          onClick={addRegionClickHandler}
                        >
                          Add Region
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="sellerSellingRegion_globalDeliveryContainer">
                <div className="form-check form-switch fontSizeForToggle">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={isDomesticRegionChecked}
                    // tfjygkhjl
                    onChange={domesticSellingChangeHandler}
                  />
                  <label
                    className="form-check-label sellerSellingRegion_globalDelivery_Label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    <span className="fw-semibold">Domestic Selling</span>
                  </label>
                </div>
              </div>
              <div
                className="d-flex justify-content-start mt-5"
                style={{ marginRight: "5%", marginLeft: "6%" }}
              >
                <span
                  className="fw-semibold"
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                >
                  Custom Selling Regions
                </span>
              </div>
              <div className="container-fluid">
                <div className="container-fluid">
                  <div className="loadedDataSeparaterForItems container-fluid"></div>
                </div>
              </div>
              <div className="">
                {error !== "" && (
                  <div
                    className="mb-3 formErrorSeller"
                    style={{ marginRight: "5%", marginLeft: "5%" }}
                  >
                    {error !== "" && <div>{error}</div>}
                  </div>
                )}
                {error === "" && (
                  <React.Fragment>
                    {regionsRecieved.map((regions) => {
                      return (
                        <ExistingRegions
                          key={regions._id}
                          _id={regions._id}
                          pincode={regions.pincode}
                          block={regions.block}
                          region={regions.region}
                          division={regions.division}
                          district={regions.district}
                          stateAndCountry={regions.stateAndCountry}
                          DeleteHandler={deleteRegionHandler}
                        />
                      );
                    })}
                    {regionsRecieved.length <= 0 && (
                      <div
                        className="formGeneralSeller"
                        style={{ marginRight: "5%", marginLeft: "5%" }}
                      >
                        No selling regions found.
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="p-3"></div>
          </div>
        )}
      </div>
      {wasCouldntFetch && <CoudntFetch />}
    </div>
  );
};
export default React.memo(Region);
