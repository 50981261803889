import "./ExistingDeliveryOptions.css";
import React from "react";
const ExistingDeliveryOptions = (props) => {
  return (
    <div className="ExistingCouponsItem">
      <div style={{ fontSize: "18px" }}>
        <strong>
          <span>₹{props.deliveryCharges}</span> {props.deliveryOptionName}
        </strong>
      </div>
      <div style={{ fontSize: "15.5px" }}>
        Delivered Within {props.waitTime}.
      </div>
      <div style={{ fontSize: "13.5px" }}>
        Only If Ordered Before {props.onlyOrderedBefore}
      </div>
      <div className="fs-6 d-flex delete_coupon_seller justify-content-end">
        <span
          onClick={() => {
            props.DeleteHandler(props._id);
          }}
        >
          <strong>Delete</strong>
        </span>
      </div>
    </div>
  );
};
export default React.memo(ExistingDeliveryOptions);
