import "./Root.css";
import React from "react";
import sellerBaner from "../../Icons/Banner_Moblie.png";
import cdrImage from "../../Icons/cdr.png";
import ncImage from "../../Icons/nc.png";
import hpImage from "../../Icons/hp.png";
import buiImage from "../../Icons/bui.png";
import lrImage from "../../Icons/lr.png";
import botgImage from "../../Icons/botg.png";
import qrImage from "../../Icons/bannerQRCodes.png";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
const Root = (props) => {
  const navigate = useNavigate();
  const startSellingHandler = () => {
    navigate("/want-physical-qr");
  };
  const { onLoad } = props;
  useEffect(() => {
    onLoad();
    document.title =
      "Digitize your business by selling on India's first e-commerce destination for small businesses  • Sell at Swoopkart";
  }, []);
  return (
    <div className="seller-root">
      <div className="seller-root_heading1">
        <div className="seller_root_image d-flex justify-content-center">
          <div className="col-5">
            <img src={sellerBaner} alt="" className="seller-root_banner" />
          </div>
          <div className="col-5 d-flex justify-content-start align-items-center">
            <strong
              className="text-start"
              style={{ paddingLeft: "20px", color: "purple", fontSize: "27px" }}
            >
              Digitizing India's Small Businesses.
            </strong>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="seller-root_heading1_text container-fluid">
            <span className="seller-root_becomeaseller">
              <strong>Become a seller on swoopkart</strong>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="seller-root_heading1_text container-fluid pb-4">
            <span className="fs-5">
              Sell on swoopkart.com with <b>₹0 Selling Fee</b> and avail{" "}
              <b>Single click launch of your online store</b>. Best support at
              no additional cost.
            </span>
          </div>
        </div>
        <div className="">
          <div className="seller-root_startSelling">
            <button className="fs-5" onClick={startSellingHandler}>
              Start Selling
            </button>
          </div>
          <div className="seller-root_tac">
            <span>*T&C Apply</span>
          </div>
        </div>
      </div>
      <div className="whysellonswoop mt-4 pt-1">
        <div
          className="fs-1 text-center mb-2"
          style={{ color: "rgb(250, 103, 103)", fontSize: "30px" }}
        >
          <strong>Why sell on swoopkart?</strong>
        </div>
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={qrImage} alt="" style={{ height: "50px" }} />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">Physical QR</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    Digitize your existing shop using our QR. Accept orders
                    online.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={cdrImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">Custom Delivery Range</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    You choose your customer's location, near you or far away
                    from you.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={ncImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">No Charges</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    ₹0 commission, you take 100% profits with you.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={hpImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">High Profits</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    Wide customer base and direct to customer business
                    eliminating trade channel.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={buiImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">Best User Interface</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    Beautiful, Easy and Simple website to manage your business.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={lrImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">Low Risk</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    No Fixed Capital or Physical Infrastructure requied. Your
                    mobile phone is the way to your success.
                  </span>
                </div>
              </div>
              <div className="col-12 whysellonswoop_reasonsContainer d-flex flex-column align-items-center">
                <div className="whysellonswoop_img">
                  <img src={botgImage} alt="" />
                </div>
                <div className="whysellonswoop_text mt-2">
                  <strong className="fs-5">Business on the go</strong>
                </div>
                <div className="mt-2">
                  <span className="fs-6">
                    Use our Swoopkart Seller Website to manage your business
                    anywhere and anytime.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="seller-root_startSelling d-flex justify-content-center">
          <button className="fs-5" onClick={startSellingHandler}>
            Start Selling
          </button>
        </div>
        <div className="seller-root_tac  d-flex justify-content-center">
          <span>*T&C Apply</span>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Root);
