import "./IndividualOrderPage.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import IndividualProductOrdered from "./IndividualProductOrdered";
import swoopkartLogo from "../../Icons/dark.png";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import { useDispatch, useSelector } from "react-redux";
import LogoNavBar from "../../UI/LogoNavBar";

const IndividualOrderPage = () => {
  const [whatToShow, setWhatToShow] = useState("notfound");
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Order " + params.orderId + " • Swoopkart";
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const fetchLoadHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/individual-order-page/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDER_FOUND") {
        setOrder(response.order);
        setWhatToShow("one");
      }
      if (response.status === "ORDER_NOT_FOUND") {
        setWhatToShow("notfound");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "USER_LOGGING_OUT" });
        navigate("/user-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLoadHandler();
  }, []);

  const acceptOrderHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-order-page/accept-order/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDER_ACCEPTED") {
        setWhatToShow("one");
        setOrder(response.order);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const rejectOrderHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-order-page/reject-order/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ORDER_REJECTED") {
        setWhatToShow("one");
        setOrder(response.order);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const completeProcessingHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-order-page/complete-processing/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "COMPLETE_PROCESSING") {
        setWhatToShow("one");
        setOrder(response.order);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const outForDeliveryHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-order-page/out-for-delivery/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "OUT_FOR_DELIVERY") {
        setWhatToShow("one");
        setOrder(response.order);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  const deliveredHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-order-page/delivered/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOrderId: params.orderId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "DELIVERED") {
        setWhatToShow("one");
        setOrder(response.order);
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  return (
    <div className="individualOrderPage">
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "one" && (
        <div>
          <SmallNavBar navBarText={`Order ${params.orderId}`} />
          <div className="container-fluid mt-3">
            <div className="individualOrderPage_DetailsContainer">
              <div className="container-fluid mt-3">
                <div className="individualOrderPage_DetailsContainer_OrderId text-center mb-2">
                  <div>Order ID : {params.orderId} </div>
                  <div className="individualOrderPage_DetailsContainer_EstimatedDelivery_Delivered">
                    {order.remarks}
                  </div>
                </div>
                <div className="row">
                  <div className="individualOrderPage_DetailsContainer_Details col-6">
                    <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                      Ordered On
                    </div>
                    <div className="individualOrderPage_DetailsContainer_DetailsText">
                      {order.orderedOn}
                    </div>
                  </div>
                  <div className="individualOrderPage_DetailsContainer_Details col-6">
                    <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                      Estimated Delivery
                    </div>
                    <div className="individualOrderPage_DetailsContainer_DetailsText">
                      {order.deliveryOption.waitTime}
                    </div>
                  </div>
                  <div className="individualOrderPage_DetailsContainer_Details col-6">
                    <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                      Order Value
                    </div>
                    <div className="individualOrderPage_DetailsContainer_DetailsText">
                      ₹{formatNumber(order.grandTotal)}
                    </div>
                  </div>
                  <div className="individualOrderPage_DetailsContainer_Details col-6">
                    <div className="individualOrderPage_DetailsContainer_DetailsHeading">
                      Payment Method
                    </div>
                    <div className="individualOrderPage_DetailsContainer_DetailsText">
                      {order.paymentMethod}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="orderedIndividualProductsContainer_new">
            <div className="container-fluid">
              <div className="individualOrderPage_DetailsContainer_Divider mb-3"></div>
            </div>
            {order.cartProducts.map((product) => {
              return (
                <IndividualProductOrdered
                  key={product._id}
                  _id={product._id}
                  image={product.productImage}
                  productName={product.productName}
                  price={formatNumber(product.price)}
                  mrp={formatNumber(product.MRP)}
                  discount={product.discount}
                  brandName={product.brandName}
                  quantity={
                    order.userCart.find((o) => o._id === product._id).quantity
                  }
                />
              );
            })}
            <div className="container-fluid">
              <div className="individualOrderPage_DetailsContainer_Divider mt-3"></div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid mb-3">
              <div
                className="row individualOrderPage_DetailsContainer_CandCContainer"
                style={{ fontWeight: "500" }}
              >
                <div className="col-9 individualOrderPage_DetailsContainer_CandC fw-semibold">
                  Subtotal
                </div>
                <div className="col-3 individualOrderPage_DetailsContainer_CandC text-end">
                  ₹{formatNumber(order.subtotal)}
                </div>
              </div>
              {order.discountData.discountPercentage !== 0 && (
                <div
                  className="row individualOrderPage_DetailsContainer_CandCContainer"
                  style={{ fontWeight: "500" }}
                >
                  <div className="col-9 individualOrderPage_DetailsContainer_CandC">
                    <div className="fw-semibold">Coupon Discount</div>
                    <div>{order.discountData._id}</div>
                    <div>{order.discountData.discountPercentage}% Off</div>
                  </div>
                  <div className="col-3 individualOrderPage_DetailsContainer_CandC text-end">
                    - ₹{formatNumber(order.calculatedDiscount)}
                  </div>
                </div>
              )}
              <div
                className="row individualOrderPage_DetailsContainer_CandCContainer"
                style={{ fontWeight: "500" }}
              >
                <div className="col-6 individualOrderPage_DetailsContainer_CandC fw-semibold">
                  {order.deliveryOption.deliveryOptionName}
                </div>
                <div className="col-6 individualOrderPage_DetailsContainer_CandC text-end">
                  + ₹{formatNumber(order.deliveryOption.deliveryCharges)}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="individualOrderPage_DetailsContainer_Divider"></div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row individualOrderPage_DetailsContainer_TotalContainer fw-semibold">
                <div className="col-6 individualOrderPage_DetailsContainer_Total">
                  Total
                </div>
                <div className="col-6 individualOrderPage_DetailsContainer_Total text-end">
                  ₹{formatNumber(order.grandTotal)}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="individualOrderPage_DetailsContainer_Divider"></div>
          </div>
          <div className="container-fluid mt-3 mb-3">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-3">
                  <span>Sold to</span>
                </div>
                <div className="col-9">
                  <div>
                    <strong>{order.userAddress.fullName}</strong>
                  </div>
                  <div>
                    <span className="fw-semibold">
                      +91 {order.userAddress.phoneNumber}
                    </span>
                  </div>
                  <span>
                    {order.userAddress.flatHouseBuildingNo},{" "}
                    {order.userAddress.areaStreet}, {order.userAddress.townCity}{" "}
                    - {order.userAddress.pincode}, {order.userAddress.state}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <span>Sold by</span>
                </div>
                <div className="col-9">
                  <div>
                    <strong>{order.soldBy}</strong>
                  </div>
                  <div>
                    <span className="fw-semibold">
                      +91 {order.sellerPhoneNumber}
                    </span>
                  </div>
                  <span>
                    <span>{order.sellerAddress}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="individualOrderPage_DetailsContainer_Divider"></div>
          </div>
          <div className="mt-3 mb-3">
            <div>
              <div className="d-flex justify-content-center">
                Agreed to no return and no refund policy.
              </div>
            </div>
            <div className="fs-6 mt-1 fw-semibold thankyou_forOrdering">
              <span>Thankyou for shopping with us.</span>
            </div>
          </div>
          <div className="container-fluid">
            <div className="individualOrderPage_DetailsContainer_Divider"></div>
          </div>
          {order.status !== "PROCESSED" && (
            <div className="cancelOrderContainerIndividualOrderPage container-fluid">
              {order.remarks === "Order Placed" && (
                <div className="container-fluid">
                  <div>
                    Seller, check the availability of products, its quanity and
                    delivery availability and then take an action.
                  </div>
                  <div className="row mt-3">
                    <div className="col-6" style={{ paddingRight: "5px" }}>
                      <button
                        className="shadow-sm acceptButtonIndividualOrderPage"
                        onClick={acceptOrderHandler}
                      >
                        Accept Order
                      </button>
                    </div>
                    <div className="col-6" style={{ paddingLeft: "5px" }}>
                      <button
                        className="shadow-sm rejectButtonIndividualOrderPage"
                        onClick={rejectOrderHandler}
                      >
                        Reject Order
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {order.remarks === "Processing Initiated" && (
                <div className="container-fluid">
                  <div>
                    Seller, after you have completed your backend tasks and
                    packed the shipment, finish the processing.
                  </div>
                  <div className="nextButton mt-3">
                    <button
                      className="nextButtonCustom"
                      onClick={completeProcessingHandler}
                    >
                      Processing Completed
                    </button>
                  </div>
                </div>
              )}
              {order.remarks === "Processing Completed" && (
                <div className="container-fluid">
                  <div>
                    Seller, after processing and aranging the specified
                    delivery, show the order as out for delivery.
                  </div>
                  <div className="nextButton mt-3">
                    <button
                      className="nextButtonCustom"
                      onClick={outForDeliveryHandler}
                    >
                      Out For Delivery
                    </button>
                  </div>
                </div>
              )}
              {order.remarks === "Out For Delivery" && (
                <div className="container-fluid">
                  <div>
                    Seller, Once the customer receives the shipment then close
                    this order.
                  </div>
                  <div className="nextButton mt-3">
                    <button
                      className="nextButtonCustom"
                      onClick={deliveredHandler}
                    >
                      Recieved By {order.userAddress.fullName}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <SmallFooter />
        </div>
      )}
      {whatToShow === "notfound" && (
        <React.Fragment>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <h3>Sorry, this page isn't available.</h3>
            </div>
            <div className="container-fluid mt-3 text-center fs-6">
              The link you followed may be broken, or the page may have been
              removed.{" "}
              <span
                className="fw-semibold"
                style={{ color: "rgb(250,103,103)" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Go back to Dashboard.
              </span>
            </div>
          </div>
          <div className="fixed-bottom footerSignIn">
            <SmallFooter />
          </div>
        </React.Fragment>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <SmallNavBar navBarText={`Order + ${params.orderId}`} />
          <div>
            <CoudntFetch />
          </div>
        </div>
      )}
    </div>
  );
};
export default IndividualOrderPage;
