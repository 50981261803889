import "./ExistingCoupons.css";
import React from "react";
const ExistingCoupons = (props) => {
  return (
    <div className="ExistingCouponsItem">
      <div style={{ fontSize: "17px" }}>
        <strong>{props._id}</strong>
      </div>
      <span className="fs-6 region_block">Flat {props.discount}% Discount</span>
      <div className="fs-6 d-flex delete_coupon_seller justify-content-end">
        <span
          onClick={() => {
            props.DeleteHandler(props._id);
          }}
        >
          <strong>Delete</strong>
        </span>
      </div>
    </div>
  );
};
export default React.memo(ExistingCoupons);
