import React from "react";
import "./SellerAccountDrawer.css";
import greaterThanIcon from "../../Icons/greater-than.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SellerAccountDrawer = (props) => {
  let drawerClasses = "sellerAccountDrawer shadow";
  if (props.show === true) {
    drawerClasses = "sellerAccountDrawer shadow open";
  } else {
    drawerClasses = "sellerAccountDrawer shadow";
  }
  const dispatch = useDispatch();
  return (
    <nav className={drawerClasses}>
      <div className="myAccountDrawer_LinksContainer container-fluid">
        <div className="container-fluid">
          <div className="myAccountDrawer_Title">
            <span>
              <strong className="fs-6 myAccountDrawer_Title_Text">
                My Account
              </strong>
            </span>
          </div>
          <Link
            className="myAccountDrawer_Links d-flex justify-content-between  align-items-center"
            to="/seller/shop/edit-shop-details"
            onClick={props.close}
          >
            <span>Edit Shop Details</span>

            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/seller/delivery-options"
            onClick={props.close}
          >
            <span>Delivery Options</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/seller/change-password"
            onClick={props.close}
          >
            <span>Change Password</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/seller/analytics"
            onClick={props.close}
          >
            <span>Analytics</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/"
            onClick={() => {
              props.close();
              dispatch({ type: "SELLER_LOGGING_OUT" });
            }}
          >
            <span>Sign out</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>

          <div className="myAccountDrawer_Title myAccountDrawer_More">
            <span>
              <strong className="fs-6 myAccountDrawer_Title_Text">
                More for You
              </strong>
            </span>
          </div>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/guide"
            onClick={props.close}
          >
            <span>Seller's Guide</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="myAccountDrawer_Links  d-flex justify-content-between  align-items-center"
            to="/support"
            onClick={props.close}
          >
            <span>Support</span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};
export default React.memo(SellerAccountDrawer);
