import "./ShopDetails.css";
import React, { useEffect, useState } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import noEditIcon from "../../Icons/no-edit.png";
import editIcon from "../../Icons/edit.png";
import { useNavigate } from "react-router-dom";
import Backdrop from "../../UI/Backdrop/Backdrop";
import ChangeDP from "../../Components/UploadImages/ChangeDP";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const ShopDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [isFormValid, setIsFormValid] = useState(true);

  const [enteredShopDisplayName, setEnteredShopDisplayName] = useState("");
  const [enteredPhonenumber, setEnteredPhonenumber] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("default");
  const [enteredShopDescription, setEnteredShopDescription] = useState("");
  const [enteredShopAddress, setEnteredShopAddress] = useState("");

  const [enteredShopDisplayNameValid, setEnteredShopDisplayNameValid] =
    useState(true);
  const [enteredPhonenumberValid, setEnteredPhonenumberValid] = useState(true);
  const [selectedCategoryValid, setSelectedCategoryValid] = useState(true);
  const [enteredShopDescriptionValid, setEnteredShopDescriptionValid] =
    useState(true);
  const [enteredShopAddressValid, setEnteredShopAddressValid] = useState(true);

  const [areFieldsDisabled, setAreFieldsDisabled] = useState(true);
  const editFieldsClickHandler = () => {
    setAreFieldsDisabled((prevState) => !prevState);
  };

  const [toggle] = useBodyScrollLock();

  const [whatToShow, setWhatToShow] = useState("one");

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setIsSideDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const drawerBackdropClickHandler = () => {
    setIsSideDrawerOpen(false);
    toggle();
  };

  const formValidationHandler = () => {
    const validation =
      enteredShopDisplayNameValid &&
      selectedCategoryValid &&
      enteredPhonenumberValid &&
      enteredShopDescriptionValid &&
      enteredShopAddressValid;
    setIsFormValid(validation);
  };

  const editProfileClickHandler = async () => {
    if (enteredShopDisplayName.trim() === "") {
      setEnteredShopDisplayNameValid(false);
      return;
    }
    setEnteredShopDisplayNameValid(true);
    if (!(enteredPhonenumber.length === 10)) {
      setEnteredPhonenumberValid(false);
      return;
    }
    setEnteredPhonenumberValid(true);
    if (selectedCategory.trim() === "default") {
      setSelectedCategoryValid(false);
      return;
    }
    setSelectedCategoryValid(true);
    if (enteredShopDescription === "") {
      setEnteredShopDescriptionValid(false);
      return;
    }
    setEnteredShopDescriptionValid(true);
    if (enteredShopAddress === "") {
      setEnteredShopAddressValid(false);
      return;
    }
    setEnteredShopAddressValid(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/edit-shop/edit",
          {
            method: "POST",
            body: JSON.stringify({
              enteredShopDisplayName: enteredShopDisplayName,
              enteredPhonenumber: enteredPhonenumber,
              selectedCategory: selectedCategory,
              enteredShopDescription: enteredShopDescription,
              enteredShopAddress: enteredShopAddress,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        if (response.status === "DETAILS_UPDATED_SUCESSFULLY") {
          localStorage.setItem("sellerName", response.newShopDisplayName);
          dispatch({ type: "SELLER_LOAD" });
          setError("");
          editFieldsClickHandler();
        }
        setIsLoading(false);
      } catch (error) {
        setWhatToShow("unableToReachServer");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Edit Shop Details • Sell at Swoopkart";
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/edit-shop/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
        setError("There was a server error.");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
        setError("There was a server error.");
      }
      if (response.status === "SELLER_NOT_FOUND") {
        setWhatToShow("one");
        setError("Coudn't load your data. Try re-signing in.");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (response.status === "SELLER_LOADED") {
        setWhatToShow("one");
        setEnteredShopDisplayName(response.sellerShopDisplayName);
        setEnteredEmail(response.sellerEmail);
        setEnteredPhonenumber(response.sellerPhoneNumber);
        setSelectedCategory(response.sellerBusinessCategory);
        setEnteredShopDescription(response.sellerShopDescription);
        setEnteredShopAddress(response.sellerAddress);
      }
      setIsLoading(false);
    } catch (err) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}

      <ChangeDP
        show={isSideDrawerOpen}
        close={drawerToggleClickHandler}
        drawerOpen={drawerToggleClickHandler}
      />
      {isSideDrawerOpen && <Backdrop click={drawerBackdropClickHandler} />}
      <SmallNavBar navBarText="Edit Shop Details" />
      {whatToShow === "one" && (
        <div>
          <div className="container-fluid mt-4 user_dp_and_name_editprofile">
            <div className="d-flex justify-content-center">
              <img
                src={sellerDPFromStore}
                alt="dp"
                className="user_dp"
                style={{ width: "80px" }}
              />
            </div>
            <div className=" edit-profile_edit_dp d-flex justify-content-center">
              <strong onClick={drawerToggleClickHandler}>Change</strong>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                className="d-flex justify-content-end mb-3"
                style={{ marginRight: "6px" }}
              >
                {areFieldsDisabled && (
                  <img
                    src={editIcon}
                    style={{ height: "24px" }}
                    alt=""
                    onClick={editFieldsClickHandler}
                  />
                )}
                {!areFieldsDisabled && (
                  <img
                    src={noEditIcon}
                    alt=""
                    style={{ height: "24px" }}
                    onClick={editFieldsClickHandler}
                  />
                )}
              </div>
              {(!enteredShopDisplayNameValid ||
                !enteredShopAddressValid ||
                !enteredShopDescriptionValid ||
                !enteredPhonenumberValid ||
                !selectedCategoryValid ||
                error !== "") && (
                <div className="mb-3 formErrorSeller">
                  {!enteredShopDisplayNameValid && (
                    <span>Enter a valid shop name.</span>
                  )}
                  {!enteredShopAddressValid && (
                    <span>Enter a valid address.</span>
                  )}
                  {!enteredPhonenumberValid && (
                    <span>Enter a valid phone number.</span>
                  )}
                  {!selectedCategoryValid && (
                    <span>Choose a valid business category.</span>
                  )}
                  {!enteredShopDescriptionValid && (
                    <span>Enter a valid shop description.</span>
                  )}
                  {error !== "" && <span>{error}</span>}
                </div>
              )}
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput_shop_edit_name"
                    placeholder="Shop Display Name"
                    disabled={areFieldsDisabled}
                    value={enteredShopDisplayName}
                    onChange={(event) => {
                      setEnteredShopDisplayName(event.target.value);
                    }}
                  />
                  <label htmlFor="floatingInput_shop_edit_name">
                    Shop Display Name
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInput_shop_phone_edit"
                    placeholder="7906834597"
                    disabled={areFieldsDisabled}
                    value={enteredPhonenumber}
                    onChange={(event) => {
                      setEnteredPhonenumber(event.target.value);
                    }}
                  />
                  <label htmlFor="floatingInput_shop_phone_edit">
                    Phone Number
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput_shop_email_edit"
                    placeholder="john@gmail.com"
                    disabled={true}
                    value={enteredEmail}
                    onChange={(event) => {
                      setEnteredEmail(event.target.value);
                    }}
                  />
                  <label htmlFor="floatingInput_shop_email_edit">Email</label>
                </div>

                <div
                  className="mt-1"
                  style={{
                    fontSize: "14.5px",
                    paddingLeft: "2.5px",
                    paddingBottom: "3px",
                  }}
                >
                  Email once registered cannot be changed.
                </div>
              </div>
              <div className="mb-3">
                <select
                  id="floatingInput_shop_category_edit"
                  className="form-select"
                  aria-label="Default select example"
                  disabled={areFieldsDisabled}
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                >
                  <option defaultValue value="default">
                    Choose Business Category
                  </option>
                  <option value="Gadgets">Gadgets</option>
                  <option value="Footwear">Footwear</option>
                  <option value="MensFashion">Men's Fashion</option>
                  <option value="WomensFashion">Women's Fashion</option>
                  <option value="Kids">Kids</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Home">Home</option>
                  <option value="Perfumes">Perfumes</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Groceries">Groceries</option>
                  <option value="Food">Food</option>
                  <option value="Pets">Pets</option>
                  <option value="Toys">Toys</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Gifting">Gifting</option>
                  <option value="Office">Office</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Shop Description"
                    id="floating_Textarea_description_edit"
                    disabled={areFieldsDisabled}
                    value={enteredShopDescription}
                    onChange={(event) => {
                      setEnteredShopDescription(event.target.value);
                    }}
                  ></textarea>
                  <label htmlFor="floating_Textarea_description_edit">
                    Shop Description
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Shop Address"
                    id="floating_Textarea_address_edit"
                    disabled={areFieldsDisabled}
                    value={enteredShopAddress}
                    onChange={(event) => {
                      setEnteredShopAddress(event.target.value);
                    }}
                  ></textarea>
                  <label htmlFor="floating_Textarea_address_edit">
                    Shop Address
                  </label>
                </div>
              </div>
              <div className="nextButton d-flex justify-content-end">
                <button
                  className="nextButtonCustom"
                  disabled={areFieldsDisabled}
                  onClick={editProfileClickHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(ShopDetails);
