import React from "react";
import { useNavigate } from "react-router-dom";
import cancelProcessing from "../../Icons/cancelProcessing.png";
import startProcessing from "../../Icons/startProcessing.png";

const PendingOrderItem = (props) => {
  const navigate = useNavigate();
  const viewMoreHandler = () => {
    navigate("/seller/orders/pending-orders/" + props._id);
  };
  return (
    <div className="orderHistoryItem">
      <div className="container-fluid" onClick={viewMoreHandler}>
        <div className="orderHistoryItemInfo_SoldBy">
          Sold to
          <span className="fw-bold">
            {"  "} {props.soldTo}
          </span>
        </div>
        <div className="orderHistoryItemInfo_OrderedOn">
          {props.orderedOn} <strong>•</strong>{" "}
          <span style={{ color: "rgb(250,103,103)" }}>{props.remarks}</span>
        </div>
        <div className="orderHistoryItemInfo_ImagesOfProducts d-flex justify-content-start align-items-center">
          {props.totalItems >= 1 && (
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[0].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 2 && (
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[1].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 3 && (
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[2].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 4 && (
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[3].productImage
              }
              alt=""
            />
          )}
          {props.totalItems >= 5 && (
            <img
              src={
                process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                "/" +
                props.cartProducts[4].productImage
              }
              alt=""
            />
          )}

          {props.totalItems > 5 && (
            <div className="orderHistoryItemInfo_ImagesOfProductsEnd d-flex justify-content-center align-items-center">
              {props.totalItems - 5}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-5">
            <div className="fw-semibold orderHistoryItemInfo_PandOpHeading">
              Products
            </div>
            <div className="orderHistoryItemInfo_PandOp">
              {props.totalItems} Products
            </div>
          </div>
          <div className="col-5">
            <div className="fw-semibold orderHistoryItemInfo_PandOpHeading">
              Order Price
            </div>
            <div className="orderHistoryItemInfo_PandOp">
              ₹ {props.grandTotal}
            </div>
          </div>
        </div>
        {props.remarks === "Order Placed" && (
          <div className="orderHistoryItemControls d-flex justify-content-end">
            <button
              className="cartItemControls_image smallButtonControlBorder d-flex justify-content-center align-items-center"
              style={{ color: "rgb(250,103,103)", fontSize: "13px" }}
              onClick={() => props.acceptOrder(props._id)}
            >
              <img src={startProcessing} alt="" style={{ height: "14px" }} />
              <span
                className="fw-semibold"
                style={{ fontSize: "14px", paddingLeft: "3%" }}
              >
                Accept
              </span>
            </button>
            <button
              className="cartItemControls_image smallButtonControlBorder d-flex justify-content-center align-items-center"
              style={{ color: "rgb(250,103,103)", fontSize: "13px" }}
              onClick={() => props.rejectOrder(props._id)}
            >
              <img src={cancelProcessing} alt="" style={{ height: "13px" }} />{" "}
              <span
                className="fw-semibold"
                style={{ fontSize: "14px", paddingLeft: "3%" }}
              >
                Reject
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(PendingOrderItem);
