import "./Analytics.css";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import shopVisitCountIcon from "../../Icons/analytics/page-views.png";
import allProductsIcon from "../../Icons/analytics/all.png";
import totalShipmentsIcon from "../../Icons/analytics/total-shipments.png";
import archivedProductsIcon from "../../Icons/analytics/archived.png";
import ProductAnalyticsItem from "./ProductAnalyticsItem/ProductAnalyticsItem";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Analytics • Sell at Swoopkart";
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [isLoading, setIsLoading] = useState(false);
  const [whatToShow, setWhatToShow] = useState("unableToReachServer");

  const [totalShipments, setTotalShipments] = useState(0);
  const [storeViews, setStoreViews] = useState(0);
  const [noOfArchivedProducts, setNoOfArchivedProducts] = useState(0);
  const [productAnalytics, setProductAnalytics] = useState([]);

  const fetchLoadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/analytics/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ANALYTICS_RETRIEVED") {
        setStoreViews(response.shopVisitCount);
        setTotalShipments(response.ordersCount);
        setProductAnalytics(response.productAnalytics);
        setNoOfArchivedProducts(response.noOfArchivedProducts);
        setWhatToShow("one");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchLoadHandler();
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Analytics" />
      {whatToShow === "one" && (
        <div>
          <div className="analyticsContainerSeller">
            <div
              className="fs-5 text-center mb-3 fw-semibold"
              style={{ color: "rgb(250,103,103)" }}
            >
              Simple Analytics
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 analysisitem d-flex flex-column align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex align-items-center">
                        <img
                          src={shopVisitCountIcon}
                          alt=""
                          className="analysisitem_img"
                        />
                      </div>
                      <div className="analysisitem_textdp">
                        <strong>Shop Visit Count</strong>
                      </div>
                    </div>
                    <div className="fs-1 analysisitem_text">
                      <strong>{storeViews}</strong>
                    </div>
                  </div>
                  <div className="col-6 analysisitem d-flex flex-column align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex align-items-center">
                        <img
                          src={totalShipmentsIcon}
                          alt=""
                          className="analysisitem_img"
                        />
                      </div>
                      <div className="analysisitem_textdp">
                        <strong>Total Shipments</strong>
                      </div>
                    </div>
                    <div className="fs-1 analysisitem_text">
                      <strong>{totalShipments}</strong>
                    </div>
                  </div>
                  <div className="col-6 analysisitem d-flex flex-column align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex align-items-center">
                        <img
                          src={allProductsIcon}
                          alt=""
                          className="analysisitem_img"
                        />
                      </div>
                      <div className="analysisitem_textdp">
                        <strong>All Products</strong>
                      </div>
                    </div>
                    <div className="fs-1 analysisitem_text">
                      <strong>{productAnalytics.length}</strong>
                    </div>
                  </div>
                  <div className="col-6 analysisitem d-flex flex-column align-items-center">
                    <div className="d-flex justify-content-start">
                      <div className="d-flex align-items-center">
                        <img
                          src={archivedProductsIcon}
                          alt=""
                          className="analysisitem_img"
                        />
                      </div>
                      <div className="analysisitem_textdp">
                        <strong>Archived Products</strong>
                      </div>
                    </div>
                    <div className="fs-1 analysisitem_text">
                      <strong>{noOfArchivedProducts}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {productAnalytics.length > 0 && (
            <div>
              <div
                className="fs-5 text-center title_ProductAnalytics fw-semibold"
                style={{ color: "rgb(250,103,103)" }}
              >
                Product Analytics
              </div>
              <div className="container-fluid">
                {productAnalytics.map((product) => {
                  return (
                    <ProductAnalyticsItem
                      key={product._id}
                      _id={product._id}
                      productName={product.productName}
                      image={product.productImage}
                      views={product.views}
                      orders={product.soldUnits}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(Analytics);
