import "./SellerQRCodeDrawer.css";
import React, { useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import swoopkartLogo from "../../Icons/icon-white.png";
import copyIcon from "../../Icons/copy.png";
import tickIcon from "../../Icons/tick.png";
import cameraIcon from "../../Icons/camera.png";
import cameraIconPhone from "../../Icons/cameraPhone.png";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";

const SellerQRCodeDrawer = (props) => {
  const navigate = useNavigate();
  const [textToCopy, setTextToCopy] = useState("");
  const copyToClipBoardHandler = () => {
    setTextToCopy(
      "https://www.swoopkart.varunbukka.in/shop/" + sellerIdFromStore + ""
    );
    copy(textToCopy);
    setTimeout(function () {
      setTextToCopy("");
    }, 2500);
  };

  let drawerClasses = "sellerQRCodeDrawer shadow";
  if (props.show === true) {
    drawerClasses = "sellerQRCodeDrawer shadow open";
  } else {
    drawerClasses = "sellerQRCodeDrawer shadow";
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);
  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 200,
      height: 200,
      type: "svg",
      data: "https://www.swoopkart.varunbukka.in/shop/" + sellerIdFromStore,
      image: swoopkartLogo,
      dotsOptions: {
        color: "rgb(250,103,103)",
        type: "rounded",
      },
      backgroundOptions: {
        color: "rgb(255,255,255)",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 5,
      },
    });
    qrCode.append(document.getElementById("canvas"));
  }, []);

  return (
    <div className={drawerClasses}>
      <div className="SellerQRCodeDrawer_Details">
        <div className="SellerQRCodeDrawer_Details_name fs-5">
          <strong>{sellerNameFromStore}</strong>
        </div>
        <div
          id="qrCodeSellerContainerId"
          className="qrCodeSellerContainer d-flex justify-content-center"
        >
          <div
            id="canvas"
            className="qrCodeSeller  d-flex justify-content-center align-items-center"
          ></div>
        </div>
        <div className="SellerQRCodeDrawer_Details_id">
          <strong className="SellerQRCodeDrawer_Details_id_text">
            Seller ID : {sellerIdFromStore}
          </strong>
          <div style={{ color: "rgb(0,0,0)", marginTop: "3.3%" }}>
            <b>
              Scan this code using{" "}
              <img
                src={cameraIcon}
                alt=""
                style={{ height: "17px", padding: "0px 2px 0px 2px" }}
              />{" "}
              Google Lense or your phone's{" "}
              <img
                src={cameraIconPhone}
                alt=""
                style={{ height: "17px", padding: "0px 2px 0px 2px" }}
              />{" "}
              Camera App to reach my shop.
            </b>
          </div>
        </div>
      </div>
      <div className="SellerQRCodeDrawer_controls d-flex flex-column align-items-center justify-content-center fixed-bottom">
        <div
          style={{ width: "100%" }}
          className="d-flex align-items-center justify-content-center"
        >
          <button
            className="d-flex align-items-center justify-content-center"
            onClick={copyToClipBoardHandler}
          >
            {textToCopy === "" && (
              <img
                src={copyIcon}
                alt=""
                style={{ height: "17px", paddingRight: "6px" }}
                onClick={copyToClipBoardHandler}
              />
            )}
            {textToCopy === "" && (
              <span onClick={copyToClipBoardHandler}>Copy</span>
            )}
            {textToCopy !== "" && (
              <img
                src={tickIcon}
                alt=""
                style={{ height: "17px", paddingRight: "6px" }}
              />
            )}
            {textToCopy !== "" && <span>Copied!</span>}
          </button>
        </div>
        <div className="container-fluid mt-2 d-flex justify-content-center">
          <div
            className="container-fluid text-center"
            style={{ fontSize: "13px" }}
          >
            <b>
              Double tap to copy the shop URL. You can also take screenshot of
              the code.
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(SellerQRCodeDrawer);
