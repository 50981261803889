import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BestSellings = (props) => {
  const navigate = useNavigate();
  const goToProductPage = () => {
    navigate("/seller/products/all-products/" + props._id);
  };
  return (
    <div className="productRoot">
      <div className="productRootInner" onClick={goToProductPage}>
        <div className="rootProductImage_cont d-flex justify-content-center">
          <img
            src={
              process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
              "/" +
              props.image
            }
            alt="product"
            className="rootProductImage"
          />
        </div>
        <div style={{ paddingLeft: "3.5%" }}>
          <div className="rootProductTitle d-flex justify-content-start">
            <span>{props.productName}</span>
          </div>
          <div className="rootProductPrice fs-6 justify-content-start">
            <b>₹{props.price}</b> ({props.discount}% Off)
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(BestSellings);
