import "./CreateAccount.css";
import React, { useEffect, useState } from "react";
import LogoNavBar from "../../UI/LogoNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import { useNavigate, useParams } from "react-router-dom";

const CreateAccount = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [whatToShow, setWhatToShow] = useState("one");
  const [isFormOneValid, setIsFormOneValid] = useState(true);
  const [isFormTwoValid, setIsFormTwoValid] = useState(true);
  const [isFormThreeValid, setIsFormThreeValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ one: "", two: "", three: "" });

  //Values
  const [enteredShopDisplayName, setEnteredShopDisplayName] = useState("");
  const [enteredPhonenumber, setEnteredPhonenumber] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("default");

  const [enteredOne, setEnteredOne] = useState("");
  const [enteredTwo, setEnteredTwo] = useState("");
  const [enteredThree, setEnteredThree] = useState("");
  const [enteredFour, setEnteredFour] = useState("");

  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredRepetePassword, setEnteredRepetePassword] = useState("");

  const [enteredQRActivationCode, setEnteredQRActivationCode] = useState("");

  //validation
  function isValidEmail(email) {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
  const [enteredShopDisplayNameValid, setEnteredShopDisplayNameValid] =
    useState(true);
  const [enteredPhonenumberValid, setEnteredPhonenumberValid] = useState(true);
  const [enteredEmailValid, setEnteredEmailValid] = useState(true);
  const [selectedCategoryValid, setSelectedCategoryValid] = useState(true);

  const [enteredOneValid, setEnteredOneValid] = useState(true);
  const [enteredTwoValid, setEnteredTwoValid] = useState(true);
  const [enteredThreeValid, setEnteredThreeValid] = useState(true);
  const [enteredFourValid, setEnteredFourValid] = useState(true);

  const [enteredPasswordValid, setEnteredPasswordValid] = useState(true);
  const [enteredRepetePasswordValid, setEnteredRepetePasswordValid] =
    useState(true);
  const [doesBothPasswordsMatch, setDoesBothPasswordsMatch] = useState(true);

  const [enteredQRActivationCodeValid, setEnteredQRActivationCodeValid] =
    useState(true);

  const showPasswordHandleChange = () => {
    setIsPasswordShown((current) => !current);
  };
  const formOneValidationHandler = () => {
    const validation =
      enteredShopDisplayNameValid &&
      enteredPhonenumberValid &&
      enteredEmailValid &&
      enteredQRActivationCodeValid &&
      selectedCategoryValid;
    setIsFormOneValid(validation);
  };
  const formTwoValidationHandler = () => {
    const validation =
      enteredOneValid &&
      enteredTwoValid &&
      enteredThreeValid &&
      enteredFourValid;
    setIsFormTwoValid(validation);
  };
  const formThreeValidationHandler = () => {
    const validation =
      enteredPasswordValid &&
      enteredRepetePasswordValid &&
      doesBothPasswordsMatch;
    setIsFormThreeValid(validation);
  };

  const nextButtonOneHandler = async () => {
    if (enteredShopDisplayName.trim() === "") {
      setEnteredShopDisplayNameValid(false);
      return;
    }
    setEnteredShopDisplayNameValid(true);
    if (!(enteredPhonenumber.length === 10)) {
      setEnteredPhonenumberValid(false);
      return;
    }
    setEnteredPhonenumberValid(true);
    if (enteredEmail.trim() === "") {
      setEnteredEmailValid(false);
      return;
    }
    if (isValidEmail(enteredEmail)) {
      setEnteredEmailValid(false);
      return;
    }
    setEnteredEmailValid(true);
    if (selectedCategory.trim() === "default") {
      setSelectedCategoryValid(false);
      return;
    }
    setSelectedCategoryValid(true);
    if (params.QRId !== "noqr" && enteredQRActivationCode.trim() === "") {
      setEnteredQRActivationCodeValid(false);
      return;
    }
    setEnteredQRActivationCodeValid(true);
    formOneValidationHandler();
    if (isFormOneValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL +
            "/sign-up/register-details/",
          {
            method: "POST",
            body: JSON.stringify({
              enteredShopDisplayName: enteredShopDisplayName,
              enteredPhonenumber: enteredPhonenumber,
              enteredEmail: enteredEmail,
              selectedCategory: selectedCategory,
              recievedQRId: params.QRId,
              enteredQRActivationCode: enteredQRActivationCode,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const response = await requestSent.json();
        if (response.status === "OTP_NOT_SENT") {
          setError({ one: "Coudn't send the OTP.", two: "", three: "" });
        }
        if (response.status === "EMAIL_ALREADY_EXISTS") {
          setError({
            one: "This email already exists. Try again with different email.",
            two: "",
            three: "",
          });
        }
        if (response.status === "FAILED") {
          setError({ one: "There was a server error.", two: "", three: "" });
        }
        if (response.status === "WRONG_ACTIVATION_CODE") {
          setError({
            one: "You have entered a wrong activation code.",
            two: "",
            three: "",
          });
        }
        if (response.status === "OTP_SENT") {
          setWhatToShow("two");
        }
        if (response.status === "QR_NOT_RGISTERED") {
          setError({
            one: "Invalid QR. The QR is not Registered.",
            two: "",
            three: "",
          });
        }
        if (!response.status) {
          setError({ one: "There was a server error.", two: "", three: "" });
        }
        setIsLoading(false);
      } catch (error) {
        setError({
          one: "Unable to reach the server. Try again later.",
          two: "",
          three: "",
        });
        setIsLoading(false);
      }
    }
  };

  const nextButtonTwoHandler = async () => {
    if (enteredOne.trim() === "") {
      setEnteredOneValid(false);
      setError({ one: "", two: "Incorrect OTP, please try again.", three: "" });
      return;
    }
    setEnteredOneValid(true);
    if (enteredTwo.trim() === "") {
      setEnteredTwoValid(false);
      setError({ one: "", two: "Incorrect OTP, please try again.", three: "" });
      return;
    }
    setEnteredTwoValid(true);
    if (enteredThree.trim() === "") {
      setEnteredThreeValid(false);
      setError({ one: "", two: "Incorrect OTP, please try again.", three: "" });
      return;
    }
    setEnteredThreeValid(true);
    if (enteredFour.trim() === "") {
      setEnteredFourValid(false);
      setError({ one: "", two: "Incorrect OTP, please try again.", three: "" });
      return;
    }
    setEnteredFourValid(true);
    formTwoValidationHandler();
    if (isFormTwoValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/sign-up/verification",
          {
            method: "POST",
            body: JSON.stringify({
              enteredShopDisplayName: enteredShopDisplayName,
              enteredPhonenumber: enteredPhonenumber,
              enteredEmail: enteredEmail,
              selectedCategory: selectedCategory,
              recievedQRId: params.QRId,
              enteredOTP:
                enteredOne +
                "" +
                enteredTwo +
                "" +
                enteredThree +
                "" +
                enteredFour,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError({ one: "", two: "There was a server error.", three: "" });
        }
        if (response.status === "WRONG_OTP") {
          setError({
            one: "",
            two: "Incorrect OTP, please try again.",
            three: "",
          });
        }
        if (response.status === "VERIFICATION_SUCESSFUL") {
          setWhatToShow("three");
        }
        if (!response.status) {
          setError({ one: "", two: "There was a server error.", three: "" });
        }
        setIsLoading(false);
      } catch (error) {
        setError({
          one: "",
          two: "Unable to reach the server. Try again later.",
          three: "",
        });
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    document.title = "Sign Up • Sell at Swoopkart";
  }, []);

  const finishButtonHandler = async () => {
    if (enteredPassword === "") {
      setEnteredPasswordValid(false);
      return;
    }
    if (enteredPassword.length < 9) {
      setEnteredPasswordValid(false);
      return;
    }
    setEnteredPasswordValid(true);
    if (enteredRepetePassword === "") {
      setEnteredRepetePasswordValid(false);
      return;
    }
    if (enteredRepetePassword.length < 9) {
      setEnteredRepetePasswordValid(false);
      return;
    }
    if (enteredRepetePassword !== enteredPassword) {
      setDoesBothPasswordsMatch(false);
      setError({
        one: "",
        two: "",
        three: "Both passwords are not matching. Try again.",
      });
      return;
    }
    setDoesBothPasswordsMatch(true);
    formThreeValidationHandler();
    if (isFormThreeValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/sign-up/create-password",
          {
            method: "POST",
            body: JSON.stringify({
              recievedQRId: params.QRId,
              enteredShopDisplayName: enteredShopDisplayName,
              enteredPhonenumber: enteredPhonenumber,
              enteredEmail: enteredEmail,
              selectedCategory: selectedCategory,
              enteredPassword: enteredPassword,
              enteredRepetePassword: enteredRepetePassword,
            }),
            headers: { "content-type": "application/json" },
          }
        );
        const response = await requestSent.json();
        if (response.status === "PASSWORD_ERROR") {
          setError({
            one: "",
            two: "",
            three:
              "Enter a valid password. It should be greater than 9 characters.",
          });
        }
        if (response.status === "FAILED") {
          setError({ one: "", two: "", three: "There was a server error." });
        }
        if (response.status === "SIGNUP_SUCESSFUL") {
          navigate("/");
          props.openSignInDrawer();
        }
        setIsLoading(false);
      } catch (err) {
        setError({
          one: "",
          two: "",
          three: "Unable to reach the server. Try again later.",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <LogoNavBar />
      <div className="container-fluid">
        <div className="container-fluid">
          {whatToShow === "one" && (
            <div>
              <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                <h3>Sell at Swoopkart</h3>
                <h6 style={{ marginTop: "-5px" }}>
                  Digitizing India's Small Businesses.
                </h6>
              </div>
              {(!enteredShopDisplayNameValid ||
                !enteredPhonenumberValid ||
                !enteredEmailValid ||
                !selectedCategoryValid ||
                !enteredQRActivationCodeValid ||
                error.one !== "") && (
                <div className="mb-3 formErrorSeller">
                  {!enteredShopDisplayNameValid && (
                    <span>Enter a valid shop display name.</span>
                  )}
                  {!enteredPhonenumberValid && (
                    <span>Enter a valid phone number.</span>
                  )}
                  {!enteredEmailValid && (
                    <span>Enter a valid email address.</span>
                  )}
                  {!selectedCategoryValid && (
                    <span>Choose a business category.</span>
                  )}
                  {!enteredQRActivationCodeValid && (
                    <span>Enter a valid QR Activation Code.</span>
                  )}
                  {error.one !== "" && <span>{error.one}</span>}
                </div>
              )}
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control`}
                  id="floatingInput_signup_shop_Displayname"
                  placeholder="Sri Ram Electronics"
                  value={enteredShopDisplayName}
                  onChange={(event) => {
                    setEnteredShopDisplayName(event.target.value);
                  }}
                />
                <label htmlFor="floatingInput_signup_shop_Displayname">
                  Shop Display Name
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className={`form-control`}
                  id="floatingInput_signup_phone"
                  placeholder="7906834597"
                  minLength="10"
                  maxLength="10"
                  value={enteredPhonenumber}
                  onChange={(event) => {
                    setEnteredPhonenumber(event.target.value);
                  }}
                />
                <label htmlFor="floatingInput_signup_phone">Phone Number</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control`}
                  id="floatingInput_signup_email"
                  placeholder="john@gmail.com"
                  value={enteredEmail}
                  onChange={(event) => {
                    setEnteredEmail(event.target.value);
                  }}
                />
                <label htmlFor="floatingInput_signup_email">Email</label>
              </div>
              <select
                className={`form-control mb-3`}
                aria-label="Default select example"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
              >
                <option defaultValue value="default">
                  Choose Business Category
                </option>
                <option value="Gadgets">Gadgets</option>
                <option value="Footwear">Footwear</option>
                <option value="MensFashion">Men's Fashion</option>
                <option value="WomensFashion">Women's Fashion</option>
                <option value="Kids">Kids</option>
                <option value="Stationery">Stationery</option>
                <option value="Home">Home</option>
                <option value="Perfumes">Perfumes</option>
                <option value="Beauty">Beauty</option>
                <option value="Wellness">Wellness</option>
                <option value="Groceries">Groceries</option>
                <option value="Food">Food</option>
                <option value="Pets">Pets</option>
                <option value="Toys">Toys</option>
                <option value="Appliances">Appliances</option>
                <option value="Gifting">Gifting</option>
                <option value="Office">Office</option>
                <option value="Other">Other</option>
              </select>
              {params.QRId !== "noqr" && (
                <div>
                  <div
                    className="mb-3 fs-6 d-flex justify-content-start"
                    style={{ paddingTop: "8px" }}
                  >
                    <b style={{ color: "rgb(250,103,103)" }}>Physical QR</b>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control`}
                      id="floatingInput_signup_qrid"
                      placeholder="ID"
                      value={params.QRId}
                      readOnly={true}
                    />
                    <label htmlFor="floatingInput_signup_qrid">ID</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={`form-control`}
                      id="floatingInput_signup_activationcode"
                      placeholder="Activation Code"
                      value={enteredQRActivationCode}
                      onChange={(event) => {
                        setEnteredQRActivationCode(
                          event.target.value.toUpperCase()
                        );
                      }}
                    />
                    <label htmlFor="floatingInput_signup_activationcode">
                      Activation Code
                    </label>
                  </div>
                </div>
              )}
              <div className="nextButton">
                <button
                  className="nextButtonCustom"
                  onClick={nextButtonOneHandler}
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {whatToShow === "two" && (
            <div>
              <div className="d-flex justify-content-center mb-2">
                <h3>OTP Verification</h3>
              </div>
              <div className="formGeneralSeller text-start">
                <div>
                  <span>
                    Enter the <strong>One Time Password</strong> sent to your
                    email. If not found in your inbox, please check in{" "}
                    <strong>Spam folder</strong>.
                  </span>
                </div>
              </div>
              {(!enteredOneValid ||
                !enteredTwoValid ||
                !enteredThreeValid ||
                !enteredFourValid ||
                error.two !== "") && (
                <div className="mb-3 mt-3 formErrorSeller">
                  <span>{error.two}</span>
                </div>
              )}
              <div className="otpBox d-flex justify-content-center mb-4">
                <input
                  type="text"
                  className="otpFields fs-1"
                  maxLength="1"
                  id="otpFieldOne"
                  value={enteredOne}
                  onChange={(event) => setEnteredOne(event.target.value)}
                />
                <strong className="otpSpacer">.</strong>
                <input
                  type="text"
                  className="otpFields fs-1"
                  maxLength="1"
                  id="otpFieldTwo"
                  value={enteredTwo}
                  onChange={(event) => setEnteredTwo(event.target.value)}
                />
                <strong className="otpSpacer">.</strong>
                <input
                  type="text"
                  className="otpFields fs-1"
                  maxLength="1"
                  id="otpFieldThree"
                  value={enteredThree}
                  onChange={(event) => setEnteredThree(event.target.value)}
                />
                <strong className="otpSpacer">.</strong>
                <input
                  type="text"
                  className="otpFields fs-1"
                  maxLength="1"
                  id="otpFieldFour"
                  value={enteredFour}
                  onChange={(event) => setEnteredFour(event.target.value)}
                />
              </div>
              <div className="nextButton">
                <button
                  onClick={nextButtonTwoHandler}
                  className="nextButtonCustom"
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {whatToShow === "three" && (
            <div>
              <div className="d-flex justify-content-center mb-2">
                <h3>Create Password</h3>
              </div>
              <div className="formGeneralSeller mb-3 text-start">
                <div>
                  <span>
                    Create a <strong>password</strong> that you will be using to
                    signin everytime.
                  </span>
                </div>
              </div>
              {(!enteredPasswordValid ||
                !enteredRepetePasswordValid ||
                error.three !== "") && (
                <div className="formErrorSeller mb-3 text-start">
                  {!enteredPasswordValid && (
                    <span>
                      Enter a valid password greater than 9 characters.
                    </span>
                  )}
                  {!enteredRepetePasswordValid && (
                    <span>
                      Re-Enter a valid password greater than 9 characters.
                    </span>
                  )}
                  {error.three !== "" && <span>{error.three}</span>}
                </div>
              )}
              <div className="form-floating mb-3">
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className="form-control"
                  id="floatingPassword_signup_1"
                  placeholder="Password"
                  value={enteredPassword}
                  onChange={(event) => setEnteredPassword(event.target.value)}
                />
                <label htmlFor="floatingPassword_signup_1">Password</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className="form-control"
                  id="floatingPassword_signup_2"
                  placeholder="Repeat Password"
                  value={enteredRepetePassword}
                  onChange={(event) =>
                    setEnteredRepetePassword(event.target.value)
                  }
                />
                <label htmlFor="floatingPassword_signup_2">
                  Repeat Password
                </label>
              </div>
              <div className="showPasswordOrNotCheckBoxContainer">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={isPasswordShown}
                    onChange={showPasswordHandleChange}
                    id="flexCheckChecked_passshow"
                    defaultChecked={false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked_passshow"
                  >
                    Show Password
                  </label>
                </div>
              </div>
              <div className="disclaimerCreateAccount">
                <span>
                  After setting up your seller account's password you will be
                  redirected to Sign In.
                </span>
              </div>
              <div className="createPassButton">
                <button
                  onClick={finishButtonHandler}
                  className="nextButtonCustom"
                  disabled={!isFormThreeValid}
                >
                  Finish
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <SmallFooter />
    </div>
  );
};
export default React.memo(CreateAccount);
