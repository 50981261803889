import "./AddNewProduct.css";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import uploadImageIcon from "../../Icons/upload.png";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import useBodyScrollLock from "../../hooks/body-scrool-lock";
import Backdrop from "../../UI/Backdrop/Backdrop";
import LogoNavBar from "../../UI/LogoNavBar";
import { useNavigate } from "react-router-dom";

const AddNewProduct = () => {
  const dispatch = useDispatch();
  const inputRefImgCrop = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const navigate = useNavigate();

  const [isFormValid, setIsFormValid] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Add New Product • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [toggle] = useBodyScrollLock();
  const [whatToShow, setWhatToShow] = useState("one");

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setIsSideDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const drawerBackdropClickHandler = () => {
    setIsSideDrawerOpen(false);
    toggle();
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [enteredProductName, setEnteredProductName] = useState("");
  const [enteredProductBrand, setEnteredProductBrand] = useState("");
  const [enteredProductMRP, setEnteredProductMRP] = useState(0);
  const [enteredProductPrice, setEnteredProductPrice] = useState(0);
  const [enteredProductDescription, setEnteredProductDescription] =
    useState("");
  const [enteredProductStock, setEnteredProductStock] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("default");

  const [selectedImageValid, setSelectedImageValid] = useState(true);
  const [enteredProductNameValid, setEnteredProductNameValid] = useState(true);
  const [enteredProductBrandValid, setEnteredProductBrandValid] =
    useState(true);
  const [enteredProductMRPValid, setEnteredProductMRPValid] = useState(true);
  const [enteredProductPriceValid, setEnteredProductPriceValid] =
    useState(true);
  const [enteredProductDescriptionValid, setEnteredProductDescriptionValid] =
    useState(true);
  const [enteredProductStockValid, setEnteredProductStockValid] =
    useState(true);
  const [selectedCategoryValid, setSelectedCategoryValid] = useState(true);

  const formValidationHandler = () => {
    const validation =
      enteredProductNameValid &&
      enteredProductBrandValid &&
      enteredProductMRPValid &&
      enteredProductPriceValid &&
      enteredProductDescriptionValid &&
      enteredProductStockValid &&
      selectedCategoryValid &&
      selectedImageValid;
    setIsFormValid(validation);
  };

  let sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow";
  if (isSideDrawerOpen === true) {
    sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow open";
  } else {
    sellerUploadDPDrawerClasses = "sellerUploadDPDrawer shadow";
  }

  const addProductHandler = async () => {
    if (selectedImage === null) {
      setSelectedImageValid(false);
      return;
    }
    setSelectedImageValid(true);
    if (enteredProductName.trim() === "") {
      setEnteredProductNameValid(false);
      return;
    }
    setEnteredProductNameValid(true);
    if (enteredProductBrand.trim() === "") {
      setEnteredProductBrandValid(false);
      return;
    }
    setEnteredProductBrandValid(true);

    if (enteredProductMRP === "") {
      setEnteredProductMRPValid(false);
      return;
    }
    if (enteredProductMRP <= 0) {
      setEnteredProductMRPValid(false);
      return;
    }
    setEnteredProductMRPValid(true);
    if (enteredProductPrice === "") {
      setEnteredProductPriceValid(false);
      return;
    }
    if (enteredProductPrice > enteredProductMRP) {
      setEnteredProductPriceValid(false);
      return;
    }
    setEnteredProductPriceValid(true);
    if (enteredProductDescription.trim() === "") {
      setEnteredProductDescriptionValid(false);
      return;
    }
    setEnteredProductDescriptionValid(true);
    if (enteredProductStock.trim() === "") {
      setEnteredProductStockValid(false);
      return;
    }
    setEnteredProductStockValid(true);

    if (selectedCategory.trim() === "default") {
      setSelectedCategoryValid(false);
      return;
    }
    setSelectedCategoryValid(true);

    formValidationHandler();

    const imageData = new File(
      [selectedImage],
      Math.floor(Math.random() * 100000000000000 + 1).toString() + ".png",
      { type: "image/png" }
    );
    console.log(imageData);

    const formData = new FormData();
    formData.append("selectedProductImage", imageData);
    formData.append("enteredProductName", enteredProductName);
    formData.append("enteredProductBrand", enteredProductBrand);
    formData.append("enteredProductMRP", enteredProductMRP);
    formData.append("enteredProductPrice", enteredProductPrice);
    formData.append("enteredProductDescription", enteredProductDescription);
    formData.append("enteredProductStock", enteredProductStock);
    formData.append("selectedProductCategory", selectedCategory);
    formData.append("recievedSoldBy", sellerNameFromStore);

    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/add-product/",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "PRODUCT_EXISTS") {
          setError("Product Already Exists. Try again.");
        }
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "PRODUCT_ADDED") {
          setEnteredProductName("");
          setEnteredProductBrand("");
          setEnteredProductMRP("");
          setEnteredProductPrice("");
          setEnteredProductDescription("");
          setEnteredProductStock("");
          setSelectedCategory("default");
          setSelectedImage(null);
          setImageUrl(null);
          setError("");
          setWhatToShow("two");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className={sellerUploadDPDrawerClasses}>
        <div className="mainChangeShopDP_container">
          <div className="container-fluid fw-semibold">
            <div
              className="text-center fs-4 fw-semibold"
              style={{ color: "rgb(250,103,103)" }}
            >
              Product Image
            </div>
            <div className="container-fluid text-center">
              {sellerNameFromStore}, Make sure that you upload an image that has
              1:1 aspect ratio.
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid mt-3">
              {!imageUrl && !selectedImage && (
                <div className="changeCropper_ChangeShopDP">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRefImgCrop}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedImage(e.target.files[0]);
                        console.log(e.target.files[0]);
                      }}
                    />
                    <div>
                      <img
                        src={uploadImageIcon}
                        alt=""
                        onClick={() => {
                          inputRefImgCrop.current.click();
                        }}
                      />
                    </div>
                    <div
                      className="fw-semibold"
                      style={{ fontSize: "15px", color: "rgb(75,75,75)" }}
                    >
                      Click to upload an image
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: "15px", color: "grey" }}
                    >
                      Once the product image is set, it cannot be modifyed in
                      the future.
                    </div>
                  </div>
                </div>
              )}
              {imageUrl && selectedImage && (
                <div className="changeCropper_ChangeShopDP">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <img
                      src={imageUrl}
                      alt={selectedImage.name}
                      style={{
                        height: "145px",
                        width: "145px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <button
                      className="seller_updateProduct_cancelbutton"
                      onClick={() => {
                        setImageUrl(null);
                        setSelectedImage(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="seller_addProduct_button"
                      onClick={drawerToggleClickHandler}
                    >
                      Choose Picture
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isSideDrawerOpen && <Backdrop click={drawerBackdropClickHandler} />}
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "one" && (
        <div>
          <SmallNavBar navBarText="Add New Product" />
          <div className="productmanupulation_container">
            <div className="mb-3 fs-5 d-flex justify-content-center flex-column">
              <div className="d-flex justify-content-center mb-3">
                <strong>Enter Product Details</strong>
              </div>
              <div className="container-fluid">
                <div className="container-fluid">
                  <div className="formGeneralSeller">
                    Describe about the product. These details can be modified
                    later except the image.
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                {(!selectedImageValid ||
                  !enteredProductNameValid ||
                  !enteredProductBrandValid ||
                  !enteredProductMRPValid ||
                  !enteredProductPriceValid ||
                  !enteredProductDescriptionValid ||
                  !enteredProductStockValid ||
                  !selectedCategoryValid ||
                  error !== "") && (
                  <div className="mb-3 formErrorSeller">
                    {!selectedImageValid && (
                      <div>Choose a valid product image.</div>
                    )}
                    {!enteredProductNameValid && (
                      <div>Enter a valid product name.</div>
                    )}
                    {!enteredProductBrandValid && (
                      <div>Enter a valid product brand.</div>
                    )}
                    {!enteredProductMRPValid && (
                      <div>Enter a valid product MRP greater than 0.</div>
                    )}
                    {!enteredProductPriceValid && (
                      <div>
                        Enter a valid product price lesser than or equal to MRP.
                      </div>
                    )}
                    {!enteredProductDescriptionValid && (
                      <div>Enter a valid product description.</div>
                    )}
                    {!enteredProductStockValid && (
                      <div>Enter a valid product stock.</div>
                    )}
                    {!selectedCategoryValid && (
                      <div>Choose a valid product category.</div>
                    )}
                    {error !== "" && <div>{error}</div>}
                  </div>
                )}
                <div className="d-flex flex-column align-items-center mb-4">
                  {imageUrl && selectedImage && (
                    <React.Fragment>
                      <img
                        src={imageUrl}
                        alt={selectedImage.name}
                        onClick={drawerToggleClickHandler}
                        className=" addProduct_uploadedimage"
                      />
                      <span className="text-center mt-1">
                        Once the product image is set, it cannot be modifyed in
                        the future.
                      </span>
                    </React.Fragment>
                  )}
                  {!imageUrl && !selectedImage && (
                    <div
                      className="formGeneralSeller d-flex justify-content-center flex-column align-items-center"
                      style={{
                        width: "100%",
                        border: "2px solid rgb(234, 234, 234)",
                      }}
                    >
                      <img
                        src={uploadImageIcon}
                        alt=""
                        className=" addProduct_uploadimagewatermark"
                        onClick={drawerToggleClickHandler}
                      />
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "15px", color: "rgb(75,75,75)" }}
                      >
                        Click to upload an image
                      </div>
                      <div
                        className="text-center"
                        style={{ fontSize: "15px", color: "grey" }}
                      >
                        Once the product image is set, it cannot be modifyed in
                        the future.
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addProduct_ProductName"
                    placeholder="Product Name"
                    value={enteredProductName}
                    onChange={(event) => {
                      setEnteredProductName(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductName">Product Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addProduct_ProductBrand"
                    placeholder="Brand"
                    value={enteredProductBrand}
                    onChange={(event) => {
                      setEnteredProductBrand(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductName">Brand</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="addProduct_ProductMRP"
                    placeholder="Price"
                    value={enteredProductMRP}
                    onChange={(event) => {
                      setEnteredProductMRP(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductMRP">M.R.P. ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="addProduct_ProductPrice"
                    placeholder="Price"
                    value={enteredProductPrice}
                    onChange={(event) => {
                      setEnteredProductPrice(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductPrice">Price ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="addProduct_ProductDescription"
                    value={enteredProductDescription}
                    onChange={(event) => {
                      setEnteredProductDescription(event.target.value);
                    }}
                    style={{ height: "150px" }}
                  ></textarea>
                  <label htmlFor="addProduct_ProductDescription">
                    Description
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="addProduct_ProductStockAvailable"
                    placeholder="Stock Available"
                    value={enteredProductStock}
                    onChange={(event) => {
                      setEnteredProductStock(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductStockAvailable">
                    Stock Available
                  </label>
                </div>
                <select
                  className={`form-control mb-3`}
                  aria-label="Default select example"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                >
                  <option defaultValue value="default">
                    Choose Business Category
                  </option>
                  <option value="Gadgets">Gadgets</option>
                  <option value="Footwear">Footwear</option>
                  <option value="MensFashion">Men's Fashion</option>
                  <option value="WomensFashion">Women's Fashion</option>
                  <option value="Kids">Kids</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Home">Home</option>
                  <option value="Perfumes">Perfumes</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Groceries">Groceries</option>
                  <option value="Food">Food</option>
                  <option value="Pets">Pets</option>
                  <option value="Toys">Toys</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Gifting">Gifting</option>
                  <option value="Office">Office</option>
                  <option value="Other">Other</option>
                </select>
                <div className="nextButton">
                  <button
                    className="nextButtonCustom"
                    onClick={addProductHandler}
                  >
                    Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "two" && (
        <div>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="d-flex mb-3 justify-content-center">
                <h3>Product Added</h3>
              </div>
              <div className="formSuccessSeller mt-2 mb-1">
                <div>
                  <strong>{`${sellerNameFromStore + ", "}`}</strong>Your request
                  to add a product to your shop was successful. If you want to
                  modify the details, you can.
                </div>
              </div>
              <div
                className="nextButton justify-content-center d-flex"
                style={{ marginTop: "30px" }}
              >
                <button
                  className="nextButtonCustom"
                  onClick={() => {
                    setWhatToShow("one");
                  }}
                  style={{
                    border: "1px solid rgb(250,103,103)",
                    color: "rgb(250,103,103)",
                    backgroundColor: "rgb(255,255,255)",
                    marginRight: "4px",
                    fontSize: "15px",
                  }}
                >
                  Add another
                </button>
                <button
                  className="nextButtonCustom"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    marginLeft: "4px",
                    fontSize: "15px",
                  }}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default React.memo(AddNewProduct);
