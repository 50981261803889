import React, { useEffect } from "react";
import "./LoadingBackdrop.css";
const LoadingBackdrop = (props) => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  return <div className="loadingBackdrop" style={{ height: "100vh" }} />;
};

export default LoadingBackdrop;
