import "./ExistingRegions.css";
import React from "react";
const ExistingAddress = (props) => {
  return (
    <div className="ExistingCouponsItem">
      <div style={{ fontSize: "17px" }}>
        <strong>{props.pincode}</strong>
      </div>
      <span className="fs-6 region_block">{props.block}</span>
      {", "}
      <span className="fs-6 region_region">{props.region}</span>
      {", "}
      <span className="fs-6 region_division">{props.division}</span>
      {", "}
      <span className="fs-6 region_division">{props.district}</span>
      {", "}
      <span className="fs-6 region_state_country">{props.stateAndCountry}</span>
      <div className="fs-6 d-flex delete_region_seller justify-content-end">
        <span
          onClick={() => {
            props.DeleteHandler(props._id);
          }}
        >
          <strong>Delete</strong>
        </span>
      </div>
    </div>
  );
};
export default React.memo(ExistingAddress);
