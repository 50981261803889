import "./ProductAnalyticsItem.css";
import React from "react";
import viewsIcon from "../../../Icons/analytics/view.png";
import soldIcon from "../../../Icons/analytics/sold.png";
import { useNavigate } from "react-router-dom";
const ProductAnalyticsItem = (props) => {
  const navigate = useNavigate();
  const productNavigationHandler = () => {
    navigate("/seller/products/all-products/" + props._id);
  };
  return (
    <div className="ProductAnalyticsItem_container">
      <div
        className="d-flex justify-content-start ProductAnalyticsItem_details"
        onClick={productNavigationHandler}
      >
        <div className="d-flex align-items-center">
          <img
            src={
              process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
              "/" +
              props.image
            }
            alt=""
            className="productAnalysisItem_ProductImg"
          />
        </div>
        <div className="fs-6 d-flex align-items-center IndividualProductAnalysis_productName">
          <strong>{props.productName}</strong>
        </div>
      </div>
      <div className="d-flex justify-content-center ProductAnalyticsItem_analytics">
        <div className="ProductAnalyticsItem_analytics_individualcont">
          <img
            src={viewsIcon}
            alt=""
            className="ProductAnalyticsItem_analytics_img_view"
          />
          <strong className="ProductAnalyticsItem_analytics_text">
            {props.views}
          </strong>
        </div>
        <div className="ProductAnalyticsItem_analytics_individualcont">
          <img
            src={soldIcon}
            alt=""
            className="ProductAnalyticsItem_analytics_img"
          />
          <strong className="ProductAnalyticsItem_analytics_text">
            {props.orders}
          </strong>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ProductAnalyticsItem);
