import "./Dashboard.css";
import React, { useState, useEffect } from "react";
import allProductsIcon from "../../Icons/bottombar/all.png";
import addProductsIcon from "../../Icons/bottombar/add.png";
import archivedProductsIcon from "../../Icons/bottombar/archived.png";
import processedIcon from "../../Icons/bottombar/processed.png";
import pendingIcon from "../../Icons/bottombar/pending.png";
import analyticsIcon from "../../Icons/bottombar/analytics.png";
import deliveryIcon from "../../Icons/bottombar/delivery.png";
import couponsIcon from "../../Icons/bottombar/coupons.png";
import dashboardIcon from "../../Icons/dashboard.png";
import swoopkartIcon from "../../Icons/icon-white.png";
import sellingRegionIcon from "../../Icons/bottombar/region.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = (props) => {
  const [dashboardDescriptionWarning, setDashboardDescriptionWarning] =
    useState(false);
  const [dashboardAddressWarning, setDashboardAddressWarning] = useState(false);
  const [dashboardDPWarning, setDashboardDPWarning] = useState(false);
  const [dashboardDeliveryOptionWarning, setDashboardDeliveryOptionWarning] =
    useState(false);
  const [dashboardSellingRegionWarning, setDashboardSellingRegionWarning] =
    useState(false);

  const [dashboardError, setDashboardError] = useState("");

  const [dashboardPendingOrders, setDashboardPendingOrders] = useState(0);

  const { onLoad } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    onLoad();
    document.title = "Dashboard  • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);
  const navigate = useNavigate();
  const navigationhandler = (url) => {
    navigate(url);
  };

  const fetchLoadHandler = async () => {
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/dashboard/",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setDashboardError("There was a server error.");
      }
      if (response.status === "DASHBOARD_DATA_RETRIEVED") {
        setDashboardDescriptionWarning(response.dashboardDescriptionWarning);
        setDashboardAddressWarning(response.dashboardAddressWarning);
        setDashboardDPWarning(response.dashboardDPWarning);
        setDashboardDeliveryOptionWarning(
          response.dashboardDeliveryOptionWarning
        );
        setDashboardSellingRegionWarning(
          response.dashboardSellingRegionWarning
        );
        setDashboardPendingOrders(response.pendingOrdersCount);
        setDashboardError("");
      }
      if (!response.status) {
        setDashboardError("There was a server error.");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
    } catch (error) {
      setDashboardError("Unable to reach the server. Try again later.");
    }
  };

  useEffect(() => {
    fetchLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div className="dashboardSellerRoot">
      <div className="d-flex justify-content-center align-items-center dashbaord_navbar">
        <div>
          <img src={dashboardIcon} alt="" style={{ height: "25px" }} />
        </div>
        <div style={{ fontSize: "19px", paddingLeft: "5px" }}>
          <span>{sellerNameFromStore}'s Dashboard</span>
        </div>
      </div>
      <div className="container-fluid dashboardPendingOrders_container">
        {(dashboardDescriptionWarning ||
          dashboardAddressWarning ||
          dashboardDPWarning ||
          dashboardDeliveryOptionWarning ||
          dashboardSellingRegionWarning) && (
          <div className="dashboardPendingOrders">
            <div>
              <div className="fw-bold">Finish setting up your account</div>
              {dashboardError !== "" && (
                <div className="formErrorSeller mt-2 mb-2">
                  {dashboardError}
                </div>
              )}
              <div className="d-flex justify-content-start align-items-start flex-column mt-2">
                {dashboardDescriptionWarning && (
                  <div>
                    <b>
                      <b>•</b>
                    </b>{" "}
                    Add a{" "}
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "600" }}
                    >
                      description
                    </span>{" "}
                    to your shop.
                  </div>
                )}
                {dashboardDPWarning && (
                  <div>
                    <b>
                      <b>•</b>
                    </b>{" "}
                    Change your shop's{" "}
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "600" }}
                    >
                      display picture
                    </span>
                    .
                  </div>
                )}
                {dashboardDeliveryOptionWarning && (
                  <div>
                    <b>
                      <b>•</b>
                    </b>{" "}
                    Add a{" "}
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "600" }}
                    >
                      delivery option
                    </span>{" "}
                    to your shop.
                  </div>
                )}
                {dashboardSellingRegionWarning && (
                  <div>
                    <b>
                      <b>•</b>
                    </b>{" "}
                    Add a{" "}
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "600" }}
                    >
                      selling region
                    </span>{" "}
                    to your shop.
                  </div>
                )}
                {dashboardAddressWarning && (
                  <div>
                    <b>
                      <b>•</b>
                    </b>{" "}
                    Add your shop's{" "}
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "600" }}
                    >
                      address.
                    </span>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className="dashboardPendingOrders"
          onClick={() => navigationhandler("/seller/orders/pending-orders")}
        >
          <div className="fw-bold">Pending Orders</div>
          {dashboardPendingOrders > 0 && (
            <div>
              You have{" "}
              <span style={{ color: "rgb(250,103,103)" }} className="fw-bold">
                {dashboardPendingOrders}
              </span>{" "}
              pending orders, respond soon.
            </div>
          )}
          {dashboardPendingOrders <= 0 && (
            <div>Hurray! You have no pending orders.</div>
          )}
        </div>
      </div>
      <div className="container-fluid">
        <div className=" CategoryDashboardText mt-4">Products</div>
      </div>
      <div className="d-flex justify-content-start container-fluid pt-1">
        <div
          className="col- containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/products/all-products")}
        >
          <div>
            <img
              src={allProductsIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>All Products</strong>
          </div>
        </div>
        <div
          className="containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/products/add-new-product")}
        >
          <div>
            <img
              src={addProductsIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Add New</strong>
          </div>
        </div>
        <div
          className="col- containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() =>
            navigationhandler("/seller/products/archived-products")
          }
        >
          <div>
            <img
              src={archivedProductsIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Archived</strong>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className=" CategoryDashboardText mt-4">Orders</div>
      </div>
      <div className="d-flex justify-content-start container-fluid pt-1">
        <div
          className="containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/orders/pending-orders")}
        >
          <div>
            <img
              src={pendingIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Pending</strong>
          </div>
        </div>
        <div
          className="containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/orders/processed-orders")}
        >
          <div>
            <img
              src={processedIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>

          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Processed</strong>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className=" CategoryDashboardText mt-4">Preferences</div>
      </div>
      <div className="container-fluid d-flex justify-content-start pt-1">
        <div
          className=" containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller")}
        >
          <div>
            <img
              src={sellerDPFromStore}
              style={{ borderRadius: "50%" }}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>My Account</strong>
          </div>
        </div>
        <div
          className=" containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/selling-region")}
        >
          <div>
            <img
              src={sellingRegionIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Selling Region</strong>
          </div>
        </div>
        <div
          className=" containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/analytics")}
        >
          <div>
            <img
              src={analyticsIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Analytics</strong>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-start">
        <div
          className=" containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/delivery-options")}
        >
          <div>
            <img
              src={deliveryIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Delivery</strong>
          </div>
        </div>
        <div
          className=" containerDashboardItem d-flex justify-content-center align-items-center flex-column"
          onClick={() => navigationhandler("/seller/coupons")}
        >
          <div>
            <img
              src={couponsIcon}
              alt=""
              className="dashboard_icon_items_root"
            />
          </div>
          <div className="pt-1">
            <strong style={{ fontSize: "13px" }}>Coupons</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
