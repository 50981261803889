import "./Coupons.css";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import ExistingCoupons from "./ExistingCoupons/ExistingCoupons";
import plusIcon from "../../Icons/plus.png";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const Coupons = () => {
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Manage Coupons • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const formValidationHandler = () => {
    const validation = enteredCouponCodeValid && enteredDiscountValid;
    setIsFormValid(validation);
  };
  const [isFormValid, setIsFormValid] = useState(true);
  const [formError, setFormError] = useState("");
  const [error, setError] = useState("");
  const [enteredCouponCode, setEnteredCouponCode] = useState("");
  const [enteredDiscount, setEnteredDiscount] = useState("");

  const [enteredCouponCodeValid, setEnteredCouponCodeValid] = useState(true);
  const [enteredDiscountValid, setEnteredDiscountValid] = useState(true);

  const [couponsRecieved, setCouponsRecieved] = useState([]);

  const [isAddCouponClicked, setIsAddCouponClicked] = useState(false);

  const addCouponToggleHandler = () => {
    setIsAddCouponClicked(true);
  };
  const cancelAddCouponToggleHandler = () => {
    setIsAddCouponClicked(false);
  };
  const addCouponClickHandler = async () => {
    if (enteredCouponCode.trim() === "") {
      setEnteredCouponCodeValid(false);
      return;
    }
    setEnteredCouponCodeValid(true);
    if (enteredDiscount === "") {
      setEnteredDiscountValid(false);
      return;
    }
    setEnteredDiscountValid(true);
    formValidationHandler();
    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/coupons/add-new-coupon/",
          {
            method: "POST",
            body: JSON.stringify({
              enteredCouponCode: enteredCouponCode,
              enteredDiscountPercentage: enteredDiscount,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setFormError("There was a server error.");
        }
        if (response.status === "COUPON_ADDED") {
          setWasCouldntFetch(false);
          setEnteredCouponCode("");
          setEnteredDiscount("");
          cancelAddCouponToggleHandler();
          setCouponsRecieved(response.coupons);
          setFormError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setFormError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  const deleteCouponHandler = async (CouponId) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/coupons/delete-coupon",
        {
          method: "POST",
          body: JSON.stringify({
            recievedCouponId: CouponId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      if (response.status === "COUPON_DELETED") {
        setWasCouldntFetch(false);
        setCouponsRecieved(response.coupons);
        setError("");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/coupons/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        if (response.status === "COUPONS_RETRIEVED") {
          setWasCouldntFetch(false);
          setCouponsRecieved(response.coupons);
          setError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="Coupons" />
      {!wasCouldntFetch && (
        <React.Fragment>
          <div className="globe-Image-region-container">
            <div>
              {!isAddCouponClicked && (
                <div
                  className="MyAccount_addNewAddresses d-flex flex-column"
                  onClick={addCouponToggleHandler}
                >
                  <div className="row" style={{ margin: "0px" }}>
                    <div
                      className="col-2 d-flex justify-content-end"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="MyAccount_addNewAddresses_img">
                        <img src={plusIcon} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-10"
                      style={{ paddingLeft: "6px", marginTop: "-1px" }}
                    >
                      <div
                        className="MyAccount_addNewAddresses_text"
                        style={{ color: "rgb(250,103,103)" }}
                      >
                        <b>New Coupon</b>
                      </div>
                      <div className="MyAccount_addNewAddresses_text_exp">
                        Create a coupon for your customers. This coupon will be
                        applied as a discount on the total bill.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isAddCouponClicked && (
                <div className="addNewAddresses_form">
                  <div>
                    <div className="addNewAddresses_form_heading">
                      <strong className="fs-5">Create New Coupon </strong>
                    </div>
                    {formError !== "" && (
                      <div className="mb-3 formErrorSeller">
                        {formError !== "" && <div>{formError}</div>}
                      </div>
                    )}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="addNewCoupon_CouponCode"
                        placeholder="Block"
                        value={enteredCouponCode}
                        onChange={(event) => {
                          setEnteredCouponCode(
                            event.target.value.toUpperCase()
                          );
                        }}
                      />
                      <label htmlFor="addNewCoupon_CouponCode">
                        Coupon Code
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="addNewCoupon_DiscountPercentage"
                        placeholder="%"
                        value={enteredDiscount}
                        onChange={(event) => {
                          setEnteredDiscount(event.target.value);
                        }}
                      />
                      <label htmlFor="addNewCoupon_DiscountPercentage">
                        Discount %
                      </label>
                    </div>
                  </div>
                  <div className="mt-4 pt-2 d-flex justify-content-center">
                    <button
                      className="seller_updateProduct_cancelbutton"
                      onClick={cancelAddCouponToggleHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="seller_addProduct_button"
                      onClick={addCouponClickHandler}
                    >
                      Add Coupon
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-start mt-5"
              style={{ marginRight: "5%", marginLeft: "6%" }}
            >
              <span
                className="fw-semibold"
                style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
              >
                {sellerNameFromStore}'s Coupons
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="loadedDataSeparaterForItems container-fluid"></div>
              </div>
            </div>
            {error !== "" && (
              <div
                className="mb-3 formErrorSeller"
                style={{ marginRight: "5%", marginLeft: "5%" }}
              >
                {error !== "" && <div>{error}</div>}
              </div>
            )}
            {error === "" && (
              <React.Fragment>
                {couponsRecieved.map((coupons) => {
                  return (
                    <ExistingCoupons
                      key={coupons._id}
                      _id={coupons._id}
                      discount={coupons.discountPercentage}
                      DeleteHandler={deleteCouponHandler}
                    />
                  );
                })}
                {couponsRecieved.length <= 0 && (
                  <div
                    className="formGeneralSeller"
                    style={{ marginRight: "5%", marginLeft: "5%" }}
                  >
                    No coupons found.
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="p-3"></div>
        </React.Fragment>
      )}
      {wasCouldntFetch && <CoudntFetch />}
    </div>
  );
};
export default React.memo(Coupons);
