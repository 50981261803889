import "./IndividualProductPage";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import Footer from "../../UI/Footer/Footer";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import React, { useEffect, useState } from "react";
import ProductReview from "./ProductReview";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoNavBar from "../../UI/LogoNavBar";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import upArrow from "../../Icons/up.png";
import downArrow from "../../Icons/down.png";

const IndividualProductPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [whatToShow, setWhatToShow] = useState("one");

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [reviewsRecieved, setReviewsRecieved] = useState([]);
  const [isDomesticSeller, setIsDomesticSeller] = useState(false);
  const [sellerSellingRegions, setSellerSellingRegions] = useState([]);

  const [isSellingRegionsOpened, setIsSellingRegionsOpened] = useState(false);

  const sellingRegionsToggleClickHandler = () => {
    setIsSellingRegionsOpened((prev) => !prev);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [dataRecieved, setDataRecieved] = useState({
    productImage: "none.png",
    rating: { totalSum: 0, noOfPeople: 0 },
    _id: "",
    productName: "",
    brandName: "",
    MRP: 0,
    price: 0,
    discount: 0,
    category: "",
    description: "",
    stockAvailable: 0,
    views: 0,
    soldUnits: 0,
    archived: false,
    reviews: [],
  });

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const [calculatedRating, setCalculatedRating] = useState(0);
  const [recievedDeliveryInfo, setRecievedDeliveryInfo] = useState({
    deliveryOptionName: "",
    onlyOrderedBefore: "",
    waitTime: "",
  });

  const fetchLoadHandler = async () => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/individual-product/" +
          params.productId,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("notfound");
      }
      if (response.status === "PRODUCT_FOUND") {
        setIsDomesticSeller(response.isDomesticSeller);
        setSellerSellingRegions(response.sellerSellingRegions);
        setDataRecieved(response.product);
        setWhatToShow("one");
        document.title =
          response.product.productName +
          " from " +
          sellerNameFromStore +
          " • Sell at Swoopkart";
        setRecievedDeliveryInfo(response.deliveryInfo);
        setReviewsRecieved(response.product.reviews);
      }
      if (response.status === "PRODUCT_NOT_FOUND") {
        setWhatToShow("notfound");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("notfound");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("notfound");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLoadHandler();
  }, []);

  useEffect(() => {
    setCalculatedRating(
      Math.round(dataRecieved.rating.totalSum / dataRecieved.rating.noOfPeople)
    );
  }, [dataRecieved]);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "one" && (
        <React.Fragment>
          <SmallNavBar
            navBarText={`${
              dataRecieved.productName.length > 19
                ? dataRecieved.productName.slice(0, 19) + "..."
                : dataRecieved.productName
            }`}
          />
          <div>
            <div className="ProductPage bg-white">
              <div className="container-fluid mb-4">
                <div className="ProductPage_Rating d-flex justify-content-end">
                  <div className="productRating">
                    <span
                      className={`fa fa-star ${
                        calculatedRating >= 1 && "checked"
                      }`}
                    ></span>{" "}
                    <span
                      className={`fa fa-star ${
                        calculatedRating >= 2 && "checked"
                      }`}
                    ></span>{" "}
                    <span
                      className={`fa fa-star ${
                        calculatedRating >= 3 && "checked"
                      }`}
                    ></span>{" "}
                    <span
                      className={`fa fa-star ${
                        calculatedRating >= 4 && "checked"
                      }`}
                    ></span>{" "}
                    <span
                      className={`fa fa-star ${
                        calculatedRating >= 5 && "checked"
                      }`}
                    ></span>
                    {"  "}
                    <span className="noOfRatings">{`(${dataRecieved.rating.noOfPeople})`}</span>
                  </div>
                </div>
              </div>
              <div className="ProductPage_ImageContainer d-flex justify-content-center">
                <img
                  src={
                    process.env
                      .REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                    "/" +
                    dataRecieved.productImage
                  }
                  alt="product"
                />
              </div>
              <div className="container-fluid mt-4">
                <div className="container-fluid">
                  <div className="ProductPage_Pricing">
                    <div className="fs-1">
                      <span className="ProductPage_Pricing_Discount">
                        {dataRecieved.discount}% off
                      </span>{" "}
                      <strong>₹{formatNumber(dataRecieved.price)}</strong>
                    </div>
                    <div className="ProductPage_Pricing_MRP fs-6">
                      M.R.P ₹{formatNumber(dataRecieved.MRP)}
                    </div>
                  </div>
                  <div className="ProductPage_TitleAndBrand fs-5">
                    <strong>{dataRecieved.productName}</strong>
                    <div className="fs-6">by {dataRecieved.brandName}</div>
                  </div>
                </div>
              </div>

              <div className="ProductPage_Delivery mb-4">
                <div className="container-fluid">
                  {dataRecieved.stockAvailable > 5 && (
                    <div
                      className="ProductPage_Controls_Stock fw-semibold"
                      style={{ fontSize: "16px" }}
                    >
                      In Stock
                    </div>
                  )}
                  {dataRecieved.stockAvailable < 5 && (
                    <div
                      className="ProductPage_Controls_Stock fw-semibold"
                      style={{ fontSize: "16px", color: "red" }}
                    >
                      Might Not Be In Stock
                    </div>
                  )}
                  <div className="pb-2">
                    <div style={{ fontSize: "18px" }}>
                      Sold by{" "}
                      <Link
                        className="ProductPage_VendorDeltils_Link"
                        to="/shop"
                      >
                        <b>{sellerNameFromStore}</b>
                      </Link>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="ProductPage_Delivery_Container">
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                      >
                        Delivered in
                      </div>
                      {recievedDeliveryInfo && (
                        <div>
                          <div>
                            <b style={{ fontSize: "18px" }}>
                              {recievedDeliveryInfo.waitTime} - (
                              {recievedDeliveryInfo.deliveryOptionName})
                            </b>
                          </div>
                          {recievedDeliveryInfo.onlyOrderedBefore !== "NA" && (
                            <div>
                              Only if ordered before{" "}
                              {recievedDeliveryInfo.onlyOrderedBefore}
                            </div>
                          )}
                          <div>
                            <i>
                              Note : Nominal delivery charges are applicable on
                              this order.
                            </i>
                          </div>
                        </div>
                      )}
                      {!recievedDeliveryInfo && (
                        <div>
                          <b style={{ fontSize: "18px" }}>
                            Sorry, Delivery for this product is temporarily
                            unavailable.
                          </b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProductPage_Description mt-4">
                <div className="container-fluid">
                  <div
                    className="fw-semibold mb-1"
                    style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                  >
                    Description
                  </div>
                  <div className="fs-6">{dataRecieved.description}</div>
                </div>
              </div>
              <div className="container-fluid" style={{ marginTop: "8%" }}>
                <div
                  className="fw-semibold mb-1  d-flex justify-content-start align-items-center"
                  style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                  onClick={sellingRegionsToggleClickHandler}
                >
                  <span style={{ marginRight: "5%" }}>Selling Regions</span>
                  {!isSellingRegionsOpened && (
                    <img src={downArrow} alt="" style={{ height: "16px" }} />
                  )}
                  {isSellingRegionsOpened && (
                    <img src={upArrow} alt="" style={{ height: "16px" }} />
                  )}
                </div>
                {isSellingRegionsOpened && (
                  <div
                    className="AddressForOrder_AddressItem"
                    style={{ marginTop: "10px" }}
                  >
                    <div
                      className="rbBlock d-flex justify-content-start align-items-center"
                      style={{ padding: "3%", margin: "0" }}
                    >
                      <div style={{ fontSize: "15px" }}>
                        <div className="container-fluid">
                          {isDomesticSeller && (
                            <div
                              className=""
                              style={{ color: "rgb(250,103,103)" }}
                            >
                              <strong>
                                <strong style={{ marginRight: "7px" }}>
                                  •
                                </strong>
                                Domestic
                              </strong>{" "}
                              -<span>{"  "}Whole India</span>
                            </div>
                          )}
                          {sellerSellingRegions.map((region) => {
                            return (
                              <div className="" key={region._id}>
                                <strong>
                                  <strong style={{ marginRight: "7px" }}>
                                    •
                                  </strong>
                                  {region.pincode}
                                </strong>{" "}
                                -
                                <span>
                                  {"  "}
                                  {region.division}, {region.district}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="ProductPage_Reviews">
                <div className="container-fluid">
                  <div
                    className="fw-semibold mb-1"
                    style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
                  >
                    Reviews
                  </div>
                </div>
                <div className="reviewsContainer">
                  <React.Fragment>
                    {reviewsRecieved.map((review) => {
                      return (
                        <ProductReview
                          key={review._id}
                          _id={review._id}
                          customerName={review.customerName}
                          review={review.review}
                        />
                      );
                    })}
                    {reviewsRecieved.length <= 0 && (
                      <div className="container-fluid mt-3 mb-3">
                        <div
                          className="formGeneralSeller p-5"
                          style={{ border: "none" }}
                        >
                          <span
                            style={{
                              color: "#494949",
                              fontSize: "16.5px",
                            }}
                          >
                            {" "}
                            Be the first person to review this product.
                          </span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )}
      {whatToShow === "notfound" && (
        <React.Fragment>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <h3>Sorry, this page isn't available.</h3>
            </div>
            <div className="container-fluid mt-3 text-center fs-6">
              The link you followed may be broken, or the page may have been
              removed.{" "}
              <span
                className="fw-semibold"
                style={{ color: "rgb(250,103,103)" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Go back to Dashboard.
              </span>
            </div>
          </div>
          <div className="fixed-bottom">
            <SmallFooter />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default React.memo(IndividualProductPage);
