import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "./UI/NavBar/NavBar";
import Root from "./Routes/Root/Root";
import useBodyScrollLock from "./hooks/body-scrool-lock";
import Backdrop from "./UI/Backdrop/Backdrop";
import SideDrawer from "./UI/SideDrawer/SideDrawer";
import Footer from "./UI/Footer/Footer";
import CreateAccount from "./Routes/CreateAccount/CreateAccount";
import ForgotPassword from "./Routes/ForgotPassword/ForgotPassword";
import SignIn from "./Components/SignIn/SignIn";
import PendingOrders from "./Routes/PendingOrders/PendingOrders";
import ArchivedProducts from "./Routes/ArchivedProducts/ArchivedProducts";
import AddNewProduct from "./Routes/AddNewProduct/AddNewProduct";
import ProcessedOrders from "./Routes/ProcessedOrders/ProcessedOrders";
import DeleteProduct from "./Routes/DeleteProduct/DeleteProduct";
import UpdateProduct from "./Routes/UpdateProduct/UpdateProduct";
import IndividualOrderPage from "./Routes/IndividualOrderPage/IndividualOrderPage";
import MyStoreRoot from "./Routes/MyStoreRoot/MyStoreRoot";
import SellerAccount from "./Routes/SellerAccount/SellerAccount";
import Analytics from "./Routes/Analytics/Analytics";
import ShopDetails from "./Routes/ShopDetails/ShopDetails";
import DeliveryOptions from "./Routes/DeliveryOptions/DeliveryOptions";
import ChangePassword from "./Routes/ChangePassword/ChangePassword";
import AllProducts from "./Routes/AllProducts/AllProducts";
import Region from "./Routes/Region/Region";
import SellerAccountDrawer from "./Components/SellerAccountDrawer/SellerAccountDrawer";
import IndividualProductPage from "./Routes/IndividualProductPage/IndividualProductPage";
import Coupons from "./Routes/Coupons/Coupons";
import PhysicalQR from "./Routes/PhysicalQR/PhysicalQR";
import WantPhysicalQR from "./Routes/WantPhysicalQR/WantPhysicalQR";
import ResetSellerPassword from "./Routes/ForgotPassword/ResetSellerPassword";
import PageNotFound from "./Routes/PageNotFound/PageNotFound";
import { useDispatch } from "react-redux";
import NonMobileScreen from "./NonMobileScreen";
import About from "./Routes/about/About";
import ContactSupport from "./Routes/contactsupport/ContactSupport";
import Dashboard from "./Routes/Dashboard/Dashboard";
import SellersGuide from "./Routes/SellersGuide/SellersGuide";

import "./App.css";
import NotAuthenticated from "./Routes/NotAuthenticated/NotAuthenticated";

const Seller = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isSignInDrawerOpen, setIsSignInDrawerOpen] = useState(false);
  const [isSellerAccountDrawerOpen, setIsSellerAccountDrawerOpen] =
    useState(false);

  const [sellerName, setSellerName] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [sellerPassword, setSellerPassword] = useState("");
  const qrGenerateSellerName = (sellerName) => {
    setSellerName(sellerName);
  };
  const qrGenerateSellerId = (sellerId) => {
    setSellerId(sellerId);
  };
  const qrGenerateSellerPassword = (sellerPass) => {
    setSellerPassword(sellerPass);
  };
  const [toggle] = useBodyScrollLock();
  const drawerToggleClickHandler = () => {
    setIsSideDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const drawerBackdropClickHandler = () => {
    setIsSideDrawerOpen(false);
    toggle();
  };
  const signInToggleClickHandler = () => {
    setIsSignInDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const signInBackdropClickHandler = () => {
    setIsSignInDrawerOpen(false);
    toggle();
  };
  const sellerAccountDrawerToggleClickHandler = () => {
    setIsSellerAccountDrawerOpen((prevState) => !prevState);
    toggle();
  };
  const sellerAccountBackdropClickHandler = () => {
    setIsSellerAccountDrawerOpen(false);
    toggle();
  };

  const [isPageNotFound, setIsPageNotFound] = useState(false);
  const location = useLocation();
  const checkURL =
    location.pathname.includes("sign-up") ||
    location.pathname.includes("forgot-password") ||
    isPageNotFound ||
    location.pathname.includes("seller");

  const pageNotFoundInvokeHandler = () => {
    setIsPageNotFound(true);
  };
  const otherPagesInvokeHandler = () => {
    setIsPageNotFound(false);
  };
  const rootElement = sellerTokenFromStore ? (
    <Dashboard onLoad={otherPagesInvokeHandler} />
  ) : (
    <Root onLoad={otherPagesInvokeHandler} />
  );
  return (
    <React.Fragment>
      <div
        style={{ height: "100vh" }}
        className="d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"
      >
        <div>
          {!checkURL && (
            <NavBar
              drawerToggle={drawerToggleClickHandler}
              signInToggle={signInToggleClickHandler}
              sellerAccountToggle={sellerAccountDrawerToggleClickHandler}
            />
          )}
          <SideDrawer
            show={isSideDrawerOpen}
            close={drawerToggleClickHandler}
            signInOpen={signInToggleClickHandler}
          />
          {isSideDrawerOpen && <Backdrop click={drawerBackdropClickHandler} />}
          <SignIn show={isSignInDrawerOpen} close={signInToggleClickHandler} />
          {isSignInDrawerOpen && (
            <Backdrop click={signInBackdropClickHandler} />
          )}
          <SellerAccountDrawer
            show={isSellerAccountDrawerOpen}
            close={sellerAccountDrawerToggleClickHandler}
          />
          {isSellerAccountDrawerOpen && (
            <Backdrop click={sellerAccountBackdropClickHandler} />
          )}
          <Routes>
            <Route path="/" element={rootElement} />
            <Route
              path="/sign-up/:QRId"
              element={
                !sellerTokenFromStore ? (
                  <CreateAccount
                    openSignInDrawer={signInToggleClickHandler}
                    QRIDToSignUp={props.QRIDToSignUp}
                  />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/want-physical-qr"
              element={
                !sellerTokenFromStore ? (
                  <WantPhysicalQR
                    sellerNameSender={qrGenerateSellerName}
                    sellerPasswordSender={qrGenerateSellerPassword}
                    sellerIdSender={qrGenerateSellerId}
                  />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/want-physical-qr/download-qr"
              element={
                !sellerTokenFromStore ? (
                  <PhysicalQR
                    sellerName={sellerName}
                    sellerId={sellerId}
                    sellerPassword={sellerPassword}
                  />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/:uniqueURL"
              element={
                <ResetSellerPassword goToSignIn={signInToggleClickHandler} />
              }
            />
            <Route
              path="/seller"
              element={
                sellerTokenFromStore ? (
                  <SellerAccount />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/shop"
              element={
                sellerTokenFromStore ? (
                  <MyStoreRoot onLoad={otherPagesInvokeHandler} />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/all-products"
              element={
                sellerTokenFromStore ? (
                  <AllProducts />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/archived-products"
              element={
                sellerTokenFromStore ? (
                  <ArchivedProducts />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/add-new-product"
              element={
                sellerTokenFromStore ? (
                  <AddNewProduct />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/orders/pending-orders"
              element={
                sellerTokenFromStore ? (
                  <PendingOrders />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/orders/processed-orders"
              element={
                sellerTokenFromStore ? (
                  <ProcessedOrders />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/all-products/:productId"
              element={
                sellerTokenFromStore ? (
                  <IndividualProductPage />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/archived-products/:productId"
              element={
                sellerTokenFromStore ? (
                  <IndividualProductPage />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />

            <Route
              path="/seller/products/delete-product/:productId"
              element={
                sellerTokenFromStore ? (
                  <DeleteProduct />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/products/update-product/:productId"
              element={
                sellerTokenFromStore ? (
                  <UpdateProduct />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/analytics"
              element={
                sellerTokenFromStore ? (
                  <Analytics />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/shop/edit-shop-details"
              element={
                sellerTokenFromStore ? (
                  <ShopDetails />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/delivery-options"
              element={
                sellerTokenFromStore ? (
                  <DeliveryOptions />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/change-password"
              element={
                sellerTokenFromStore ? (
                  <ChangePassword />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/selling-region"
              element={
                sellerTokenFromStore ? (
                  <Region />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/orders/pending-orders/:orderId"
              element={
                sellerTokenFromStore ? (
                  <IndividualOrderPage />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/orders/processed-orders/:orderId"
              element={
                sellerTokenFromStore ? (
                  <IndividualOrderPage />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/seller/coupons"
              element={
                sellerTokenFromStore ? (
                  <Coupons />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="/about"
              element={<About onLoad={otherPagesInvokeHandler} />}
            />
            <Route
              path="/guide"
              element={<SellersGuide onLoad={otherPagesInvokeHandler} />}
            />
            <Route
              path="/support"
              element={<ContactSupport onLoad={otherPagesInvokeHandler} />}
            />
            <Route
              path="/seller-not-authenticated"
              element={
                sellerTokenFromStore ? (
                  <NotAuthenticated signInToggle={signInToggleClickHandler} />
                ) : (
                  <PageNotFound onLoad={pageNotFoundInvokeHandler} />
                )
              }
            />
            <Route
              path="*"
              element={<PageNotFound onLoad={pageNotFoundInvokeHandler} />}
            />
          </Routes>
          {!checkURL && <Footer />}
        </div>
      </div>
      <div
        className="d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block"
        style={{ height: "100vh" }}
      >
        <NonMobileScreen />
      </div>
    </React.Fragment>
  );
};
export default Seller;
