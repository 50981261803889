import React from "react";
import { useNavigate } from "react-router-dom";
import LogoNavBar from "../../UI/LogoNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";

const NotAuthenticated = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = "Security Issue Encountered • Sell at Swoopkart";
  }, []);

  return (
    <div>
      <LogoNavBar />
      <div className="container-fluid">
        <div className="container-fluid">
          <div className="text-center fs-5 fw-semibold">
            Security Issues Found
          </div>
          <div className="mt-4">
            <div>Dear Seller,</div>
            <div className="mt-2">
              We noticed that there was an authentication error. That means it
              was not you. For the safety of your data we have safely logged you
              out. You can now sign in and use your account normally. We
              apologize for the inconvenience caused. Happy Selling.
            </div>
            <div className="mt-2">Team Swoopkart</div>
          </div>
          <div className="nextButton">
            <button
              className="nextButtonCustom"
              onClick={() => {
                navigate("/");
                props.signInToggle();
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
      <SmallFooter />
    </div>
  );
};

export default NotAuthenticated;
