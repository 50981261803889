import "./DeleteProduct.css";
import React, { useState, useEffect } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoNavBar from "../../UI/LogoNavBar";

const DeleteProduct = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const deleteConfirmationHandlerChange = () => {
    setIsDeleteConfirmation((current) => !current);
  };

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [enteredProductName, setEnteredProductName] = useState("");
  const [enteredProductBrand, setEnteredProductBrand] = useState("");
  const [enteredProductMRP, setEnteredProductMRP] = useState("");
  const [enteredProductPrice, setEnteredProductPrice] = useState("");
  const [enteredProductDescription, setEnteredProductDescription] =
    useState("");
  const [enteredProductStock, setEnteredProductStock] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("default");

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Delete Product • Sell at Swoopkart";
  }, []);

  const [whatToShow, setWhatToShow] = useState("notfound");

  const onLoadHandler = async () => {
    const requestSent = await fetch(
      process.env.REACT_APP_SELLER_BACKEND_URL + "/delete-product/load/",
      {
        method: "POST",
        body: JSON.stringify({
          productId: params.productId,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + sellerTokenFromStore,
        },
      }
    );
    const response = await requestSent.json();
    if (response.status === "FAILED") {
      setError("");
      setWhatToShow("notfound");
    }
    if (!response.status) {
      setError("");
      setWhatToShow("notfound");
    }
    if (response.status === "PRODUCT_FOUND") {
      setWhatToShow("one");
      setEnteredProductName(response.recievedProductName);
      setEnteredProductBrand(response.recievedProductBrand);
      setEnteredProductMRP(response.recievedMRP);
      setEnteredProductPrice(response.recievedPrice);
      setEnteredProductDescription(response.recievedDescription);
      setEnteredProductStock(response.recievedStock);
      setSelectedCategory(response.recievedSelectedCategory);
      setImageUrl(response.recievedImageUrl);
      setError("");
    }
    if (response.status === "NOT_AUTHENTICATED") {
      dispatch({ type: "SELLER_LOGGING_OUT" });
      navigate("/seller-not-authenticated");
    }
    if (response.status === "PRODUCT_NOT_FOUND") {
      setError("");
      setWhatToShow("notfound");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      onLoadHandler();
    } catch (err) {
      setError("");
      setIsLoading(false);
    }
  }, []);

  const deleteProductHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/delete-product/",
        {
          method: "POST",
          body: JSON.stringify({
            productId: params.productId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (response.status === "PRODUCT_DELETED") {
        setWhatToShow("two");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "one" && (
        <div>
          <SmallNavBar navBarText="Delete Product" />
          <div className="productmanupulation_container">
            <div className="mb-4 fs-5 d-flex justify-content-center">
              <span>
                <strong>Product Details</strong>
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                {error !== "" && (
                  <div className="mb-3 formErrorSeller">
                    {error !== "" && <div>{error}</div>}
                  </div>
                )}
                <div className="d-flex flex-column align-items-center mb-4">
                  <img
                    src={`${
                      process.env
                        .REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                      "/" +
                      imageUrl
                    }`}
                    alt=""
                    className=" addProduct_uploadedimage"
                  />
                  <span className="text-center mt-1">
                    Product image cannot be modifyed.
                  </span>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductName"
                    placeholder="Product Name"
                    disabled={true}
                    value={enteredProductName}
                  />
                  <label htmlFor="addProduct_ProductName">Product Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductBrand"
                    placeholder="Brand"
                    disabled={true}
                    value={enteredProductBrand}
                  />
                  <label htmlFor="addProduct_ProductName">Brand</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductMRP"
                    placeholder="Price"
                    disabled={true}
                    value={enteredProductMRP}
                  />
                  <label htmlFor="addProduct_ProductMRP">M.R.P. ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductPrice"
                    placeholder="Price"
                    disabled={true}
                    value={enteredProductPrice}
                  />
                  <label htmlFor="addProduct_ProductPrice">Price ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductDescription"
                    disabled={true}
                    value={enteredProductDescription}
                    style={{ height: "150px" }}
                  ></textarea>
                  <label htmlFor="addProduct_ProductDescription">
                    Description
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control deleteProductDisabledWhite"
                    id="addProduct_ProductStockAvailable"
                    placeholder="Stock Available"
                    disabled={true}
                    value={enteredProductStock}
                  />
                  <label htmlFor="addProduct_ProductStockAvailable">
                    Stock Available
                  </label>
                </div>
                <select
                  className={`form-control mb-3 deleteProductDisabledWhite`}
                  aria-label="Default select example"
                  value={selectedCategory}
                  disabled={true}
                >
                  <option defaultValue value="default">
                    Choose Business Category
                  </option>
                  <option value="Gadgets">Gadgets</option>
                  <option value="Footwear">Footwear</option>
                  <option value="MensFashion">Men's Fashion</option>
                  <option value="WomensFashion">Women's Fashion</option>
                  <option value="Kids">Kids</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Home">Home</option>
                  <option value="Perfumes">Perfumes</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Groceries">Groceries</option>
                  <option value="Food">Food</option>
                  <option value="Pets">Pets</option>
                  <option value="Toys">Toys</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Gifting">Gifting</option>
                  <option value="Office">Office</option>
                  <option value="Other">Other</option>
                </select>
                <div className="mt-4 d-flex justify-content-start">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isDeleteConfirmation}
                      onChange={deleteConfirmationHandlerChange}
                      id="flexCheckChecked_passshow"
                      defaultChecked={false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked_passshow"
                    >
                      Yes, I understand that I am going to delete this product
                      and I cannot restore it back.
                    </label>
                  </div>
                </div>
                <div className="nextButton">
                  <button
                    className="nextButtonCustom"
                    disabled={!isDeleteConfirmation}
                    onClick={deleteProductHandler}
                  >
                    Delete Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3"></div>
        </div>
      )}
      {whatToShow === "two" && (
        <div>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="d-flex mb-3 justify-content-center">
                <h3>Product Deleted</h3>
              </div>
              <div className="formSuccessSeller mt-2 mb-1">
                <div>
                  <strong>{`${sellerNameFromStore + ", "}`}</strong>Your request
                  to delete the product was successful. It has been removed from
                  your product catlog permanently.
                </div>
              </div>
              <div className="nextButton" style={{ marginTop: "30px" }}>
                <button
                  className="nextButtonCustom"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "notfound" && (
        <div>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <h3>Sorry, this page isn't available.</h3>
            </div>
            <div className="container-fluid mt-3 text-center fs-6">
              The link you followed may be broken, or the page may have been
              removed.{" "}
              <span
                className="fw-semibold"
                style={{ color: "rgb(250,103,103)" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Go back to Dashboard.
              </span>
            </div>
          </div>
          <div className="fixed-bottom" style={{ zIndex: "1" }}>
            <SmallFooter />
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(DeleteProduct);
