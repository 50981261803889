import "./SideDrawer.css";
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import greaterThanIcon from "../../Icons/greater-than.png";
import { useSelector } from "react-redux";
const SideDrawer = (props) => {
  let drawerClasses = "merchantSideDrawer shadow";
  if (props.show) {
    drawerClasses = "merchantSideDrawer shadow open";
  } else {
    drawerClasses = "merchantSideDrawer shadow";
  }
  const navigate = useNavigate();
  const sellerAccountNavigateHandler = () => {
    props.close();
    navigate("/seller");
  };
  const signinNavigateHandler = () => {
    props.close();
    navigate("/");
    props.signInOpen();
  };
  const signupNavigateHandler = () => {
    props.close();
    navigate("/want-physical-qr");
  };

  function openSwoopkartInNewTab() {
    const win = window.open("https://www.swoopkart.varunbukka.in/", "_blank");
    if (win != null) {
      win.focus();
    }
  }

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  return (
    <div className={drawerClasses}>
      {sellerTokenFromStore && (
        <div className="headerSideDrawerSeller d-flex align-items-center justify-content-between">
          <span
            className="headerSideDrawerSeller_username_color"
            style={{ fontSize: "18px" }}
            onClick={sellerAccountNavigateHandler}
          >
            <strong>{sellerNameFromStore}</strong>
          </span>
          <img
            onClick={sellerAccountNavigateHandler}
            src={sellerDPFromStore}
            alt="swoopkart"
            className="sellerIconSideDrawer"
          />
        </div>
      )}
      {!sellerTokenFromStore && (
        <React.Fragment>
          <div className="headerSideDrawer d-flex justify-content-start">
            <span
              className="fs-6 headerSideDrawer_username_color"
              onClick={signinNavigateHandler}
            >
              <strong>Sign in</strong>
            </span>
          </div>
          <div className="headerSideDrawer_notlogged d-flex justify-content-start">
            <span
              className="fs-6 headerSideDrawer_username_color"
              onClick={signupNavigateHandler}
            >
              <strong>Sign up</strong>
            </span>
          </div>
        </React.Fragment>
      )}
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>Most Used</strong>
          </span>
        </div>
        <div className="container-fluid">
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>My Account</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Dashboard</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/analytics");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Analytics</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
        </div>
      </div>
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>Orders</strong>
          </span>
        </div>
        <div className="container-fluid">
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/orders/pending-orders");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Pending Orders</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/orders/processed-orders");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Processed Orders</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
        </div>
      </div>
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>Product</strong>
          </span>
        </div>
        <div className="container-fluid">
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/products/all-products");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>All Products</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/products/add-new-product");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Add New Product</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              if (sellerTokenFromStore) {
                props.close();
                navigate("/seller/products/archived-products");
              }
              if (!sellerTokenFromStore) {
                props.close();
                navigate("/");
                props.signInOpen();
              }
            }}
          >
            <span className="">
              <span>Archived Products</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
        </div>
      </div>
      <div className="categoryContainerSideDrawer">
        <div className="categorySideDrawer">
          <span>
            <strong>More From Swoopkart</strong>
          </span>
        </div>
        <div className="container-fluid">
          <Link
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            to="/forgot-password"
            onClick={props.close}
          >
            <span className="">
              <span>Forgot Password</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <div
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            onClick={() => {
              props.close();
              openSwoopkartInNewTab();
            }}
          >
            <span className="">
              <span>Swoopkart</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </div>
          <Link
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            to="/support"
            onClick={props.close}
          >
            <span className="">
              <span>Support</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
          <Link
            className="linkSideDrawer d-flex justify-content-between  align-items-center"
            to="/guide"
            onClick={props.close}
          >
            <span className="">
              <span>Seller's Guide</span>
            </span>
            <img
              src={greaterThanIcon}
              alt=""
              className="greaterThanSideDrawerIcon"
            />
          </Link>
        </div>
      </div>
      <div style={{ height: "35px" }}></div>
    </div>
  );
};
export default React.memo(SideDrawer);
