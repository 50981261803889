import "./UpdateProduct.css";
import React, { useEffect, useState } from "react";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import LogoNavBar from "../../UI/LogoNavBar";
const UpdateProduct = () => {
  const [isUpdateConfirmation, setIsUpdateConfirmation] = useState(false);
  const updateConfirmationHandlerChange = () => {
    setIsUpdateConfirmation((current) => !current);
  };

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [isFormValid, setIsFormValid] = useState(true);

  const [enteredProductName, setEnteredProductName] = useState("");
  const [enteredProductBrand, setEnteredProductBrand] = useState("");
  const [enteredProductMRP, setEnteredProductMRP] = useState("");
  const [enteredProductPrice, setEnteredProductPrice] = useState("");
  const [enteredProductDescription, setEnteredProductDescription] =
    useState("");
  const [enteredProductStock, setEnteredProductStock] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("default");

  const [enteredProductNameValid, setEnteredProductNameValid] = useState(true);
  const [enteredProductBrandValid, setEnteredProductBrandValid] =
    useState(true);
  const [enteredProductMRPValid, setEnteredProductMRPValid] = useState(true);
  const [enteredProductPriceValid, setEnteredProductPriceValid] =
    useState(true);
  const [enteredProductDescriptionValid, setEnteredProductDescriptionValid] =
    useState(true);
  const [enteredProductStockValid, setEnteredProductStockValid] =
    useState(true);
  const [selectedCategoryValid, setSelectedCategoryValid] = useState(true);

  const formValidationHandler = () => {
    const validation =
      enteredProductNameValid &&
      enteredProductBrandValid &&
      enteredProductMRPValid &&
      enteredProductPriceValid &&
      enteredProductDescriptionValid &&
      enteredProductStockValid &&
      selectedCategoryValid;
    setIsFormValid(validation);
  };

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Update Product • Sell at Swoopkart";
  }, []);

  const [whatToShow, setWhatToShow] = useState("notfound");

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  //update handler
  const updateProductHandler = async () => {
    if (enteredProductName.trim() === "") {
      setEnteredProductNameValid(false);
      return;
    }
    setEnteredProductNameValid(true);
    if (enteredProductBrand.trim() === "") {
      setEnteredProductBrandValid(false);
      return;
    }
    setEnteredProductBrandValid(true);

    if (enteredProductMRP === "") {
      setEnteredProductMRPValid(false);
      return;
    }
    setEnteredProductMRPValid(true);
    if (enteredProductPrice === "") {
      setEnteredProductPriceValid(false);
      return;
    }
    setEnteredProductPriceValid(true);
    if (enteredProductDescription.trim() === "") {
      setEnteredProductDescriptionValid(false);
      return;
    }
    setEnteredProductDescriptionValid(true);
    if (enteredProductStock === "") {
      setEnteredProductStockValid(false);
      return;
    }
    setEnteredProductStockValid(true);

    if (selectedCategory.trim() === "default") {
      setSelectedCategoryValid(false);
      return;
    }
    setSelectedCategoryValid(true);

    formValidationHandler();

    if (isFormValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/update-product/",
          {
            method: "POST",
            body: JSON.stringify({
              productId: params.productId,
              enteredProductName: enteredProductName,
              enteredProductBrand: enteredProductBrand,
              enteredProductMRP: enteredProductMRP,
              enteredProductPrice: enteredProductPrice,
              enteredProductDescription: enteredProductDescription,
              enteredProductStock: enteredProductStock,
              selectedProductCategory: selectedCategory,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setError("There was a server error.");
        }
        if (response.status === "PRODUCT_UPDATED") {
          setEnteredProductName("");
          setEnteredProductBrand("");
          setEnteredProductMRP("");
          setEnteredProductPrice("");
          setEnteredProductDescription("");
          setEnteredProductStock("");
          setSelectedCategory("default");
          setError("");
          setWhatToShow("two");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setError("Unable to reach the server. Try again later.");
        setIsLoading(false);
      }
    }
  };

  //onload
  const onLoadHandler = async () => {
    const requestSent = await fetch(
      process.env.REACT_APP_SELLER_BACKEND_URL + "/update-product/load/",
      {
        method: "POST",
        body: JSON.stringify({
          productId: params.productId,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + sellerTokenFromStore,
        },
      }
    );
    const response = await requestSent.json();
    if (response.status === "FAILED") {
      setError("");
      setWhatToShow("notfound");
    }
    if (!response.status) {
      setError("");
      setWhatToShow("notfound");
    }
    if (response.status === "NOT_AUTHENTICATED") {
      dispatch({ type: "SELLER_LOGGING_OUT" });
      navigate("/seller-not-authenticated");
    }
    if (response.status === "PRODUCT_FOUND") {
      setWhatToShow("one");
      setEnteredProductName(response.recievedProductName);
      setEnteredProductBrand(response.recievedProductBrand);
      setEnteredProductMRP(response.recievedMRP);
      setEnteredProductPrice(response.recievedPrice);
      setEnteredProductDescription(response.recievedDescription);
      setEnteredProductStock(response.recievedStock);
      setSelectedCategory(response.recievedSelectedCategory);
      setImageUrl(response.recievedImageUrl);
      setError("");
    }
    if (response.status === "PRODUCT_NOT_FOUND") {
      setError("");
      setWhatToShow("notfound");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      onLoadHandler();
    } catch (err) {
      setError("");
      setIsLoading(false);
    }
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      {whatToShow === "two" && (
        <div>
          <LogoNavBar />
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="d-flex mb-3 justify-content-center">
                <h3>Product Updated</h3>
              </div>
              <div className="formSuccessSeller mt-2 mb-1">
                <div>
                  <strong>{`${sellerNameFromStore + ", "}`}</strong>Your request
                  to update the product was successful. It has been updated in
                  your product catlog permanently.
                </div>
              </div>
              <div className="nextButton" style={{ marginTop: "30px" }}>
                <button
                  className="nextButtonCustom"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {whatToShow === "notfound" && (
        <div>
          <div>
            <LogoNavBar />
            <div className="container-fluid">
              <div className="d-flex justify-content-center">
                <h3>Sorry, this page isn't available.</h3>
              </div>
              <div className="container-fluid mt-3 text-center fs-6">
                The link you followed may be broken, or the page may have been
                removed.{" "}
                <span
                  className="fw-semibold"
                  style={{ color: "rgb(250,103,103)" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go back to Swoopkart Seller.
                </span>
              </div>
            </div>
            <div className="fixed-bottom" style={{ zIndex: "1" }}>
              <SmallFooter />
            </div>
          </div>
        </div>
      )}
      {whatToShow === "one" && (
        <div>
          <SmallNavBar navBarText="Update Product" />
          <div className="productmanupulation_container">
            <div className="mb-4 fs-5 d-flex justify-content-center">
              <span>
                <strong>Product Details</strong>
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                {(!enteredProductNameValid ||
                  !enteredProductBrandValid ||
                  !enteredProductMRPValid ||
                  !enteredProductPriceValid ||
                  !enteredProductDescriptionValid ||
                  !enteredProductStockValid ||
                  !selectedCategoryValid ||
                  error !== "") && (
                  <div className="mb-3 formErrorSeller">
                    {!enteredProductNameValid && (
                      <div>Enter a valid product name.</div>
                    )}
                    {!enteredProductBrandValid && (
                      <div>Enter a valid product brand.</div>
                    )}
                    {!enteredProductMRPValid && (
                      <div>Enter a valid product MRP.</div>
                    )}
                    {!enteredProductPriceValid && (
                      <div>Enter a valid product price.</div>
                    )}
                    {!enteredProductDescriptionValid && (
                      <div>Enter a valid product description.</div>
                    )}
                    {!enteredProductStockValid && (
                      <div>Enter a valid product stock.</div>
                    )}
                    {!selectedCategoryValid && (
                      <div>Choose a valid product category.</div>
                    )}
                    {error !== "" && <div>{error}</div>}
                  </div>
                )}
                <div className="d-flex flex-column align-items-center mb-4">
                  <img
                    src={`${
                      process.env
                        .REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
                      "/" +
                      imageUrl
                    }`}
                    alt=""
                    className=" addProduct_uploadedimage"
                  />
                  <span className="text-center mt-1">
                    Product image cannot be modifyed.
                  </span>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addProduct_ProductName"
                    placeholder="Product Name"
                    value={enteredProductName}
                    onChange={(event) => {
                      setEnteredProductName(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductName">Product Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addProduct_ProductBrand"
                    placeholder="Brand"
                    value={enteredProductBrand}
                    onChange={(event) => {
                      setEnteredProductBrand(event.target.value);
                    }}
                  />
                  <label htmlFor="addProduct_ProductName">Brand</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="updProduct_ProductMRP"
                    placeholder="M.R.P"
                    value={enteredProductMRP}
                    onChange={(event) => {
                      setEnteredProductMRP(event.target.value);
                    }}
                  />
                  <label htmlFor="updProduct_ProductMRP">M.R.P. ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="updProduct_ProductPrice"
                    placeholder="Price"
                    value={enteredProductPrice}
                    onChange={(event) => {
                      setEnteredProductPrice(event.target.value);
                    }}
                  />
                  <label htmlFor="updProduct_ProductPrice">Price ₹</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="addProduct_ProductDescription"
                    value={enteredProductDescription}
                    onChange={(event) => {
                      setEnteredProductDescription(event.target.value);
                    }}
                    style={{ height: "150px" }}
                  ></textarea>
                  <label htmlFor="addProduct_ProductDescription">
                    Description
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="updProduct_ProductStockAvailable"
                    placeholder="Stock Available"
                    value={enteredProductStock}
                    onChange={(event) => {
                      setEnteredProductStock(event.target.value);
                    }}
                  />
                  <label htmlFor="updProduct_ProductStockAvailable">
                    Stock Available
                  </label>
                </div>
                <select
                  className={`form-control mb-3`}
                  aria-label="Default select example"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                >
                  <option defaultValue value="default">
                    Choose Business Category
                  </option>
                  <option value="Gadgets">Gadgets</option>
                  <option value="Footwear">Footwear</option>
                  <option value="MensFashion">Men's Fashion</option>
                  <option value="WomensFashion">Women's Fashion</option>
                  <option value="Kids">Kids</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Home">Home</option>
                  <option value="Perfumes">Perfumes</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Wellness">Wellness</option>
                  <option value="Groceries">Groceries</option>
                  <option value="Food">Food</option>
                  <option value="Pets">Pets</option>
                  <option value="Toys">Toys</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Gifting">Gifting</option>
                  <option value="Office">Office</option>
                  <option value="Other">Other</option>
                </select>
                <div className="mt-4 d-flex justify-content-start">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isUpdateConfirmation}
                      onChange={updateConfirmationHandlerChange}
                      id="flexCheckChecked_passshow"
                      defaultChecked={false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked_passshow"
                    >
                      Yes, I understand that I am going to update this product
                      and I cannot revert the changes back.
                    </label>
                  </div>
                </div>
                <div className="nextButton">
                  <button
                    disabled={!isUpdateConfirmation}
                    className="nextButtonCustom"
                    onClick={updateProductHandler}
                  >
                    Update Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3"></div>
        </div>
      )}
    </div>
  );
};
export default React.memo(UpdateProduct);
