import { useNavigate } from "react-router-dom";
import React from "react";
const Product = (props) => {
  const navigate = useNavigate();
  const openFullProductPageHandler = () => {
    navigate("/seller/products/all-products/" + props._id);
  };
  const calculatedRating = Math.round(
    props.rating.totalSum / props.rating.noOfPeople
  );
  return (
    <div
      className="searchResultProduct d-flex justify-content-start row"
      onClick={openFullProductPageHandler}
    >
      <div className="col-5 d-flex justify-content-center">
        <img
          src={
            process.env.REACT_APP_SELLER_BACKEND_PRODUCT_IMAGE_LOCATION +
            "/" +
            props.image
          }
          alt="product"
          className="productImage"
        />
      </div>
      <div className="col-7 productDetails d-flex flex-column justify-content-center align-items-start">
        {/* 30 Words */}
        <span className="productTitle ">{props.productName}</span>
        <span className="productPrice fw-bold fs-5">₹{props.price}</span>
        <div className="fs-6 searchResultProduct_discount">
          <strike>₹{props.mrp}</strike>
          <span>
            {" "}
            {"  "}({props.discount}% off)
          </span>
        </div>
        {/* 20 Words */}
        <span className="productBrand">{props.brand}</span>
        <div className="productRating">
          <span
            className={`fa fa-star ${calculatedRating >= 1 && "checked"}`}
          ></span>{" "}
          <span
            className={`fa fa-star ${calculatedRating >= 2 && "checked"}`}
          ></span>{" "}
          <span
            className={`fa fa-star ${calculatedRating >= 3 && "checked"}`}
          ></span>{" "}
          <span
            className={`fa fa-star ${calculatedRating >= 4 && "checked"}`}
          ></span>{" "}
          <span
            className={`fa fa-star ${calculatedRating >= 5 && "checked"}`}
          ></span>
          {"  "}
          <span className="noOfRatings">{`(${props.rating.noOfPeople})`}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Product);
