import {
    createStore
} from "redux";


const reducerFunction = (state = {
    sellerData: {}
}, action) => {
    if (action.type === "SELLER_LOAD") {
        const sellerTokenData = localStorage.getItem('seller');
        const sellerNameData = localStorage.getItem('sellerName');
        const sellerDPData = localStorage.getItem('sellerDP');
        const sellerIdData = localStorage.getItem('sellerId');
        if (!sellerTokenData || !sellerNameData || !sellerDPData || !sellerIdData) {
            return {
                sellerData: {},
            }
        }
        if (sellerTokenData || sellerNameData || sellerDPData || sellerIdData) {
            return {
                sellerData: {
                    sellerToken: sellerTokenData,
                    sellerDP: sellerDPData,
                    sellerName: sellerNameData,
                    sellerId: sellerIdData
                },
            }
        }
    }
    if (action.type === "SELLER_LOGGING_IN") {
        const sellerTokenData = localStorage.getItem('seller');
        const sellerNameData = localStorage.getItem('sellerName');
        const sellerDPData = localStorage.getItem('sellerDP');
        const sellerIdData = localStorage.getItem('sellerId');
        return {
            sellerData: {
                sellerToken: sellerTokenData,
                sellerDP: sellerDPData,
                sellerName: sellerNameData,
                sellerId: sellerIdData
            },
        }
    }
    if (action.type === "SELLER_LOGGING_OUT") {
        localStorage.removeItem('seller');
        localStorage.removeItem('sellerId');
        localStorage.removeItem('sellerName');
        localStorage.removeItem('sellerDP');
        return {
            sellerData: {}
        }
    }
    return state;
};

const store = createStore(reducerFunction);

export default store;