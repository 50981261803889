import "./WantPhysicalQR.css";
import React, { useState } from "react";
import PhysicalQR from "../../Icons/bannerQRCodes.png";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import Footer from "../../UI/Footer/Footer";
import { useNavigate } from "react-router-dom";
const WantPhysicalQR = (props) => {
  const navigate = useNavigate();
  const [enteredSellerName, setEnteredSellerName] = useState("");
  const [isShopNameClicked, setIsShopNameClicked] = useState(false);
  const shopNameToggleHandler = () => {
    setIsShopNameClicked(true);
  };
  const cancelShopNameToggleHandler = () => {
    setIsShopNameClicked(false);
  };
  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  const shopNameClickHandler = () => {
    const randomId = Math.floor(Math.random() * 100000000 + 1).toString();
    var randomPassword = randomString(7, "ABCDEFGHIJKLMNOPQRSTUVWXYZ");
    setIsShopNameClicked(false);
    navigate("/want-physical-qr/download-qr");
    props.sellerNameSender(enteredSellerName);
    props.sellerPasswordSender(randomPassword);
    props.sellerIdSender(randomId);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="wantAPhysicalQR_main">
          <div className="WPQR_tagline text-center">
            <b>Digitizing India's Small Businesses.</b>
          </div>
          <div className="WPQR_tagline_Free text-center">
            <b>Free Physical QR's</b>
          </div>
          <div className="WPQR_tagline_moretext text-center">
            <b style={{ color: "rgb(50,50,20)" }}>for your shop.</b>
          </div>
          <div className="WPQR_PhysicalQR d-flex justify-content-center">
            <img src={PhysicalQR} alt="" style={{ height: "310px" }} />
          </div>
          <div className="whyPhysicalQR_Container">
            <div className="d-flex container-fluid justify-content-start mb-1 whyPhysicalQR_Heading">
              <strong>Why Should You Opt ?</strong>
            </div>
            <div className="container-fluid">
              <div>
                If you are having a <strong>physical store</strong>, then we
                suggest you to opt for free Physical QR.
              </div>
              <div>
                You can stick the QR in your shop's display which will let
                people know that they can shop from your digital store.
              </div>
            </div>
            <div className="container-fluid">
              <div>
                If you are wishing to run a complete{" "}
                <strong>digital business</strong>, then physical QR becomes
                optional.
              </div>
              <div>
                Don't worry, we provide an in app QR which can be shared, for
                our 100% online sellers.
              </div>
            </div>
          </div>
          <div className="WPQR_buttons_controls container-fluid">
            {!isShopNameClicked && (
              <div>
                <button
                  className="WPQR_buttons_c fw-semibold"
                  onClick={shopNameToggleHandler}
                >
                  Yes, I want physical QR
                </button>
              </div>
            )}
            {isShopNameClicked && (
              <div className="enterShopNameContainer">
                <div className="mb-2 fs-5 d-flex justify-content-center">
                  <b>Your Shop Name</b>
                </div>
                <div className="text-center mb-3">
                  Printing your name is completely optional. You can write it
                  later.
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="qr_Entershopname"
                    placeholder="Shop Name"
                    value={enteredSellerName}
                    onChange={(event) => {
                      setEnteredSellerName(event.target.value);
                    }}
                  />
                  <label htmlFor="qr_Entershopname">Shop Name</label>
                </div>
                <div className="enterShopNameContainer_Controls mt-4 d-flex justify-content-end">
                  <button
                    className="seller_updateProduct_cancelbutton"
                    onClick={cancelShopNameToggleHandler}
                  >
                    Cancel
                  </button>
                  <button
                    className="seller_addProduct_button"
                    onClick={shopNameClickHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            {!isShopNameClicked && (
              <div>
                <button
                  className="WPQR_buttons mt-3 text-black fw-semibold"
                  onClick={() => {
                    navigate("/sign-up/noqr");
                  }}
                >
                  No, let me create my account
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantPhysicalQR;
