import "./AllProducts.css";
import AllProductsItem from "./AllProductsItem/AllProductsItem";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import emptyImage from "../../Icons/empty.png";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const AllProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "All Products • Sell at Swoopkart";
  }, []);

  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const [isLoading, setIsLoading] = useState(false);
  const [whatToShow, setWhatToShow] = useState("unableToReachServer");
  const [dataRecieved, setDataRecieved] = useState([]);
  const [totalResults, setTotalResults] = useState(null);

  const archiveHandler = async (ProductId) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/all-products/archive/",
        {
          method: "POST",
          body: JSON.stringify({
            recievedProductId: ProductId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWhatToShow("unableToReachServer");
      }
      if (response.status === "ARCHIVED") {
        setWhatToShow("one");
        navigate("/seller/products/archived-products/");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWhatToShow("unableToReachServer");
      }
      setIsLoading(false);
    } catch (error) {
      setWhatToShow("unableToReachServer");
      setIsLoading(false);
    }
  };
  const updateHandler = async (ProductId) => {
    navigate("/seller/products/update-product/" + ProductId);
  };
  const deleteHandler = async (ProductId) => {
    navigate("/seller/products/delete-product/" + ProductId);
  };

  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/all-products/fetch/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWhatToShow("unableToReachServer");
        }
        if (response.status === "PRODUCT_RETRIEVED") {
          setDataRecieved((prev) => prev.concat(response.products));
          setTotalResults(response.totalResults);
          setWhatToShow("one");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWhatToShow("unableToReachServer");
        }
        setIsLoading(false);
      } catch (error) {
        setWhatToShow("unableToReachServer");
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}
      <SmallNavBar navBarText="All Products" />
      {whatToShow === "one" && (
        <div>
          {dataRecieved.length > 0 && (
            <div className="text-center container-fluid mt-3 mb-3">
              <div className="dashboardPendingOrders container-fluid">
                <div className="fw-semibold ">
                  Hey {sellerNameFromStore}, You are having{" "}
                  <span style={{ color: "rgb(250,103,103)" }}>
                    {totalResults}
                  </span>{" "}
                  products in your store.
                </div>
              </div>
            </div>
          )}
          <React.Fragment>
            <div className="">
              {dataRecieved.map((product) => {
                return (
                  <AllProductsItem
                    key={product._id}
                    _id={product._id}
                    price={formatNumber(product.price)}
                    brandName={product.brandName}
                    productName={product.productName}
                    mrp={formatNumber(product.MRP)}
                    discount={product.discount}
                    rating={product.rating}
                    image={product.productImage}
                    ArchiveHandler={archiveHandler}
                    UpdateHandler={updateHandler}
                    DeleteHandler={deleteHandler}
                  />
                );
              })}
              {dataRecieved.length <= 0 && (
                <div className="container-fluid mt-3 mb-3">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "75vh" }}
                  >
                    <div
                      style={{
                        color: "#494949",
                        fontSize: "20px",
                      }}
                      className="fw-semibold d-flex justify-content-center align-items-center flex-column"
                    >
                      <img
                        src={emptyImage}
                        alt=""
                        style={{ height: "160px" }}
                      />{" "}
                      <div>No products found in your shop.</div>
                      <div
                        className="fw-semibold"
                        style={{ fontSize: "15px", color: "rgb(250,103,103)" }}
                        onClick={() => {
                          navigate("/seller/products/add-new-product");
                        }}
                      >
                        Add New Product.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="p-3"></div>
          </React.Fragment>
        </div>
      )}
      {whatToShow === "unableToReachServer" && (
        <div>
          <CoudntFetch />
        </div>
      )}
    </div>
  );
};
export default React.memo(AllProducts);
