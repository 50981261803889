import "./About.css";
import React, { useEffect } from "react";
import founderImage from "../../Icons/founder.jpeg";
import twitter from "../../Icons/twitter.png";
import linkedIn from "../../Icons/linkedin.png";

const About = (props) => {
  const { onLoad } = props;

  useEffect(() => {
    document.title = "About Us • Sell at Swoopkart";
    onLoad();
  }, []);
  function openTwitterInNewTab() {
    const win = window.open("https://www.twitter.com/thevarunbukka", "_blank");
    if (win != null) {
      win.focus();
    }
  }
  function openLinkedInInNewTab() {
    const win = window.open(
      "https://in.linkedin.com/in/thevarunbukka",
      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  }
  return (
    <div className="aboutUsContainer">
      <div className="d-flex justify-content-center">
        <div className="aboutUsContainer_aboutUs">
          <strong style={{ color: "rgb(250,103,103)" }}>about us</strong>
        </div>
      </div>
      <div className="container-fluid aboutUs_paragraph">
        <div className="mt-2" style={{ paddingLeft: "4px" }}>
          We empower local businesses, small businesses, startups and individual
          business owners by digitizing them and providing an e-store. Sellers
          can sign up instantly, list all products in their store and start
          selling. The Seller has the flexibility of his delivery range, he can
          go local or country-wide just by managing his delivery options for the
          customers. As he recives an order from the customer he can just
          arrange a delivery chosen by the customer and dispatch the shipment.
          The seller also gets the delivery charges.
        </div>
        <div className="mt-3" style={{ paddingLeft: "4px" }}>
          We at Swoopkart are very passionate about our mission to digitize
          local businesses and for that we have made this all absolutely FREE
          for our sellers. We aspire to be a one-stop solution for scaling our
          seller's business. We have also made purchasing easier for our
          consumers as they can shop online from their most favourite stores
          near them or far away from them.
        </div>
      </div>
      <div className="d-flex justify-content-center ">
        <div className="aboutUsContainer_aboutUs">
          <strong style={{ color: "rgb(250,103,103)" }}>developed by</strong>
        </div>
      </div>
      <div className="aboutUs_founder">
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <img
                src={founderImage}
                alt=""
                className="aboutUs_founder_img mt-3"
              />
            </div>
            <div className="aboutUs_founder_name">
              <h3>
                <strong>Varun Bukka</strong>
              </h3>
            </div>
            {/* <div className="aboutUs_founder_founder">Founder</div> */}
            <div style={{ marginTop: "-2px" }}>
              <div className="founderSocialHandle">
                <img src={twitter} alt="" onClick={openTwitterInNewTab} />
              </div>
              <div className="founderSocialHandle">
                <img src={linkedIn} alt="" onClick={openLinkedInInNewTab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(About);
