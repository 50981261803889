import "./PhysicalQR.css";
import React, { useEffect } from "react";
import html2canvas from "html2canvas";
import swoopkartLogo from "../../Icons/dark.png";
import qrIcon from "../../Icons/icon-white.png";
import cameraIcon from "../../Icons/phoneCamera.png";
import googleLenseIcon from "../../Icons/googleLense.png";
import QRCodeStyling from "qr-code-styling";
import swoopkartSellerLogo from "../../Icons/SellerLogo.png";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import LogoNavBar from "../../UI/LogoNavBar";
import { redirect } from "react-router-dom";

const PhysicalQR = (props) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrToShow, setQRToShow] = useState(true);

  const [genereatedRandomId, setGenereatedRandomId] = useState(props.sellerId);
  const [genereatedRandomCode, setGenereatedRandomCode] = useState(
    props.sellerPassword
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!genereatedRandomId) {
      navigate("/want-physical-qr/");
    }
  }, [genereatedRandomId]);

  useEffect(() => {
    let tempURLMaker;
    if (genereatedRandomId) {
      tempURLMaker = (
        "https://www.swoopkart.varunbukka.in/shop/" +
        genereatedRandomId +
        "/"
      ).toString();
    }
    if (!genereatedRandomId) {
      tempURLMaker = "";
    }
    const finalURLGenerated = tempURLMaker;

    const qrCode = new QRCodeStyling({
      width: 250,
      height: 250,
      type: "svg",
      data: finalURLGenerated,
      image: qrIcon,
      dotsOptions: {
        color: "rgb(250,103,103)",
        type: "rounded",
      },
      backgroundOptions: {
        color: "rgb(255,255,255)",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 8,
      },
      // cornersSquareOptions: {
      //   type: "rounded",
      // },
      // cornersDotOptions: {
      //   type: "dot",
      // },
    });
    qrCode.append(document.getElementById("QRDownloadContainer_QR_CODE"));
  }, []);
  const imageDownloadHandler = async () => {
    setIsLoading(true);
    try {
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL + "/physical-qr/",
        {
          method: "POST",
          body: JSON.stringify({
            randomId: genereatedRandomId,
            randomPassword: genereatedRandomCode,
          }),
          headers: { "content-type": "application/json" },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setError("There was a server error.");
      }
      if (!response.status) {
        setError("There was a server error.");
      }
      if (response.status === "QR_REGISTERED") {
        const screenshotTarget = document.getElementById("QRDownloadContainer");
        html2canvas(screenshotTarget).then((canvas) => {
          const base64image = canvas.toDataURL("image/png");
          var anchor = document.createElement("a");
          anchor.setAttribute("href", base64image);
          anchor.setAttribute("download", "PhysicalQR.png");
          anchor.click();
          anchor.remove();
          setQRToShow(false);
        });
        setError("");
      }
      setIsLoading(false);
    } catch (error) {
      setError("Unable to reach the server. Try again later.");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {genereatedRandomId && (
        <div>
          {isLoading && <LoadingSpinner />}
          {isLoading && <LoadingBackdrop />}
          {/* <SmallNavBar navBarText="Download Your QR" /> */}
          {qrToShow && (
            <div>
              <div>
                <div className="WPQR_tagline_GreatChoice text-center">
                  <b>Great Choice!</b>
                </div>
                <div className="WPQR_tagline_GreatChoiceText_1 text-center">
                  <b>Here is your QR, Download it.</b>
                </div>
              </div>
              <div
                className="container-fluid "
                style={{ marginTop: "30px", marginBottom: "10px" }}
              >
                <div className="nextButton" style={{ marginTop: "-5px" }}>
                  <button
                    className="nextButtonCustom fw-semibold"
                    onClick={imageDownloadHandler}
                    style={{
                      color: "white",
                      marginBottom: "18px",
                      marginTop: "2px",
                      backgroundColor: "rgb(250,103,103)",
                      fontSize: "16px",
                    }}
                  >
                    Download Your QR
                  </button>
                </div>
                {error !== "" && (
                  <div className="formErrorSeller mb-4">
                    <span>{error}</span>
                  </div>
                )}
              </div>
              <div id="QRDownloadContainer">
                <div className="innercontainer_qr_white">
                  <div className="d-flex justify-content-center QRDownloadContainer_logo_container">
                    <img
                      src={swoopkartLogo}
                      alt=""
                      className="QRDownloadContainer_logo"
                    />
                  </div>
                  <div className="QRDownloadContainer_QR d-flex justify-content-center">
                    <div id="QRDownloadContainer_QR_CODE"></div>
                  </div>
                  <div className="QRDownloadContainer_Slogan d-flex justify-content-center">
                    <b>Shop Online</b>
                  </div>
                  <div className="QRDownloadContainer_TextDescription container-fluid text-center">
                    <div style={{ color: "rgb(0,0,0)", marginTop: "1%" }}>
                      <b>
                        Scan this code using{" "}
                        <img
                          src={googleLenseIcon}
                          alt=""
                          style={{ height: "17px", padding: "0px 2px 0px 2px" }}
                        />{" "}
                        Google Lense or your phone's{" "}
                        <img
                          src={cameraIcon}
                          alt=""
                          style={{ height: "17px", padding: "0px 2px 0px 2px" }}
                        />{" "}
                        Camera App to reach my shop.
                      </b>
                    </div>
                  </div>
                  <div className="d-flex QRDownloadContainer_logoSeller_container justify-content-center">
                    <img
                      src={swoopkartSellerLogo}
                      alt=""
                      className="QRDownloadContainer_logoSeller"
                    />
                  </div>
                </div>
                <div className="QRDownloadContainer_ShopName text-center">
                  {props.sellerName !== "" && (
                    <div style={{ marginTop: "-15px" }}>{props.sellerName}</div>
                  )}
                  {props.sellerName === "" && (
                    <div
                      className="text-start"
                      style={{ fontSize: "15px", marginTop: "3px" }}
                    >
                      Name
                    </div>
                  )}
                </div>
                <div className="QRDownloadContainer_CredentialsPrinted d-flex align-items-center text-white justify-content-center">
                  <span className="col-4 text-center">
                    ID : <span>{genereatedRandomId}</span>
                  </span>
                  <span className="col-7 text-center">
                    Activation Code : <span>{genereatedRandomCode}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
          {!qrToShow && (
            <div className="container-fluid">
              <div>
                <div className="WPQR_tagline_GreatChoice text-center">
                  <b>What Next ?</b>
                </div>
                <div className="WPQR_tagline_GreatChoiceText text-center">
                  <b>Here is what you should do!</b>
                </div>
              </div>
              <div className="whatNextDownloadQR_textContainer container-fluid">
                <div className="whatNextDownloadQR_textContainer_individual">
                  <b className="whatNextDownloadQR_textContainer_bold fs-5">
                    Step 1
                  </b>
                  <div className="whatNextDownloadQR_textContainer_text">
                    Print your downloaded QR. It will be in your phone's gallery
                    or files.
                  </div>
                </div>
                <div className="whatNextDownloadQR_textContainer_individual">
                  <b className="whatNextDownloadQR_textContainer_bold fs-5">
                    Step 2
                  </b>
                  <div className="whatNextDownloadQR_textContainer_text ">
                    Scan your shop's QR with{" "}
                    <img
                      src={googleLenseIcon}
                      alt=""
                      style={{ height: "18px", padding: "0px 2px 0px 2px" }}
                    />{" "}
                    Google Lense or your{" "}
                    <img
                      src={cameraIcon}
                      alt=""
                      style={{ height: "18px", padding: "0px 2px 0px 2px" }}
                    />{" "}
                    Phone's Camera, This will take to Create Seller Account
                    Page.
                  </div>
                </div>
                <div className="whatNextDownloadQR_textContainer_individual">
                  <b className="whatNextDownloadQR_textContainer_bold fs-5">
                    Step 3
                  </b>
                  <div className="whatNextDownloadQR_textContainer_text">
                    While filling your details, Enter the Code on the bottom of
                    your Physical QR. This is to verify your QR.
                  </div>
                </div>
                <div className="whatNextDownloadQR_textContainer_individual">
                  <b className="whatNextDownloadQR_textContainer_bold fs-5">
                    Finally
                  </b>
                  <div className="whatNextDownloadQR_textContainer_text">
                    When you sign up sucessfully, whenever your customers scan
                    your QR they directly navigated to your online store.
                  </div>
                </div>
                <div
                  className="whatNextDownloadQR_easy d-flex justify-content-center fs-6 mt-4"
                  // style={{ color: "rgb(250,103,103)" }}
                >
                  <b className="mt-3">Sign up and activate your QR now.</b>
                </div>
                <div
                  className="whatNextDownloadQR_easy d-flex justify-content-center fs-6 mb-3"
                  style={{ color: "rgb(250,103,103)" }}
                >
                  <b>Easy Right : )</b>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default React.memo(PhysicalQR);
