import "./DeliveryOptions.css";
import plusIcon from "../../Icons/plus.png";
import React, { useState, useEffect } from "react";
import LoadingBackdrop from "../../UI/LoadingSpinner/LoadingBackdrop";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import SmallNavBar from "../../UI/SmallNavBar/SmallNavBar";
import SmallFooter from "../../UI/SmallFooter/SmallFooter";
import ExistingDeliveryOptions from "./ExistingDeliveryOptions/ExistingDeliveryOptions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CoudntFetch from "../../UI/CoudntFetch/CoudntFetch";

const DeliveryOptions = () => {
  const [wasCouldntFetch, setWasCouldntFetch] = useState(false);
  const navigate = useNavigate();
  const [isAddDeliveryOptionClicked, setIsAddDeliveryOptionClicked] =
    useState(false);
  const [isDomesticDelivery, setIsDomesticDelivery] = useState(false);
  const addDeliveryOptionToggleHandler = () => {
    setIsAddDeliveryOptionClicked(true);
  };
  const cancelAddDeliveryOptionToggleHandler = () => {
    setDeliveryOptionName("");
    setDeliveryCharges("");
    setDeliveryOrderedBefore("");
    setDeliveryWaitTime("");
    setSelectedTimeUnit("default");
    setIsAddDeliveryOptionClicked(false);
  };
  const [isDomesticDeliveryOptionClicked, setIsDomesticDeliveryOptionClicked] =
    useState(false);
  const editDomesticDeliveryOptionToggleHandler = () => {
    setIsDomesticDeliveryOptionClicked(true);
  };
  const cancelDomesticDeliveryOptionToggleHandler = () => {
    setIsDomesticDeliveryOptionClicked(false);
  };

  const goToSellingRegionHandler = () => {
    navigate("/seller/selling-region");
  };
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SELLER_LOAD" });
    document.title = "Delivery Options • Sell at Swoopkart";
  }, []);
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);

  const formOneValidationHandler = () => {
    const validation =
      deliveryOptionNameValid &&
      deliveryChargesValid &&
      deliveryOrderedBeforeValid &&
      deliveryWaitTimeValid &&
      selectedTimeUnitValid;
    setIsFormOneValid(validation);
  };

  const [isFormOneValid, setIsFormOneValid] = useState(true);
  const [deliveryOptionError, setDeliveryOptionError] = useState("");
  const [error, setError] = useState("");
  const [deliveryOptionName, setDeliveryOptionName] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [deliveryOrderedBefore, setDeliveryOrderedBefore] = useState("");
  const [deliveryWaitTime, setDeliveryWaitTime] = useState("");
  const [selectedTimeUnit, setSelectedTimeUnit] = useState("");

  const [optionsRecieved, setOptionsRecieved] = useState([]);

  const [deliveryOptionNameValid, setDeliveryOptionNameValid] = useState(true);
  const [deliveryChargesValid, setDeliveryChargesValid] = useState(true);
  const [deliveryOrderedBeforeValid, setDeliveryOrderedBeforeValid] =
    useState(true);
  const [deliveryWaitTimeValid, setDeliveryWaitTimeValid] = useState(true);
  const [selectedTimeUnitValid, setSelectedTimeUnitValid] = useState(true);

  const formTwoValidationHandler = () => {
    const validation =
      domesticDeliveryChargesValid && domesticDeliveryWaitTimeValid;
    setIsFormTwoValid(validation);
  };
  const [isFormTwoValid, setIsFormTwoValid] = useState(true);
  const [domesticDeliveryError, setDomesticDeliveryError] = useState("");

  const [domesticDeliveryCharges, setDomesticDeliveryCharges] = useState("");
  const [domesticDeliveryWaitTime, setDomesticDeliveryWaitTime] = useState("");

  const [domesticDeliveryChargesValid, setDomesticDeliveryChargesValid] =
    useState(true);
  const [domesticDeliveryWaitTimeValid, setDomesticDeliveryWaitTimeValid] =
    useState(true);

  // Adding Delivery Option
  const addDeliveryOptionClickHandler = async () => {
    if (deliveryOptionName.trim() === "") {
      setDeliveryOptionError("Enter a valid delivery option name.");
      setDeliveryOptionNameValid(false);
      return;
    }
    setDeliveryOptionNameValid(true);
    if (deliveryCharges === "") {
      setDeliveryOptionError("Enter valid delivery charges.");
      setDeliveryChargesValid(false);
      return;
    }
    setDeliveryChargesValid(true);
    if (deliveryOrderedBefore.trim() === "") {
      setDeliveryOptionError("Enter some valid delivery note.");
      setDeliveryOrderedBeforeValid(false);
      return;
    }
    setDeliveryOrderedBeforeValid(true);
    if (deliveryWaitTime === "") {
      setDeliveryOptionError("Enter a valid wait time.");
      setDeliveryWaitTimeValid(false);
      return;
    }
    setDeliveryWaitTimeValid(true);
    if (selectedTimeUnit.trim() === "default") {
      setDeliveryOptionError("Select a valid time unit.");
      setSelectedTimeUnitValid(false);
      return;
    }
    setSelectedTimeUnitValid(true);
    formOneValidationHandler();
    if (isFormOneValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL +
            "/delivery-options/add-new-option",
          {
            method: "POST",
            body: JSON.stringify({
              deliveryOptionName: deliveryOptionName,
              deliveryCharges: deliveryCharges,
              deliveryOrderedBefore: deliveryOrderedBefore,
              deliveryWaitTime: deliveryWaitTime,
              selectedTimeUnit: selectedTimeUnit,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setDeliveryOptionError("There was a server error.");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setDeliveryOptionError("There was a server error.");
        }
        if (response.status === "OPTION_ADDED") {
          setWasCouldntFetch(false);
          setOptionsRecieved(response.deliveryOptions);
          setDomesticDeliveryCharges(response.domesticDeliveryPrice);
          setDomesticDeliveryWaitTime(response.domesticDeliveryWaitTime);
          setIsDomesticDelivery(response.domesticDelivery);
          setDeliveryOptionError("");
          cancelAddDeliveryOptionToggleHandler();
        }
        setIsLoading(false);
      } catch (err) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  // updating Domestic Delivery
  const submitDomesticDeliveryOptionClickHandler = async () => {
    if (domesticDeliveryCharges === "") {
      setDomesticDeliveryError("Enter a valid domestic delivery charge.");
      setDomesticDeliveryChargesValid(false);
      return;
    }
    setDomesticDeliveryChargesValid(true);
    if (domesticDeliveryWaitTime === "") {
      setDomesticDeliveryError("Enter a valid domestic delivery wait time.");
      setDomesticDeliveryWaitTimeValid(false);
      return;
    }
    setDomesticDeliveryWaitTimeValid(true);
    formTwoValidationHandler();
    if (isFormTwoValid) {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL +
            "/delivery-options/update-domestic-delivery",
          {
            method: "POST",
            body: JSON.stringify({
              domesticDeliveryCharges: domesticDeliveryCharges,
              domesticDeliveryWaitTime: domesticDeliveryWaitTime,
            }),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setDomesticDeliveryError("There was a server error.");
          setWasCouldntFetch(false);
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
          setWasCouldntFetch(false);
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setDomesticDeliveryError("There was a server error.");
        }
        if (response.status === "DOMESTIC_DELIVERY_UPDATED") {
          cancelDomesticDeliveryOptionToggleHandler();
          setWasCouldntFetch(false);
          setOptionsRecieved(response.deliveryOptions);
          setDomesticDeliveryCharges(response.domesticDeliveryPrice);
          setDomesticDeliveryWaitTime(response.domesticDeliveryWaitTime);
          setIsDomesticDelivery(response.domesticDelivery);
          setDomesticDeliveryError("");
          cancelAddDeliveryOptionToggleHandler();
        }
        setIsLoading(false);
      } catch (err) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    }
  };

  // delete option
  const deleteDeliveryOption = async (OptionId) => {
    try {
      setIsLoading(true);
      const requestSent = await fetch(
        process.env.REACT_APP_SELLER_BACKEND_URL +
          "/delivery-options/delete-delivery-option",
        {
          method: "POST",
          body: JSON.stringify({
            recievedOptionId: OptionId,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + sellerTokenFromStore,
          },
        }
      );
      const response = await requestSent.json();
      if (response.status === "FAILED") {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      if (response.status === "OPTION_DELETED") {
        setWasCouldntFetch(false);
        setOptionsRecieved(response.deliveryOptions);
        setDomesticDeliveryCharges(response.domesticDeliveryPrice);
        setDomesticDeliveryWaitTime(response.domesticDeliveryWaitTime);
        setIsDomesticDelivery(response.domesticDelivery);
        setError("");
      }
      if (response.status === "NOT_AUTHENTICATED") {
        dispatch({ type: "SELLER_LOGGING_OUT" });
        navigate("/seller-not-authenticated");
      }
      if (!response.status) {
        setWasCouldntFetch(false);
        setError("There was a server error.");
      }
      setIsLoading(false);
    } catch (error) {
      setWasCouldntFetch(true);
      setIsLoading(false);
    }
  };

  // useEffect load
  useEffect(() => {
    const fetchLoadHandler = async () => {
      setIsLoading(true);
      try {
        const requestSent = await fetch(
          process.env.REACT_APP_SELLER_BACKEND_URL + "/delivery-options/",
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + sellerTokenFromStore,
            },
          }
        );
        const response = await requestSent.json();
        if (response.status === "FAILED") {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        if (response.status === "OPTIONS_RETRIEVED") {
          setWasCouldntFetch(false);
          setOptionsRecieved(response.deliveryOptions);
          setDomesticDeliveryCharges(response.domesticDeliveryPrice);
          setDomesticDeliveryWaitTime(response.domesticDeliveryWaitTime);
          setIsDomesticDelivery(response.domesticDelivery);
          setError("");
        }
        if (response.status === "NOT_AUTHENTICATED") {
          dispatch({ type: "SELLER_LOGGING_OUT" });
          navigate("/seller-not-authenticated");
        }
        if (!response.status) {
          setWasCouldntFetch(false);
          setError("There was a server error.");
        }
        setIsLoading(false);
      } catch (error) {
        setWasCouldntFetch(true);
        setIsLoading(false);
      }
    };
    fetchLoadHandler();
  }, []);

  const formatNumber = (x) => {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {isLoading && <LoadingBackdrop />}

      <SmallNavBar navBarText="Delivery Options" />
      {!wasCouldntFetch && (
        <React.Fragment>
          <div className="globe-Image-region-container">
            <div>
              {!isAddDeliveryOptionClicked && (
                <div
                  className="MyAccount_addNewAddresses d-flex flex-column align-items-center"
                  onClick={addDeliveryOptionToggleHandler}
                >
                  <div className="row" style={{ margin: "0px" }}>
                    <div
                      className="col-2 d-flex justify-content-end"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="MyAccount_addNewAddresses_img">
                        <img src={plusIcon} alt="" />
                      </div>
                    </div>
                    <div
                      className="col-10"
                      style={{ paddingLeft: "6px", marginTop: "-1px" }}
                    >
                      <div
                        className="MyAccount_addNewAddresses_text"
                        style={{ color: "rgb(250,103,103)" }}
                      >
                        <b>New Delivery Option</b>
                      </div>
                      <div className="MyAccount_addNewAddresses_text_exp">
                        Add your custom delivery option for your customers. All
                        your delivery options will be applicable on all products
                        of your store.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isAddDeliveryOptionClicked && (
                <div className="addNewAddresses_form mb-5">
                  <div>
                    <div className="addNewAddresses_form_heading">
                      <strong className="fs-5">
                        New Custom Delivery Option
                      </strong>
                    </div>
                    {deliveryOptionError !== "" && (
                      <div className="mb-3 formErrorSeller">
                        {deliveryOptionError !== "" && (
                          <div>{deliveryOptionError}</div>
                        )}
                      </div>
                    )}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="addDeliveryOption_Name"
                        placeholder="Delivery Option Name"
                        value={deliveryOptionName}
                        onChange={(event) => {
                          setDeliveryOptionName(event.target.value);
                        }}
                      />
                      <label htmlFor="addDeliveryOption_Name">
                        Delivery Option Name
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="addDeliveryOption_DeliveryCharges"
                        placeholder="Delivery Charges ₹"
                        value={deliveryCharges}
                        onChange={(event) => {
                          setDeliveryCharges(event.target.value);
                        }}
                      />
                      <label htmlFor="addDeliveryOption_DeliveryCharges">
                        Delivery Charges ₹
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="delivetyOptions_ProductDeliveredOB"
                        placeholder="Only If Ordered Before, Eg : 11:00AM"
                        value={deliveryOrderedBefore}
                        onChange={(event) => {
                          setDeliveryOrderedBefore(event.target.value);
                        }}
                      />
                      <label htmlFor="delivetyOptions_ProductDeliveredOB">
                        Only If Ordered Before, Eg : 11:00AM
                      </label>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                      <div className="form-floating col-6 waittime_adddelivery_div">
                        <input
                          type="number"
                          className="form-control"
                          id="addDeliveryOption_WaitTime"
                          placeholder="Delivery Wait Time"
                          value={deliveryWaitTime}
                          onChange={(event) => {
                            setDeliveryWaitTime(event.target.value);
                          }}
                        />
                        <label htmlFor="addDeliveryOption_WaitTime">
                          Wait Time
                        </label>
                      </div>
                      <div className="col-6">
                        <select
                          className="form-select waittimeunits_adddelivery_div fs-6"
                          aria-label="Choose Time Units"
                          value={selectedTimeUnit}
                          onChange={(event) => {
                            setSelectedTimeUnit(event.target.value);
                          }}
                        >
                          <option defaultValue value="default">
                            Choose
                          </option>
                          <option value="Minutes">Minutes</option>
                          <option value="Hours">Hours</option>
                          <option value="Days">Days</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2 d-flex justify-content-center">
                    <button
                      className="seller_updateProduct_cancelbutton"
                      onClick={cancelAddDeliveryOptionToggleHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="seller_addProduct_button"
                      onClick={addDeliveryOptionClickHandler}
                    >
                      Add Option
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-start mt-5"
              style={{ marginRight: "5%", marginLeft: "6%" }}
            >
              <span
                className="fw-semibold"
                style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
              >
                Domestic Delivery
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="loadedDataSeparaterForItems container-fluid"></div>
              </div>
            </div>
            <div>
              {!isDomesticDeliveryOptionClicked && (
                <div className="addDeliveryDomesticDeliveryDataContainer">
                  <div>
                    <strong style={{ fontSize: "17px" }}>
                      ₹{formatNumber(domesticDeliveryCharges)}
                    </strong>
                  </div>
                  <div>
                    <div className="fs-6">
                      Delivered Within {domesticDeliveryWaitTime} Days.
                    </div>
                  </div>
                  <div
                    className="mt-1 fs-6 enableDomesticDeliveryDisclaimer"
                    onClick={goToSellingRegionHandler}
                  >
                    <span
                      style={{ color: "rgb(250,103,103)", fontWeight: "500" }}
                    >
                      Click to {isDomesticDelivery ? "disable" : "enable"}{" "}
                      domestic selling in selling region.
                    </span>
                  </div>
                  <div className="d-flex delete_coupon_seller justify-content-end">
                    <strong onClick={editDomesticDeliveryOptionToggleHandler}>
                      Edit
                    </strong>
                  </div>
                </div>
              )}
              {isDomesticDeliveryOptionClicked && (
                <div className="addNewAddresses_form mt-4 pt-3 mb-5">
                  <div className="addNewAddresses_form_heading">
                    <strong className="fs-5">Domestic Delivery</strong>
                  </div>
                  <div>
                    {domesticDeliveryError !== "" && (
                      <div className="mb-3 formErrorSeller">
                        {domesticDeliveryError !== "" && (
                          <div>{domesticDeliveryError}</div>
                        )}
                      </div>
                    )}
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="floatingInput_domesticDeliveryCharges"
                        placeholder="Delivery Charges, Eg : ₹89"
                        value={domesticDeliveryCharges}
                        onChange={(event) => {
                          setDomesticDeliveryCharges(event.target.value);
                        }}
                      />
                      <label htmlFor="floatingInput_domesticDeliveryCharges">
                        Delivery Charges, Eg : ₹89
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="floatingInput_domesticDeliveryWaitTime"
                        placeholder="Wait Time In Days, Eg : 5 Days"
                        value={domesticDeliveryWaitTime}
                        onChange={(event) => {
                          setDomesticDeliveryWaitTime(event.target.value);
                        }}
                      />
                      <label htmlFor="floatingInput_domesticDeliveryWaitTime">
                        Wait time in days, Eg : 5 Days
                      </label>
                    </div>
                  </div>
                  <div className="mt-4 pt-2 d-flex justify-content-center">
                    <button
                      className="seller_updateProduct_cancelbutton"
                      onClick={cancelDomesticDeliveryOptionToggleHandler}
                    >
                      Cancel
                    </button>
                    <button
                      className="seller_addProduct_button"
                      onClick={submitDomesticDeliveryOptionClickHandler}
                    >
                      Done
                    </button>
                  </div>
                  <div className="mt-3 enableDomesticDeliveryDisclaimer d-flex justify-content-center">
                    <span onClick={goToSellingRegionHandler}>
                      Enable domestic selling in selling region.
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-start mt-5"
              style={{ marginRight: "5%", marginLeft: "6%" }}
            >
              <span
                className="fw-semibold"
                style={{ fontSize: "19px", color: "rgb(250,103,103)" }}
              >
                Custom Delivery Options
              </span>
            </div>
            <div className="container-fluid">
              <div className="container-fluid">
                <div className="loadedDataSeparaterForItems container-fluid"></div>
              </div>
            </div>
            {error !== "" && (
              <div
                className="mb-3 formErrorSeller"
                style={{ marginRight: "5%", marginLeft: "5%" }}
              >
                {error !== "" && <div>{error}</div>}
              </div>
            )}
            {error === "" && (
              <React.Fragment>
                {optionsRecieved.map((option) => {
                  return (
                    <ExistingDeliveryOptions
                      key={option._id}
                      _id={option._id}
                      deliveryCharges={formatNumber(option.deliveryCharges)}
                      deliveryOptionName={option.deliveryOptionName}
                      waitTime={option.waitTime}
                      onlyOrderedBefore={option.onlyOrderedBefore}
                      DeleteHandler={deleteDeliveryOption}
                    />
                  );
                })}
                {optionsRecieved.length <= 0 && (
                  <div
                    className="formGeneralSeller"
                    style={{ marginRight: "5%", marginLeft: "5%" }}
                  >
                    No delivery options found.
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="p-3"></div>
        </React.Fragment>
      )}
      {wasCouldntFetch && <CoudntFetch />}
    </div>
  );
};
export default React.memo(DeliveryOptions);
