import "./NavBar.css";
import React from "react";
import menuIcon from "../../Icons/menu.png";
import merchantSwoopkartLogo from "../../Icons/SellerLogo.png";
import businessIcon from "../../Icons/shopLogoDemo.png";
import shopIcon from "../../Icons/shopNavIcon.png";
import dashboardIcon from "../../Icons/dashboard.png";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const NavBar = (props) => {
  const sellerTokenFromStore = useSelector(
    (state) => state.sellerData.sellerToken
  );
  const sellerDPFromStore = useSelector((state) => state.sellerData.sellerDP);
  const sellerNameFromStore = useSelector(
    (state) => state.sellerData.sellerName
  );
  const sellerIdFromStore = useSelector((state) => state.sellerData.sellerId);
  const location = useLocation();
  const checkURL = location.pathname.includes("shop");
  return (
    <nav
      className={`d-flex justify-content-center align-items-center  container-fluid sticky-top bg-white merchantNavBar shadow-sm`}
    >
      <div className="container-fluid row">
        <div className="col-3 class-0padding">
          <button
            className="merchantDrawerToggleButton"
            onClick={props.drawerToggle}
          >
            <img src={menuIcon} alt="Menu" className="merchantBars" />
          </button>
        </div>

        <div className="merchantNavBarLogo d-flex justify-content-center align-items-center col-6 class-0padding">
          <Link to="/">
            <img src={merchantSwoopkartLogo} alt="logo" height="30px" />
          </Link>
        </div>

        <div className="col-3 d-flex justify-content-end align-items-center class-0padding">
          {!sellerTokenFromStore && (
            <Link className="merchantNavSignIn " onClick={props.signInToggle}>
              <strong>Sign in</strong>
            </Link>
          )}
          {sellerTokenFromStore && (
            <div className="d-flex justify-content-end align-items-center">
              <Link
                className="merchantNavAccount"
                onClick={props.sellerAccountToggle}
              >
                <img src={sellerDPFromStore} alt="" />
              </Link>
              <div style={{ width: "14px" }}></div>
              {!checkURL && (
                <Link
                  to="/shop"
                  className="merchantNavOrders position-relative"
                >
                  <img
                    src={shopIcon}
                    alt="shop"
                    className="merchantNavOrders_img"
                  />
                </Link>
              )}
              {checkURL && (
                <Link to="/" className="merchantNavOrders position-relative">
                  <img
                    src={dashboardIcon}
                    alt=""
                    className="merchantNavOrders_img"
                  />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
